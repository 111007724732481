<template>
  <div>
    <v-icon @click="showSignout=true" class="ml-3" style="cursor:pointer" medium :class="`white--text lighten-1 ml-1`">mdi-location-exit</v-icon>
    <v-icon @click="showService=true" class="ml-3" style="cursor:pointer" medium :class="`white--text lighten-1 ml-1`">mdi-cog</v-icon>
    <v-dialog v-model="showSignout" max-width="290">
      <v-card>
        <v-card-title style="noselect" class="body-2">
          Are yu sure to sign off
        </v-card-title>
          <v-card-actions>
          <v-btn class="warning" dark large text outlined @click="showSignout = false;">cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="success" large dark outlined @click="showSignout = false; logout();">Bye bye</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="showService" max-width="290">
                <v-card>
                  <v-card-title style="noselect" class="body-2">
                    Some Service Tools; Be carefull!
                  </v-card-title>
                    <v-card-actions>
                    <v-btn class="success" large dark outlined  @click="showService = false; downloadDBase();">DownLoad DBase</v-btn>
                 
                    <v-btn class="warning" large dark outlined @click="showService = false; doit();">doit</v-btn>
                 
                  </v-card-actions>
                </v-card>
    </v-dialog>
    
  </div>

</template>
<script>
const fb = require('../firebaseConfig.js')
import { mapState,mapMutations,mapActions } from 'vuex'
import editsets from '../components/editsets'

  export default {
    data() {return {
      showSignout: false,
      showService: false,
      SM:true}
    },

    computed: {
      
    ...mapState('settings',['usrSet','currentSet']),
    ...mapState('globals',['safeMode']),
     ...mapState('service',['db_done','full_db']),
     //GUI dynamics
     SafeMode(){return this.safeMode},
     CurrentSetName(){try{return this.usrSet['sets'][this.currentSet]['name']}catch{return null} },
     AllSets(){return this.allSets},
     
    },
    
    
    methods: {
      ...mapMutations('poa',['changeMode']),
      ...mapMutations('globals',['toggleSafeMode']),
      ...mapActions('settings',['setCurrentSet']),
      
      ...mapActions('service',['doit','downloadDBase','safeDBase']),
      
      doit(){this.doit()},
      logout() {
          fb.auth.signOut().then(() => {
              this.$store.dispatch('auth/clearData')
              this.$router.push('/login')
          }).catch(err => {
              console.log(err)
          })
      }
  },
  watch: {
    db_done: function(){if (this.db_done==3){this.safeDBase()}  },
  },
  created: function () {  },

}
</script>
