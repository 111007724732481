<template>
  <v-app>
    <router-view @dragover.prevent @dragover.prevent @dragleave.prevent @drop.prevent/>
  </v-app>
</template>

<script>
    import { mapState,mapActions,mapMutations } from 'vuex'
    export default {
        computed: {
            ...mapState('auth',['currentUser'])
        },
        methods:{
          ...mapActions('memes',['getAllMemesFromFB','getUserSettings','initUserSettings']),
          ...mapActions('globals',['startGlobalTimer']),
          ...mapMutations('globals',['createColors100']),
          ...mapActions('settings',['getSettings']),
        },
        created(){
          this.getSettings()
          //this.getUserSettings()
          this.createColors100()
          this.startGlobalTimer()
          
        }
    }
</script>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
</style>
