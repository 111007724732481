const state = {
  nu: new Date(),
  globaltimerid: null,
  safeMode: true,
  maxActive:25,
  mediaPath: {'base': 'memes', 'trash': 'trash', 'thumbs':'thumbs'},
  colors100:{},
  colors100C:{},
};

const mutations = {
  toggleSafeMode(state){state.safeMode=!state.safeMode},
  createColors100(state){
    let c = ['light-blue','deep-orange','light-green','cyan','red','blue-grey','teal','pink','purple','green']
    let c_C = ['lightBlue','deepOrange','lightGreen','cyan','red','blueGrey','teal','pink','purple','green']
    for (let j of [...Array(10).keys()]){
      for (let k of [...Array(10).keys()] ){
      state.colors100[String(j)+String(k)] = c[j]
      state.colors100[("0" + Number(j*10)).slice(-2)+'-'+("0" + Number(((j+1)*10-1))).slice(-2)]= c[j]
      state.colors100C[String(j)+String(k)] = c_C[j]
      state.colors100C[("0" + Number(j*10)).slice(-2)+'-'+("0" + Number(((j+1)*10-1))).slice(-2)]= c_C[j]
    }}
    for (let k of [...Array(10).keys()] ){
      state.colors100[String(k)] = 'grey'
      state.colors100['0-9']='grey'; 
      state.colors100C[String(k)] = 'grey'
      state.colors100C['0-9']='grey'; 
    }
    }
};

const actions = {
  startGlobalTimer({commit,dispatch, state,rootState}) {
        dispatch('clearGlobalTimer')
        state.globaltimerid = setInterval(() => {
          state.nu = new Date()
        }, this.interval_delta*1000);//tolerance in ms
      },
clearGlobalTimer({commit,dispatch, state,rootState}){
  //console.log('nu clearen')
  clearInterval(state.globaltimerid)
}
};
export default {
  namespaced: true,
  state,   //es6 voor state: state
  mutations,
  actions,
};
