<template>
  <span style="display:inline-block; white-space: nowrap;no-select" :class="class_main?class_main:'font-weight-light'">
    <!-- -->
    
    <span v-if="name.pre" class="">{{name.pre}}</span>
    <span v-if="name['0']" :class="class_major?class_major:'font-weight-bold'">{{name["0"].toUpperCase()}}</span>
    <span v-if="name.mid">{{name.mid}}</span>
    <span v-if="name['1']"   :class="class_major?class_major:'font-weight-bold'">{{name["1"].toUpperCase()}}</span>
    <span v-if="name.post">{{name.post}}</span>
    <!-- <span v-if="parent"  :class="class_parent?class_parent:``" >|{{parent}}</span> -->
</span>

</template>

<script>
  // Ӽ  \u04FC , ᶍ \u1D8D  ⁀১𐐅𐐎Ӽ
  export default {
    data () {
      return {
      }
    },
    computed: {
      name(){
        var majorized = {"pre":null,"0":null,"mid":null,"1":null,"post":null}
        var arr = []
        try{
            arr = this.instance.split('`') //arr.flat()
            if (this.major[1]){
              var end = arr.splice(this.major[1])
              majorized["post"] = end.length>1?end.splice(1).join(''):null
              majorized["1"] = end[0]
              var end = arr.splice(this.major[0])
              majorized["mid"] = end.length>1?end.splice(1).join(''):null
              majorized["0"] = end[0]
              majorized["pre"] = arr.length>0?arr.join(''):null
            }
            else{
              var end = arr.splice(this.major[0])
              majorized["0"] = end[0]
              majorized["post"] = end.length>1?end.splice(1).join(''):null
              majorized["pre"] = arr.length>0?arr.join(''):null
            }
           }catch{
              //console.log(this.parent)
           }

        return majorized
      }
    },
    methods: {
       x_corr() {}
    },
    props: ['instance', 'major','parent','class_parent','class_main','class_major','only']
  }
</script>
