<template>
    <v-row dense class="pa-0" v-if="thisMeme&&showImageSort&&Spots" align="center">
        <!-- SHOW ALL  ----------------------------------------------------------------------------------->
            <template v-for="spot of Object.keys(Spots)">
                <div :draggable="!isLocked(spot)" class="mr-1"  @dragstart="startDrag($event, spot)" :key="spot" style="cursor:pointer"> 
                    <div  @dragover.prevent="!isLocked(spot)?dragEnter({'spot':spot}):null"
                          @dragleave ="!isLocked(spot)?dragLeave({'spot':spot}):null"
                          @drop.prevent="!isLocked(spot)?onDrop($event, {'spot':spot}):null"
                       >
                        <v-avatar style="z-index:1" rounded class="rounded-lg" :size="`${(!getUrlImg(thisMeme,spot) && thisMeme)?size/3:size}`" @dblclick.native="setMediaItemAndDo(spot)" :style="`outline: ${Spots[spot]?'3px':'0px'} solid white`" > <!--  width="90vh" height="90vh" max-height="600" max-width="600" min-height="100" min-width="100" --> <!--:size="CropNow?300:500"  object-fit: cover-->
                            <v-avatar rounded  :class="`rounded-lg ${curColor} ${spot==0?'lighten-5':'lighten-3'}`" width="97%" height="97%" :style="`opacity:${(!getUrlImg(thisMeme,spot) && thisMeme)?0.5:1}`"> <!--:size="CropNow?300:500"  object-fit: cover-->
                                  <mememedia :meme="thisMeme" :media_id="spot" :file_base="fileBase(spot)" :bgcolor="curColor"/>
                             <!--
                                <img  v-if="getUrlImg(thisMeme,spot) && SafeMode" style="opacity:0.6; object-fit: cover;" src="../assets/img/anon.jpg" /> 
                                <img v-if="getUrlImg(thisMeme,spot) && !SafeMode"  style="opacity:1;object-fit:cover" :src="getUrlImg(thisMeme,spot)" /> 
                                <img v-if="!getUrlImg(thisMeme,spot) && thisMeme" style="opacity:0.3; object-fit: cover;" src="../assets/img/unknown.jpg"/>
                               <img v-if="!getUrlImg(thisMeme,spot) && !thisMeme" style="opacity:0.2; object-fit: cover;" src="../assets/img/noone.jpg"/>
                              -->
                            </v-avatar>
                        </v-avatar>
                    </div>
                </div>
            </template>

            
            <v-dialog v-model="showDelete" max-width="290" color="#272727">
                <v-card color="#272727">
                <v-card-title class="overline grey--text text--lighten-3 font-weight-light">Delete?</v-card-title>
                    <v-card-text v-if="Object.keys(thisMeme).includes('media')">
                    <v-row justify="center" class="mt-1" >
                        <v-avatar style="z-index:1" rounded class="rounded-lg" size="150" > <!--  width="90vh" height="90vh" max-height="600" max-width="600" min-height="100" min-width="100" --> <!--:size="CropNow?300:500"  object-fit: cover-->
                            <mememedia :meme="thisMeme" :media_id="media_id" :file_base="fileBase(media_id)" :bgcolor="curColor"/>
                            <!--<img v-if="getUrlImg(thisMeme,media_id)" :style="`opacity:${SafeMode?0.3:1};object-fit:cover`" :src="getUrlImg(thisMeme,media_id)" />  scale-down -->
                        </v-avatar>
                    </v-row>
                    </v-card-text>
                    <v-card-actions>
                    <span class="overline green--text text--lighten-3 font-weight-light" @click="showDelete = false;" style="cursor:pointer"> No keep it</span>
                    <v-spacer/>
                    <span v-if="!isLocked(media_id)" class="overline red--text text--lighten-3 font-weight-light" @click="showDelete = false; removeMedia(media_id)" style="cursor:pointer">Yes Delete</span>
                </v-card-actions>
                </v-card>
            </v-dialog>
        <thecropper class="pa-0" :showCropper="showCropper" :thisMeme="thisMeme" :media_id="media_id" :initial_img="initial_img" @finishedCropper="showCropper=false;initial_img=null;initSpots()" />
        <videocropper class="pa-0" :showVideoCropper="showVideoCropper" :thisMeme="thisMeme" :media_id="media_id" :initial_img="initial_img" :initial_file_size="initial_file_size" @finishedVideoCropper="showVideoCropper=false;initial_img=null;initial_file_size=null;initSpots()" />
    
    </v-row>
</template>

<script>
  import { ObjectID } from 'bson';
  import { mapState,mapMutations,mapActions } from 'vuex'
  import thecropper from '../components/thecropper'
  import videocropper from '../components/videocropper'
  import mememedia from '../components/mememedia'

  const Dropbox = require('dropbox').Dropbox;


const fb = require('../firebaseConfig.js')
  export default {
    data () {
      return {
        Spots:{},
        loading:false,
        loadmessage:'',
        media_id:null,
        showDelete:false,
        showCropper: false,
        showVideoCropper:false,
        initial_img:null,
        initial_file_size:null,
    }},
    computed: {
      ...mapState('auth',['currentUser']),
       ...mapState('settings',['dbx_token','currentSet']),
      ...mapState('globals',['safeMode','maxActive','mediaPath','colors100']),
      SafeMode(){return this.safeMode},
      curColor(){try{return this.colors100[this.thisMeme['theHundred'][this.currentSet]['digits']]}catch{return 'grey'}},
     
    },
    methods: {
        ...mapActions('memes',['updateMemeInFB']),
        ...mapMutations('globals',['toggleSafeMode']),
        closeDialog(){this.$emit('finishedImageSort');},
        fileBase(spot){ try {return this.thisMeme['media'][spot]['file_base'] }catch{return null}},
        //isLocked(spot){let out = false; try{Object.keys(this.thisMeme['media'][spot]).includes('ffmpeg')?out=!this.thisMeme['media'][spot]['ffmpeg']:null}catch{};return out;},
        isLocked(spot){return false;},
        getUrlImg(meme,i){ let out = null; try{out = meme['media'][i?i:0]['url']}catch{}; return out},
        setMediaItemAndDo(media_id){
            this.media_id = media_id
            try{this.showDelete = Object.keys(this.thisMeme['media']).includes(media_id)}catch{}
            if (!this.showDelete){this.showCropper=true}
         },
      startDrag (evt, org_spot) {evt.dataTransfer.dropEffect = 'move'; evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('org_spot', org_spot)
      },
    getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
            },
      onDrop (evt, new_spot) {
        let hasImage = false; try{hasImage = Object.keys(this.thisMeme['media']).includes(new_spot['spot'])}catch{}
        if (!hasImage && evt.dataTransfer.files.length>0){
          this.initial_file_size = evt.dataTransfer.files[0].size
              
          if(evt.dataTransfer.items[0].type.slice(0,5)=='image'){
              this.getBase64(evt.dataTransfer.files[0]).then(data => {
                this.initial_img = data;
                this.media_id= new_spot['spot']
                this.showCropper = true
                })}
           else if(evt.dataTransfer.items[0].type.slice(0,5)=='video'){
              this.getBase64(evt.dataTransfer.files[0]).then(data => {
                let n = data.split('quicktime');
                if (n.length>1){ this.initial_img = n[0]+'mp4'+n[1]}
                else{ this.initial_img = data;}
                this.media_id= new_spot['spot']
                this.showVideoCropper = true
                })}
              
        }
        else{
        let org_spot = evt.dataTransfer.getData('org_spot')
        this.swapMedia(org_spot,new_spot['spot'])
        this.initSpots()
        }
      },
      dragEnter (sp){this.Spots[ sp['spot'] ]=true },
      dragLeave (sp){this.Spots[ sp['spot'] ]=false },
      initSpots(){this.Spots = Object.fromEntries(Object.entries([...Array(this.maxActive).keys()]) .map(([ key, val ]) => [key, false ])) },
      swapMedia(org_spot, new_spot){
        //console.log(ext + ': ' + org_spot+ ' to '+ new_spot); 
        if (org_spot!=new_spot && Object.keys(this.thisMeme['media']).includes(org_spot)){
          this.loading=true; this.loadmessage='Swap'
          let org_spot_media = this.thisMeme['media'][org_spot]; 
          let new_spot_media = null
           if (Object.keys(this.thisMeme['media']).includes(new_spot)){
            new_spot_media = this.thisMeme['media'][new_spot]
            this.thisMeme['media'][new_spot] = org_spot_media
            this.thisMeme['media'][org_spot] = new_spot_media
            }else{
              delete  this.thisMeme['media'][org_spot]
              this.thisMeme['media'][new_spot] = org_spot_media
            }
            
         this.updateMemeInFB(this.thisMeme)  
         this.loading=false;  
        }
      },
      removeMedia(media_id){
        const dbx = new Dropbox({ accessToken : this.dbx_token ,fetch: require("isomorphic-fetch")  })
        let that=this
        this.loading = true; this.loadmessage = 'updating dropbox file'
        let full_path = '/'+this.currentUser.uid+'/'+this.mediaPath['base']+'/'+that.thisMeme['media'][media_id]['type']+'/'
        let full_path_poster = '/'+this.currentUser.uid+'/'+this.mediaPath['base']+'/poster/'
        let full_path_trash = '/'+this.currentUser.uid+'/'+this.mediaPath['trash']+'/'
        let file_name_base = this.thisMeme['media'][media_id]['file_base'] 
        let ext = this.thisMeme['media'][media_id]['type']
        this.loadmessage = 'moving file to trash folder'; //dbx.filesDeleteV2
         dbx.filesMoveV2( {'from_path':  full_path+file_name_base+'.'+ext ,'to_path':   full_path_trash+file_name_base+'.'+ext})
          .then(function() {
              if (Object.keys(that.thisMeme['media'][media_id]).includes('url_poster')){
                dbx.filesMoveV2( {'from_path':  full_path_poster+file_name_base+'.jpg' ,'to_path':   full_path_trash+file_name_base+'.jpg'})
                .then(function() {}).catch(function(error) { console.log(error);})
                } //dit gaat dus async tegelijk....
     
            that.$delete(that.thisMeme['media'],media_id)
            that.updateMemeInFB(that.thisMeme)
            that.loading = false
          }).catch(function(error) { console.log(error);  that.loading = false})
      
      },
    },
    props: ['showImageSort','thisMeme','size'],
    created: function () {
      this.initSpots();
    },
    watch: { 
    thisMeme: function(){ this.initSpots(); },
    },
     components:{thecropper,videocropper,mememedia},
   
  }
</script>

