<template>
      <v-row justify="center" v-if="resultShow" class="" > <!-- easy clear of inputs-->
      <v-dialog :value="resultShow" persistent >
      <v-card >
      <v-card-title class="mt-n2 mb-n4">
        <v-col v-if="Object.keys(Errors).length>0" class="text-left text-h4 pink--text"  >
          <span class="mr-5" v-if="ErrorsTotals[99]>0">
          <v-badge
              :content="ErrorsTotals[99]"
              :offset-x="0"
              :offset-y="13"
              :color="`${(ErrorsTotals[0]>0)?'grey':'pink'}`"
              overlap  >
              <v-icon large  :color="`${(ErrorsTotals[0]>0)?'grey':'pink'}`" >mdi-close-octagon-outline</v-icon>
          </v-badge>
        </span>
          <template v-for="err in 4" >
            <span class="mr-2" v-if="ErrorsTotals[err-1]>0">
            <v-badge
                :content="ErrorsTotals[err-1]"
                :offset-x="0"
                :offset-y="13"
                :color="`${(err-1==0)?'grey':'pink lighten-3'}`"
                overlap  >
                <v-icon medium  :color="`${(err-1==0)?'grey':'pink lighten-3'}`" >
                  {{ ErrorIcons[err-1]}}
              </v-icon>
            </v-badge>
          </span>

         </template>

        </v-col>
        <v-col v-else class="text-left text-h4 green--text"  >
          <v-icon size="40px"  color="green">mdi-seal</v-icon>
        </v-col>
        <v-col class="text-center" >
          <v-icon v-if="currentSet<paoRun['settings']['NumberOfSets']" @click="currentSet-=(currentSet>0)?1:0" size="40px" color="grey">mdi-arrow-left-circle</v-icon>
          <v-icon v-if="" @click="closeDialog()" size="40px" color="pink">mdi-stop-circle</v-icon>
          <v-icon v-if="" @click="submitResultRun(Errors);closeDialog()" :disabled="Object.keys(Errors).length>0&&ErrorsTotals[0]>0" size="30px" color="pink">mdi-content-save</v-icon>
          <v-icon v-if="currentSet>=-1" @click="currentSet+=(currentSet<paoRun['settings']['NumberOfSets']-1)?1:0" size="40px"  color="grey">mdi-arrow-right-circle</v-icon>
        </v-col>

        <v-col class="text-right" >
          <v-icon v-if="" @click="DeleteRun=true" size="30px" color="pink">mdi-delete-circle</v-icon>
        </v-col>
      </v-card-title>

    <v-divider></v-divider>

      <v-card-text class="text-left mt-5 mb-n2">
        <v-row  justify="center" dense class="">
        <template v-for="i in paoRun['settings']['NumberOfSets']">
          <div class="mb-2" style="cursor:pointer" @click="currentSet=i-1">
            <template v-for="j in 3" >
              <v-badge
                  :content="paoRun['decode']['set'][ ((i-1)*3)+j-1 ]"
                  :value=" (((i-1)*3)+j-1 in Errors)"
                  :offset-x="15"
                  bottom
                  :color="`${((((i-1)*3)+j-1)in Errors)?(Errors[((i-1)*3)+j-1].type>0)?(Errors[((i-1)*3)+j-1].type==1)?'black':'pink':'grey':'black'}`"
                  overlap >
                    <v-avatar
                    :color="`${((((i-1)*3)+j-1)in Errors)?(Errors[((i-1)*3)+j-1].type>0)?(Errors[((i-1)*3)+j-1].type==2)?'black':'pink':'grey':'black'}`"
                    :size="`${(i-1)==currentSet?'45':'35'}`"
                    class="mx"
                    style="cursor:pointer" >
                    <span class="title-1  font-weight-bold white--text noselect" >
                      {{paoRun['encode']['set'][ ((i-1)*3)+j-1 ]}}
                    </span>
                  </v-avatar>
              </v-badge>
            </template>
            <v-avatar color="white" size="6" class="mx" ><span class="font-weight-bold white--text noselect" >-</span></v-avatar>
          </div>
        </template>
      </v-row>
      </v-card-text>

<v-divider></v-divider>

      <v-card-text class="mt-2 px-5" v-if="currentSet>-1 && Object.keys(the100).length>99">
        <template v-for="(pao,i) in ['person','action','object']" :keys="i">
            <v-row v-if="((currentSet*3)+i in Errors)" dense>
              <v-col cols="5" class="text-right">
                    <span :class="`font-weight-light subtitle-1 ${(Errors[currentSet*3+i].type>0)?(Errors[currentSet*3+i].type==2)?'black':'pink':'grey'}--text`">
                      {{the100[ paoRun['encode']['set'][i+(currentSet*3)]][pao]}}
                    </span>

                    <v-avatar  :color="`${(Errors[currentSet*3+i].type>0)?(Errors[currentSet*3+i].type==2)?'black':'pink':'grey'}`" size="30" class="mr-1" style="cursor:pointer" >
                    <span class="subtitle-5  font-weight-bold white--text noselect" >
                      {{paoRun['encode']['set'][ (currentSet*3)+i ] }}
                    </span></v-avatar>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-icon  @click="Errors[(currentSet*3)+i].type+=(Errors[(currentSet*3)+i].type<3)?1:-2"
              class="mt" :color="`${(Errors[(currentSet*3)+i].type==0)?'grey':'pink'}`" >{{ ErrorIcons[Errors[(currentSet*3)+i].type]}}</v-icon>
            </v-col>


            <v-col cols="5">
            <v-avatar
            :color="`${(Errors[currentSet*3+i].type>0)?(Errors[currentSet*3+i].type==1)?'black':'pink':'grey'}`"
            size="30" class="mr-1" style="cursor:pointer" >
            <span class="subtitle-5  font-weight-bold white--text noselect" >
              {{paoRun['decode']['set'][ (currentSet*3)+i ] }}
            </span></v-avatar>
            <span color="pink" :class="`font-weight-light subtitle-1 ${(Errors[currentSet*3+i].type>0)?(Errors[currentSet*3+i].type==1)?'black':'pink':'grey'}--text`">
              {{the100[ paoRun['decode']['set'][i+(currentSet*3)]][pao]}}
            </span>

          </v-col>

            </v-row>
      </template>
      </v-card-text>
      <v-card-text class="mt-n2 px-5" v-if="currentSet>-1 && Object.keys(the100).length>99">
      <v-row justify="space-between">
            <v-icon class="" small color="grey lighten-1">mdi-format-quote-open-outline</v-icon>

            <template v-for="(pao,i) in ['person','action','object']">
              <span class="font-weight-light subtitle-1 black--text">{{the100[ paoRun['encode']['set'][i+(currentSet*3)]][pao]}}</span>
            </template>
            <v-icon class="mt-n4" small color="grey lighten-1">mdi-format-quote-close-outline</v-icon>
      </v-row>
      </v-card-text>
    </v-card>
    </v-dialog>

    <v-dialog v-model="DeleteRun" max-width="400">
                 <v-card>
                   <v-card-title style="noselect" class="body-2">This will delete current run!</v-card-title>
                   <v-card-actions>
                     <v-btn class="success" dark large text outlined @click="DeleteRun=false;">Nope, keep it</v-btn>
                     <v-spacer></v-spacer>
                     <v-btn class="error" large dark outlined @click="removeRunFromFB(paoRun); DeleteRun=false; closeDialog()">Delete the Run</v-btn>
                   </v-card-actions>
                 </v-card>

      </v-dialog>

  </v-row>






</template>

<script>
import { mapState,mapMutations,mapActions } from 'vuex'
  export default {
    data () {
      return {
        DeleteRun:false,
        currentSet:0,
        Errors:{},
        subOfTens:{},
        the100:{},
        PaoIcons:{'person':'mdi-account-supervisor','action':'mdi-shape-outline','object':'mdi-cube-send'},
        ErrorIcons:{ 0:'mdi-help-circle-outline',
                     1:'mdi-ear-hearing',
                     2:'mdi-bullhorn-outline',
                     3:'mdi-skull-outline'}
      }
    },

    computed: {
        ...mapState('runs',['paoRun']),
        ...mapState('globals',['nu','colors100']),
        ...mapState('poa',['allPeople','pao_mode']),
        ErrorsTotals(){
          let out={0:0,1:0,2:0,3:0}
          for (let k of Object.keys(this.Errors)){out[this.Errors[k].type] += 1;}
          out[99]=out[0]+out[1]+out[2]+2*out[3]
          return out
        }

      },

    methods: {
        ...mapMutations('runs',['resetRun']),
        ...mapActions('runs',['submitResultRun','removeRunFromFB']),

      closeDialog(){this.resetRun(); this.$emit('finishedresultShow')},
      loadThe100(){
        this.the100 = Object.fromEntries(Object.entries([...Array(110).keys()]) .map(([ key, val ]) => [key<100?this.$options.filters.dd(key):key-100,{'person':null,'action':null,'object':null} ]))

        for (let person of this.allPeople){
          if(person.theHundred?person.theHundred[this.pao_mode]!=null:false)
          {this.the100[person.theHundred[this.pao_mode]]={
            'person':person['displayName']?this.$options.filters.capitalized(person['displayName']):null,
            'action':person['action']?this.$options.filters.capitalizefirst(person['action'].replace('<...>','')):null,
            'object':person['object']?this.$options.filters.capitalizefirst(person['object']):null
          }}

        }
        this.the100['?']={'person':'?','action':'?','object':'?'}
      },

      init(){
        this.loadThe100()
        this.currentSet = 0
        this.Errors = this.paoRun['errors']
      },
    },
    watch: {
      decodeShow: function(){  if (this.resultShow){this.init()}  },
      paoRun: function(){  if (this.resultShow){this.init()}  }
    },
    props: ['resultShow'],
    //mixins: [mixin],
    components:{},
    mounted: function () {

      for (let j of [...Array(10).keys()]){
        var k = [...Array(10).keys()].map(x => this.$options.filters.dd(x + j*10));
        this.subOfTens[this.$options.filters.dd(j*10)+'-'+this.$options.filters.dd(((j+1)*10-1))]= k
      }


    }
  }
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>
