import Vue from 'vue'
import Vuex from 'vuex'

//modules import
import auth from './auth';
import memes from './memes';
import runs from './runs';
import globals from './globals';
import settings from './settings';
import service from './service';
Vue.use(Vuex)

export default new Vuex.Store({
 
  state: {

  },
  mutations: {

  },
  actions: {
  },
  getters: {
  },
  modules: {
    auth,
    memes,
    runs,
    globals,
    settings,
    service
  },
  namespaced: true,
});
