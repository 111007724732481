<template><div>
  <v-card class="mx-auto mt-10" max-width="344" outlined :disabled="busy" :loading="busy">
     <v-list-item three-line >
       <v-list-item-content>
         <div class="overline mb-4">n<sup>th</sup><b>elephant</b></div>
         <v-list-item-title class="headline mb-1">{{ signIn ? forgotPassword?'Reset password':'Login':'Create new account'}}</v-list-item-title>
         <v-divider/><br>
           <v-list-item-content>
           <v-form  @keyup.native.enter="(signIn && !forgotPassword)?login():''" ref="form" v-model="valid" lazy-validation>
             <v-text-field  label="user name" prepend-icon="mdi-account-card-details-outline"
                  v-if = "!signIn && !forgotPassword"
                  v-model="name"
                  :rules="[]"/>

               <v-text-field  label="email" prepend-icon="mdi-account-circle"
                    v-model="email"
                    :rules="emailRules"
                />
               <v-text-field
                    v-if = "!forgotPassword"
                     :type="displayPassword? 'text' : 'password'"
                     label="password"
                     v-model="password"
                     prepend-icon="mdi-lock"
                     :append-icon="displayPassword?'mdi-eye': 'mdi-eye-off'"
                     @click:append ="displayPassword=!displayPassword"
                     :rules="passwordRules"

               />
             </v-form>
         </v-list-item-content>
         <v-alert v-if="errorMsg !== ''"
           color="pink lighten-3"
           dark
           border="top"
           icon="mdi-alert"
           transition="fade">
           {{errorMsg}}
         </v-alert>
      </v-list-item-content>
     </v-list-item>
     <v-card-actions>
       <v-btn v-if="allowSignUp && signIn && !forgotPassword" text x-small @click="clearAll(); signIn=false; forgotPassword=false">sign up</v-btn>
       <v-spacer/>
       <v-btn v-if="signIn && !forgotPassword" text x-small @click="forgotPassword=true">forgot my password</v-btn>
      </v-card-actions>
     <v-card-actions>
       <v-btn v-if="!signIn || forgotPassword" text outlined color="info" @click='clearAll(); signIn=true; forgotPassword=false'>cancel</v-btn>
       <v-spacer></v-spacer>
       <v-btn v-if="signIn && !forgotPassword" text outlined color="info" @click='login()' :disabled="!valid">login</v-btn>
       <v-btn v-if="!signIn"                   text outlined color="info" @click='signup()' :disabled="!valid">sign up</v-btn>
       <v-btn v-if="signIn && forgotPassword"  text outlined color="info" @click='resetPassword()' :disabled="!valid">reset password</v-btn>

     </v-card-actions>
   </v-card></div>
 </template>

<script>
    const fb = require('../firebaseConfig.js')
    export default {
        data() {
            return {
                allowSignUp: false,//false = mensen mogen niet signuppen!!!! belangrijk
                signIn: true,    //false = signup
                forgotPassword: false,
                email: '',
                name: '',
                password: '',
                emailRules: [
                    v => !!v || 'E-mail required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                  ],
                passwordRules: [
                      v => !!v || 'Password required',
                      v => (v && v.length >= 8) || 'Password needs at least 8 chararcters',
                    ],
                passwordResetSuccess: false,
                errorMsg: '',
                displayPassword:false,
                valid:false,
                busy:false
            }
        },
        methods: {
            clearAll(){
              this.email=''
              this.password=''
              this.errorMsg =''
              this.$refs.form.resetValidation()
            },
            login() {
                if (this.$refs.form.validate()){
                  this.busy=true
                  fb.auth.signInWithEmailAndPassword(this.email, this.password).then(user => {
                    user.user.updateProfile({
                      displayName: 'caca moale'
                    })
                      this.$store.commit('auth/setCurrentUser', user.user)
                      this.clearAll()
                      this.busy=false
                      this.$router.push('/')
                  }).catch(err => {console.log(err)
                      this.busy=false
                      this.errorMsg = err.message
                  })
                }
            },
            signup() {
              if (this.$refs.form.validate() && this.allowSignUp){
                this.busy=true
                fb.auth.createUserWithEmailAndPassword(this.email, this.password).then(user => {
                  this.$store.commit('auth/setCurrentUser', user.user)
                  this.clearAll()
                  this.busy=false
                  this.$router.push('/')
                }).catch(err => {console.log(err)
                    this.busy=false
                    this.errorMsg = err.message
                })
              }
            },
            //deze nog een keer maken;nu niet echt belangrijk
            resetPassword() {
                if (this.$refs.form.validate()){
                    this.busy=false
                    fb.auth.sendPasswordResetEmail(this.email).then(() => {
                      //this.clearAll()
                      this.password=''
                      this.forgotPassword=false
                      this.busy=false
                      this.errorMsg = "Mail has been send to reset your password"
                    }).catch(err => {console.log(err)
                      this.busy=false
                      this.errorMsg = err.message
                    })
                  }
          }
        }
    }
</script>
