//import firebase from 'firebase'
//import 'firebase/firestore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/database'; // If using Firebase database
import 'firebase/compat/storage';  // If using Firebase storage
// No see what we need??????
import 'firebase/compat/auth';        // for authentication
import 'firebase/compat/storage';     // for storage
import 'firebase/compat/database';    // for realtime database
import 'firebase/compat/firestore';   // for cloud firestore
//import 'firebase/compat/firestore/timestamp';   // for cloud firestore
//import 'firebase/messaging';   // for cloud messaging
//import 'firebase/functions';   // for cloud functions
import firebaseConf from '../firebaseConfig.js'    

firebase.initializeApp(firebaseConf)

// Enable offline support
//https://dev.to/paco_ita/break-the-cache-api-limits-in-our-pwa-oo3
firebase.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
      }
  });


// firebase utils
const fs = firebase.firestore
const storage = firebase.storage()
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser
// date issue fix according to firebase
//db.settings(settings)

// firebase collections //old
const runsCollection = db.collection('runs')
const memesCollection = db.collection('memes')
const settingsCollection = db.collection('settings')
const ffmpegCollection = db.collection('ffmpeg') //deze vervangen door api

export {
    db,
    auth,
    currentUser,
    runsCollection,
    memesCollection,
    settingsCollection,
    fs,
    storage,
    ffmpegCollection
}
