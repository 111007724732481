const fb = require('../firebaseConfig.js')
const Dropbox = require('dropbox').Dropbox;
           
// A. STATE ---------------------------------------------------------------------------------------------------------

//import idb from '@/api/idb';
import c from '../filtersandmixins/changePermutations';
const state = {
  allMemes:{}, // per DIMENSION!!!!!!!!!!! 
  uniqueNNN:{},
  alternatives:{},
  //'..':'0','^':'1','>':'2','=':'3','oo':'4','[.]':'5','|':'6','/\\':'7',',,':'8','(|)':'9'
  //Ѡ	U+0460
  the100Toggle: false,
  the100NNN: {},
  notThe100NNN:{},
  ffmpegwatchers:{}
}
// B. MUTATIONS ---------------------------------------------------------------------------------------------------------
const mutations = {
  initMemes(state){
    state.allMemes={}; state.uniqueNNN={}; state.alternatives={}; state.the100NNN={}; state.notThe100NNN={}; state.the100Toggle=!state.the100Toggle;
  },
  fillUniques(state,pl){
    let allDims = pl['allDims']
    for (let dim of Object.keys(allDims)){
      state.uniqueNNN[dim] = {}
      for (let what of ['category']){
        var result = []
        let names = state.allMemes[dim].map(a => a[what])
        names = [... new Set(names)]
        names.push('default')
        state.uniqueNNN[dim][what] = names.sort()
      }
    }
  },
  determineAlternatives(state,pl){
      let setSequence = pl['setSequence']
      let currentSet = pl['currentSet']
      let alternatives = {}
      for (let dim of setSequence){
        alternatives[dim] = {}
        for (let meme of state.allMemes[dim]){
            for (let inst of Object.keys(meme['instances'])){
              for (let alt of Object.keys(meme['instances'][inst]['alts'])){
                let nw = {'id':meme.id, 
                          'codeName':meme['instances'][inst]['codeName'], 
                          'major':meme['instances'][inst]['alts'][alt],
                          'inst_id': inst,
                          'usedIn': meme.theHundred?meme.theHundred[currentSet]?meme.theHundred[currentSet]['digits']?meme.theHundred[currentSet]['digits']:null:null:null,
                          'majorLocation': this._vm.$options.filters.majorLocation(meme['instances'][inst]['codeTemplate'],meme['instances'][inst]['alts'][alt]),
                        }
                if (!alternatives[dim][alt]){alternatives[dim][alt]=[]}
                alternatives[dim][alt].push(nw)
            }
            }
        }
        //console.log(alternatives)
        state.alternatives = alternatives
        
    }

  },  
  updateMemeInMemes(state, pl) {
    let meme = pl['meme']; let act = pl['act']; let allDims = pl['allDims']
    let meme_dim = meme['dimension']
    //console.log(allDims)  
    if (Object.keys(allDims).includes(String(meme_dim))){
     let pao_type = allDims[meme_dim]['type']; 
      let cats = pao_type=='c'?allDims[meme_dim]['cats']:{}
      for (let inst of Object.keys(meme['instances'])){
      meme['instances'][inst]['alts'] = c.changePermutations(meme['instances'][inst],pao_type,cats,meme['category'])
      }
      let i= state.allMemes[meme_dim].findIndex(x=>x.id==meme.id);i = state.allMemes[meme_dim].findIndex(x=>x.id==meme.id);
      //if (!Object.keys(meme).includes('category')){console.log(meme)}
      if (act=='update'){state.allMemes[meme_dim].splice(i,1,meme)}
      if (act=='remove'){state.allMemes[meme_dim].splice(i,1);}
      if (act=='add'){ state.allMemes[meme_dim].push(JSON.parse(JSON.stringify(meme)) )}
    }
  },
  loadThe100(state,pl) {
    let allDims = pl['allDims'];  let currentSet = pl['currentSet']
      for (let dim of Object.keys(allDims)){
        this._vm.$set(state.notThe100NNN,dim,[])
        this._vm.$set(state.the100NNN, dim, Object.fromEntries(Object.entries([...Array(110).keys()]) .map(([ key, val ]) => [key<100?this._vm.$options.filters.dd(key):key-100, null ])))
          for (let meme of state.allMemes[dim]){
            let theHun = null
            try{
            let theHundred = meme.theHundred[currentSet]
            theHun = theHundred['digits']
            }catch{theHun}
            if(theHun){
              if(theHun!=null && dim==meme.dimension){
                if (!state.the100NNN[dim][theHun]){
                this._vm.$set(state.the100NNN[dim],theHun,meme)}
                else{console.log('duplicates found for:'+theHun+'>'+meme+'('+dim+')')}
              }}
              else{if (dim==meme.dimension){state.notThe100NNN[dim].push(meme)}
              }
          }
        }
    },
};

// C. ACTIONS ---------------------------------------------------------------------------------------------------------
const actions = {
 
  getAllMemesFromFB({commit,dispatch, state,rootState}){
    commit('initMemes')
    //this._vm.$forceUpdate(); //quick en dirty; wellicht nog kijken of ook
    for (let dim in rootState.settings.allDims){state.allMemes[dim]=[];state.uniqueNNN[dim] = {'category':[],'group':[],'parent':[]} } 
    fb.memesCollection.where('userId','==',rootState.auth.currentUser.uid)//.limit(10)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var meme = doc.data()
                meme['id']=doc.id
              //console.log(meme)
               meme['createdOn'] =meme['createdOn'].toDate()
               meme['lastModifiedOn'] = meme['lastModifiedOn'].toDate()
               commit('updateMemeInMemes',{'meme': meme,'act':'add','allDims':rootState.settings.allDims})
               //dispatch('queueffmpeg',meme)
              });
              commit('loadThe100',{'allDims':rootState.settings.allDims,'currentSet':rootState.settings.currentSet})
              commit('fillUniques',{'allDims':rootState.settings.allDims})
              commit('determineAlternatives',{'setSequence': rootState.settings.setSequence,'currentSet':rootState.settings.currentSet})
              state.the100Toggle=!state.the100Toggle
            })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
    },  
    
  updateMemeInFB({commit,dispatch, state,rootState},meme){
    if (meme){
      let swapObj = JSON.parse(JSON.stringify(meme)); 
      swapObj['createdOn'] = fb.fs.Timestamp.fromDate(new Date(meme['createdOn']))
      swapObj['lastModifiedOn'] =new Date()
      meme['lastModifiedOn'] =new Date() //wat een gezeik!! 
      fb.memesCollection.doc(meme.id).set(swapObj)
      .then(function() {
          commit('updateMemeInMemes',{'meme': meme,'act':'update','allDims':rootState.settings.allDims})
          //dispatch('queueffmpeg',meme)
          commit('loadThe100',{'allDims':rootState.settings.allDims,'currentSet':rootState.settings.currentSet})
          commit('fillUniques',{'allDims':rootState.settings.allDims})
          commit('determineAlternatives',{'setSequence': rootState.settings.setSequence,'currentSet':rootState.settings.currentSet})
          state.the100Toggle=!state.the100Toggle
        })
      .catch(function(error) {console.error("Error writing document: ", error);});
    }
  },
  //swap or delete if no new number and instnace
  swapMemeNumber({commit,dispatch, state,rootState}, pl){
    let swapObj = JSON.parse(JSON.stringify(pl['meme'])); 
    
    let newnumber = pl['newnumber']; let inst_id = pl['inst_id']
    //console.log(swapObj); console.log(newnumber); console.log(inst_id)
    if (swapObj && newnumber && inst_id){
      //console.log('nu')
      for (let m of state.allMemes[swapObj['dimension']]){
        try{if (m.theHundred[rootState.settings.currentSet]['digits']===newnumber){
              m.theHundred[rootState.settings.currentSet]={}
              dispatch('updateMemeInFB',m)}}catch{}
        }
      }
      if (newnumber && inst_id){
      //console.log('nu niet')
      swapObj['theHundred'][rootState.settings.currentSet]={'instance': inst_id,'digits':newnumber}
      }else{delete swapObj['theHundred'][rootState.settings.currentSet]} //geen pl => verwijderen van spot
      dispatch('updateMemeInFB',swapObj)
      //this._vm.$forceUpdate();
  },
  addMemeInFB({commit,dispatch, state,rootState},meme) {
    let insertObj =JSON.parse(JSON.stringify(meme))
    insertObj['createdOn'] =  new Date()
    insertObj['lastModifiedOn'] =  new Date()
    insertObj['userId'] = rootState.auth.currentUser.uid
    fb.memesCollection.add(insertObj).then((docRef) => {
        insertObj['id']=docRef.id
        commit('updateMemeInMemes',{'meme': insertObj,'act':'add','allDims':rootState.settings.allDims})
        //dispatch('queueffmpeg',meme)
        commit('loadThe100',{'allDims':rootState.settings.allDims,'currentSet':rootState.settings.currentSet})
        commit('fillUniques',{'allDims':rootState.settings.allDims})
        commit('determineAlternatives',{'setSequence': rootState.settings.setSequence,'currentSet':rootState.settings.currentSet})
        state.the100Toggle=!state.the100Toggle
      })
      .catch(error => {console.error("Error writing document: ", error)})
  },
  queueffmpeg({commit,dispatch, state,rootState},meme) {
   let that=this  
    try{
        for (let media_id of Object.keys(meme['media'])){
          let medium = meme['media'][media_id]
          if (['mp4','webm'].includes(medium['type'] ) && !medium['ffmpeg'] && !state.ffmpegwatchers[medium['file_base']]){
            //console.log(medium); console.log(meme)
            state.ffmpegwatchers[medium['file_base']] = setInterval( () => {
              let full_file_path = '/'+rootState.auth.currentUser.uid+'/'+rootState.globals.mediaPath['base']+'/'+medium['type']+'/'+medium['file_base']+'.'+medium['type']
              const dbx = new Dropbox({ accessToken : rootState.settings.dbx_token ,fetch: require("isomorphic-fetch") })
              dbx.sharingCreateSharedLinkWithSettings({"path": full_file_path}) 
                    .then(function(responseLnk) {
                      if (responseLnk){
                      let res_url = responseLnk.result.url.replace('dl=0','dl=1')
                      clearInterval(state.ffmpegwatchers[medium['file_base']])
                      medium['url'] = res_url
                      medium['ffmpeg'] = true
                      meme['media'][media_id] = medium
                      dispatch('updateMemeInFB',meme)
                      //console.log('updating')
                      }
                      })
                    .catch(function(error) {})
            }, 4000 + Math.random()*2000)
          }}
      }catch{(e)=> console.log(e)}
  
  },
  removeMemeFromFB({commit,dispatch, state,rootState},meme) {
    fb.memesCollection.doc(meme.id).delete().then(function() {
      commit('updateMemeInMemes',{'meme': meme,'act':'remove','allDims':rootState.settings.allDims})    
      commit('loadThe100',{'allDims':rootState.settings.allDims,'currentSet':rootState.settings.currentSet})
      commit('fillUniques',{'allDims':rootState.settings.allDims})
      commit('determineAlternatives',{'setSequence': rootState.settings.setSequence,'currentSet':rootState.settings.currentSet})
      state.the100Toggle=!state.the100Toggle
    }).catch(function(error) {console.error("Error removing document: ", error);});
  },
}


// F. EXPORT ---------------------------------------------------------------------------------------------------------
export default {
  namespaced: true,
  state,   //es6 voor state: state
  mutations,
  actions,
};
