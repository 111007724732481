const fb = require('../firebaseConfig.js')
import c from '../filtersandmixins/changePermutations';
// A. STATE ---------------------------------------------------------------------------------------------------------

const state = {
  full_db:{'memes':[],'settings':[],'people':[]},
  db_done:0,
}
// B. MUTATIONS ---------------------------------------------------------------------------------------------------------
const mutations = {  
 
};
// C. ACTIONS ---------------------------------------------------------------------------------------------------------
const actions = {
  
  doit({commit,dispatch, state,rootState}){
    console.log('shoot:')
    let i = 0
    fb.memesCollection
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        var meme = doc.data()
        if (Object.keys(meme).includes('media')){
        console.log(meme)
        //fb.memesCollection.doc(meme['id']).update("media", new_media ).then(function() {}) .catch(function(error) {console.error("Error writing document: ", error);})
        //fb.memesCollection.doc(meme['id']).update( "jpg",fb.fs.FieldValue.delete()).then(function() {}) .catch(function(error) {console.error("Error writing document: ", error);})
        //fb.memesCollection.doc(meme['id']).delete("jpg").then(function() {}) .catch(function(error) {console.error("Error writing document: ", error);});
         //console.log(meme['id'])  
      }
      
      })})
      
  },

  resetAllDates({commit,dispatch, state,rootState}){
    fb.peopleCollection//.where('userId','==','brkV7Skd4DcfAPsqXkoiFEnhX8e2')
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var person = doc.data()
                var meme = {}
                let TheHun = null
                let obj= null
               try {TheHun = person['theHundred']['c']
                    obj = person['action']
              }catch{}
                if (TheHun!=null && obj!=null){
                    meme['userId'] = person['userId']
                    meme['category'] = 'person linked'
                    meme['dimension'] = "1"
                    meme['createdOn'] = new Date()
                    meme['lastModifiedOn'] = new Date()
                    meme['favorite'] = null
                    meme['lock']=false
                    
                    meme['theHundred'] = {'0':{'digits':TheHun,'instance':'0'}}
                    meme['instances'] = {}
                    meme['instances']["0"] = {
                      'alts': {TheHun:[0,1]},
                      'codeName': TheHun[0]+'`'+TheHun[1]+'` `'+obj.trim().toLowerCase(),
                      'codeTemplate':TheHun[0]+'`'+TheHun[1]+'`-2`-1',
                      'displayName': TheHun + ' '+obj.trim().toLowerCase(),
                    }
                    setTimeout( console.log(meme), 500)
                    //fb.memesCollection.add(meme).then((docRef) => {  }) .catch(error => {console.error("Error writing document: ", error)})
                    //fb.settingsCollection.doc(meme['id']).update("createdOn",new Date()).then(function() {}) .catch(function(error) {console.error("Error writing document: ", error);});
                  
                }
             })
            
            }).catch(function(error) { console.log("Error getting documents: ", error);  
            
          })
    },

    downloadDBase({state,rootState}){
      state.full_db={'memes':[],'settings':[],'runs':[]}; state.db_done=0;
        fb.memesCollection.where('userId','==',rootState.auth.currentUser.uid).get().then(function(querySnapshot) { querySnapshot.forEach(function(doc) {
          var meme = doc.data()
          meme['createdOn'] =meme['createdOn'].toDate()
          meme['lastModifiedOn'] = meme['lastModifiedOn'].toDate()
          state.full_db['memes'].push(meme)
        });state.db_done++; }).catch(function(error) { console.log("Error ", error); });
        fb.settingsCollection.where('userId','==',rootState.auth.currentUser.uid).get().then(function(querySnapshot) { querySnapshot.forEach(function(doc) {
          var settings = doc.data()
          state.full_db['settings'].push(settings)
        });state.db_done++;}).catch(function(error) { console.log("Error ", error); });
        fb.runsCollection.where('userId','==',rootState.auth.currentUser.uid).get().then(function(querySnapshot) { querySnapshot.forEach(function(doc) {
          var run = doc.data()
          state.full_db['runs'].push(run)
        });state.db_done++;}).catch(function(error) { console.log("Error ", error); });
    },
    safeDBase({state}){
      //console.log(Object.keys(state.full_db['memes']).length + ' '+ Object.keys(state.full_db['people']).length + ' ' + Object.keys(state.full_db['settings']).length)
      function download(content, fileName, contentType) {
        var a = document.createElement("a");
        var file = new Blob([JSON.stringify(content)], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      } download(state.full_db, 'backup'+new Date()+'.json', 'text/plain');
      console.log(Object.keys(state.full_db['memes']).length + ' '+ Object.keys(state.full_db['people']).length + ' ' +  Object.keys(state.full_db['settings']).length)
     
    }

};


export default {
  namespaced: true,
  state,   //es6 voor state: state
  mutations,
  actions,
};
