<template>
    <div>
        <editmeme :showEdit="showEdit" :newdim="newdim" :meme="editMeme" :newnumber="newnumber"  @finishedEditMeme="showEdit=false;"/>
        <v-bottom-sheet  :fullscreen="FocusMode" :value="showMore" @click:outside="closeDialog()">
            <v-sheet color="#272727" :height="`FocusMode?'100vh':'100%'`" :scrollable="false" @wheel="onWheel($event)"  @dragover.prevent @dragover.prevent @dragleave.prevent @drop.prevent>
                <v-row  :class="`${colors100[actual_i]} pt-1`" class="lighten-3 mt-0" style="opacity:0.7" dense ></v-row>
                <v-row  dense class="mt-1 mb-n3" align="center" @click.self="FocusMode?closeDialog():''" > 
                    <v-avatar v-if="!FocusMode" :color="`${colors100[actual_i]}`" medium  class="ml-2 mr-2 lighten-3" style="z-index: 2">
                        <b :class="`headline font-weight-bold  noselect`">{{actual_i | dd}} </b>
                    </v-avatar>
                    <span v-if="!FocusMode && (inst = the100NNN[actual_d][actual_i])">
                        <span v-if="the100Instance(inst)"><v-icon v-if="allDims[actual_d]['type']!='c' && !FilterMajorLocs[FilterMajorLoc].includes($options.filters.majorLocation(the100Instance(inst)['codeTemplate'],the100Instance(inst)['alts'][actual_i])['type'])" class="mr-3" color="warning">mdi-alert-circle</v-icon></span>
                        <span v-if="allDims[actual_d]['type']=='c'" :class="`caption white--text text--lighten-3 font-weight-bold`"> {{allDims[actual_d]['cats'][actual_i.charAt(0)]['name'] }} </span>
                        <major  v-if= "the100Instance(inst)" class="mr-2" :instance="the100Instance(inst).codeName" :major="the100Instance(inst).alts[actual_i]"
                        :class_main="`headline ${colors100[actual_i]}--text text--lighten-3 font-weight-light`"
                        :class_major="`headline white--text font-weight-bold`"/>
                        <v-icon v-if="!FocusMode" @click.stop="showEdit=true; editMeme=the100NNN[actual_d][actual_i]; newnumber=null" class="mt-n1 mr-1 grey--text text--lighten-0" style="cursor:pointer" small>mdi-square-edit-outline</v-icon>
                    </span>

                    <v-icon v-if="!FocusMode" small style="cursor:pointer" @click="showEdit=true; editMeme=null; newnumber=null; newdim=actual_d" class="mr-1 grey--text text--lighten-0">mdi-plus-circle</v-icon>
                    <v-spacer/>
                    <!-- <v-rating v-if="the100NNN[actual_d][actual_i]" class="mr-5" v-model="fav"  background-color="white" color="black accent-4" length="3" dense half-increments clearable hover size="25"></v-rating>-->
                    <div @click="closeDialog()" v-if="FocusMode"><v-icon class="mr-3" style="cursor:pointer" medium color="white">mdi-arrow-left-circle</v-icon></div>
                    <div  @click="toggleSafeMode()"> <v-icon class="mr-3" style="cursor:pointer" medium color="white">mdi-eye{{SafeMode?'-off':''}}</v-icon></div>
                    <div @click="toggleFocusMode()"><v-icon class="mr-3" style="cursor:pointer" medium color="white">mdi-{{FocusMode?'arrow-collapse-all':'arrow-expand-all'}}</v-icon></div> <!--'brain':'puzzle-outline' -->
                    <div @click="reshuffle()" v-if="FocusMode"><v-icon class="mr-3" style="cursor:pointer" medium color="white">mdi-dice-multiple</v-icon></div> <!--'brain':'puzzle-outline' -->
                    
                     
                    <v-spacer/>
                    <v-icon v-if="!FocusMode&&the100NNN[actual_d][actual_i]" medium class="lighten-3 mr-3"  @click="swapMemeNumber({'meme':the100NNN[actual_d][actual_i]})"  style="cursor:pointer"  :color="colors100[actual_i]" >mdi-close-circle-multiple-outline</v-icon>
                    <v-icon medium class="lighten-3 mr-3" :color="colors100[actual_i]" >mdi-{{allDims[actual_d]['icon']}}</v-icon>
                </v-row>
<!-- FOCUS MODE ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------>
                <v-row  v-if="FocusMode" justify="center" class="text-center" fluid :style="`height: 100vh; overflow-y: hidden`">
                   <v-col class="text-center mt-1"  @click.self="closeDialog()" ><!-- .self zorgt dat parent niet triggered bij click child!-->
                        <div :style="`background-color:none; display:inline-block; position:relative; transform: rotate(${wooh[0]['rot']}deg);`" >
                            <v-avatar :style="`border-color: ${colors[colors100C[actual_i]]['lighten3']};  z-index:${wooh[0]['z']}`" rounded class="rounded-xl my_border " :width="wooh[0]['size']*aspectRatio+wh_mode" :height="wooh[0]['size']+wh_mode" :max-height="max_size/aspectRatio" :max-width="max_size" :min-height="min_size" :min-width="min_size"> 
                                <mememedia :meme="the100NNN[actual_d][actual_i]" :media_id="media_show_ids[0]"  :file_base="fileBase(the100NNN[actual_d][actual_i],media_show_ids[0])" :canplay="true" :autoplay="autoplay" :bgcolor="colors100[actual_i]"/>
                            </v-avatar>
                                <span v-if="the100NNN[actual_d][actual_i]?Object.keys(the100NNN[actual_d][actual_i]).includes('media'):false">
                                <template v-if="Object.keys(the100NNN[actual_d][actual_i]['media']).length>1" v-for="(media_id,pos) in media_show_ids "> <!-- starts at 1 --> 
                                    <span v-if="pos>0" :style="`cursor:pointer; position: absolute; ${(pos<Math.floor(max_number_of_pos/2)+1)?'left:-':'right:-'}${wooh[pos]['x']}${wh_mode}; top:${getVert(pos)+wooh[pos]['y']+'%'}; transform: rotate(${wooh[pos]['rot']}deg); opacity: 1;z-index:${wooh[pos]['z']}`" :key="pos">
                                        <v-avatar @click="curr_main_id=media_id; reshuffle(); autoplay=true" :style="`border-color: ${colors[colors100C[actual_i]]['lighten3']};`"v-if="getUrlImg(the100NNN[actual_d][actual_i],media_id)" rounded class="rounded-lg my_border" :width="wooh[pos]['size']*aspectRatio+wh_mode" :height="wooh[pos]['size']+wh_mode">
                                            <mememedia :meme="the100NNN[actual_d][actual_i]" :media_id="media_id"  :file_base="fileBase(the100NNN[actual_d][actual_i],media_id)" :bgcolor="colors100[actual_i]" />
                                        </v-avatar>
                                    </span>
                            </template>
                            </span>
                        </div>
                    
                    </v-col>
                </v-row>
<!-- THE REST NON-FOCUS (= edit) MODE ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------>
                <v-row v-if="!FocusMode" dense  class="pa-2" fluid>
                    <v-col  cols="4">
                        <!--  2. IMG AVATAR    -->
                        <v-avatar @click.stop="toggleFocusMode();editMeme=the100NNN[actual_d][actual_i]" rounded class="ml-3 mt-0 rounded-lg" :color="`${colors100[actual_i]}`" size="350px" style="cursor:pointer">
                            <v-avatar rounded  :class="`rounded-lg ${colors100[actual_i]} lighten-3`" width="99.5%" height="99.5%" > <!--:size="CropNow?300:500"  object-fit: cover-->
                                 <mememedia :meme="the100NNN[actual_d][actual_i]" :media_id="0" :file_base="fileBase(the100NNN[actual_d][actual_i],0)" :bgcolor="colors100[actual_i]" />
                            </v-avatar>
                        </v-avatar>
                    </v-col>
                    <v-col  cols="8"  style="height:350px; overflow-y: auto;">
                        <!-- 4.a ALTS FOR SPOT!!!!!!!!!! -->
                        <v-row v-if="altsmode" dense class="mt-n2" justify="end" style="cursor:pointer">
                            <span class="overline grey--text text--lighten-3 font-weight-light" style="z-index:1" @click="altsmode=!altsmode">Alternatives for this position:</span>
                            <v-menu open-on-hover   top  offset-y  style="cursor:pointer" v-if="allDims[actual_d]['type']!='c'">
                                <template v-slot:activator="{ on, attrs }">
                                    <span  v-bind="attrs" v-on="on" class="ml-2 overline white--text font-weight-bold">{{FilterMajorLoc}}</span>
                                </template>
                                <v-list style="cursor:pointer">
                                    <v-list-item v-for="loc of Object.keys(FilterMajorLocs)" :key="loc" >
                                    <v-list-item-title @click="setFilterMajorLoc(loc)">{{ loc }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <span v-else class="ml-2 overline grey--text font-weight-thin">ALL</span>
                        </v-row>
                        <v-row v-if="altsmode" dense justify="end" :style="`max-height: 100%; overflow-y: scroll`" class="mt-1">
                            <template v-for="a in alternatives[actual_d][actual_i]">
                                <span v-if="the100NNN[actual_d][actual_i]?!(a['id']==the100NNN[actual_d][actual_i]['id']&&a['inst_id']==the100InstanceId(the100NNN[actual_d][actual_i])):true" >
                                    <!---- FILTER --->
                                    <span v-if="allDims[actual_d]['type']=='c' || (FilterMajorLoc!='all'?FilterMajorLocs[FilterMajorLoc].includes(a['majorLocation']['type']):true)" >
                                        <v-chip class="px-0 mr-1 mb-1 body-2 grey--text text--darken-2" outlined small :color="`${the100NNN[actual_d][actual_i]?(a['id']==the100NNN[actual_d][actual_i]['id'])?colors100[actual_i]+' darken-1':'grey darken-3':'grey darken-3'}`" :key="a['id']+'-'+a['inst_id']">
                                            <v-chip class="px-0 body-2 lighten-3" small color="#272727" >
                                                <!-- the major in the pill itself -->
                                                <v-btn x-small text icon :color="`${colors100[actual_i]} lighten-1`" class="ml-1"  @click="swapMemeNumber({'meme':AllMemes[actual_d].filter(x=>x.id==a.id)[0],'newnumber':actual_i,'inst_id':a['inst_id']}); "><v-icon>mdi-arrow-left</v-icon></v-btn>
                                                <major class="" :instance="a['codeName']" :major="a.major" 
                                                :class_main="`subtitle  ${colors100[actual_i]}--text text--lighten-3 font-weight`"
                                                :class_major="`subtitle-1 white--text font-weight-bold`" />
                                                <v-icon @click="showEdit=true; editMeme=AllMemes[actual_d].filter(x=>x.id==a.id)[0]; newnumber=actual_i" class="mx-1 grey--text text--ligthen-0" style="cursor:pointer" small>mdi-square-edit-outline</v-icon>
                                                <v-chip v-if="a.usedIn" :color="colors100[a.usedIn]" x-small class="pa-1 mr-1">
                                                    <span class="overline white--text font-weight-bold">{{a.usedIn}}</span>
                                                </v-chip >
                                            </v-chip>
                                        </v-chip>
                                    </span> 
                                </span>
                            </template><br>
                        </v-row>

                        <!-- 4.b ALTS FOR SPOT!!!!!!!!!! -->
                        <v-row v-if="!altsmode" dense class="" justify="end"  >
                            <span class="overline grey--text text--lighten-3 font-weight-light" style="z-index:1; cursor:pointer"  @click="altsmode=!altsmode">Alternative spots for this meme:</span>
                        </v-row>
                        <v-row v-if="!altsmode && (the100NNN[actual_d][actual_i] && allPossibileSpots(the100NNN[actual_d][actual_i],actual_i ).length>0) " class="mt-1" dense justify="end">
                            <template v-for="spot of allPossibileSpots(the100NNN[actual_d][actual_i],actual_i )">
                                <v-chip class="px-0 mr-1 mb-1 body-2 grey--text text--darken-2" outlined small :color="`${colors100[spot]} darken-2`" :key="spot" style="cursor:pointer">
                                    <v-chip class="px-0 body-2 lighten-3" small color="#272727" >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span  v-on="on">
                                                <v-chip :color="colors100[spot]" x-small class="ml-1 mt-n1 mr-1 px-1">
                                                    <span class="overline white--text font-weight-bold noselect">{{spot}}</span></v-chip>
                                                <major v-if="inst_n = the100NNN[actual_d][spot]" class="mr-2"  :instance="the100Instance(inst_n).codeName" :major="the100Instance(inst_n).alts[spot]"
                                                :class_main="`subtitle  ${colors100[spot]}--text text--lighten-3 font-weight`"
                                                :class_major="`subtitle-1 white--text font-weight-bold`" />
                                                <span v-else :class="`subtitle  ${colors100[spot]}--text`"><v-icon class="mt-n1" small>mdi-map-marker-radius-outline</v-icon></span>
                                            
                                            </span>
                                        </template>
                                        <span v-for="instance of the100NNN[actual_d][actual_i]['instances']" v-if="Object.keys(instance['alts']).includes(spot)">
                                            <major :instance="instance['codeName']" :major="instance['alts'][spot]"   
                                            :class_main="`subtitle white--text text--ligthen-0 font-weight`"
                                            :class_major="`subtitle-1 ${colors100[actual_i]}--text text--lighten-0 font-weight-bold`"
                                            />
                                        </span>
                                    </v-tooltip>
                                    </v-chip>
                                </v-chip>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="!FocusMode" dense fluid class="px-7 py-1 mb-3 mt-1">
                    <imagesort  :showImageSort="showImageSort" :size="size_sort" :thisMeme="the100NNN[actual_d][actual_i]" @finishedImageSort="showImageSort=false" style="overflow-x: auto; flex-wrap: nowrap;" />
               </v-row>
               
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
    import { mapState,mapActions,mapMutations } from 'vuex'
    import major from '../components/major.vue'
    import imagesort from '../components/imagesort'
    import editmeme from '../components/editmeme'
    import mememedia from '../components/mememedia'
    import colors from 'vuetify/lib/util/colors'

  export default {
    data () {
    return {
        showEdit : false,
        autoplay: false,
        showImageSort:true,
        newnumber:null,
        newdim:null,
        editMeme:null,
        show:null,
        focusEditMode:false,
        altsmode:true,
        //max_size:720,min_size:100,max_size_sm:200, size_sort:60, 
        max_size:720,min_size:100,max_size_sm:200, size_sort:60, 
        aspectRatio:1/1,
        wooh:{},
        ImgAtPos:{},
        max_number_of_pos: 8,
        media_show_ids:[],
        curr_main_id:0,
        wh_mode:"vh",
        colors:colors,
        wheelwait:false,
     }
    },
    computed: {           
        ...mapState('auth',['currentUser']),
        ...mapState('globals',['safeMode','colors100','colors100C']),
        ...mapState('memes',['allMemes','the100Toggle','the100NNN','alternatives']),
        ...mapState('settings',['usrSet','allDims','currentSet','uniqueNNN','showMoreSize','focusMode','filterMajorLoc','filterMajorLocs']),
        the100_sub () {return Object.fromEntries( Object.entries(this.the100NNN[actual_d]).filter(([key]) => this.arr_of_nums.includes(key)))},
        showAllcards(){return this.arr_of_nums.length>10},
        FilterMajorLoc(){return this.filterMajorLoc},
        AllMemes(){return this.allMemes},
        ShowMoreSize(){return this.showMoreSize},
        FocusMode(){return this.focusMode},
        SafeMode(){ return this.safeMode},
        FilterMajorLocs(){return this.filterMajorLocs},
        CropNow(){return this.$vuetify.breakpoint.width<550},
        AllImages(){
        }
    },
    methods:{
        fileBase(meme,spot){let out = 'geen'; try {out= meme['media'][spot]['file_base'] }catch{};  return out; },
        getVert(i){let out = Math.floor((100/Math.floor(this.max_number_of_pos/2))* (Math.ceil(Math.abs(i-(Math.floor(0.5+this.max_number_of_pos/2)+0.5)))-1));return out}, // <!--haal eerste 0.5 weg in floor en dan meer naar rechts -->
        ...mapActions('memes',['swapMemeNumber','updateMemeInFB']),
        ...mapMutations('globals',['toggleSafeMode']),
        ...mapMutations('settings',['toggleShowMoreSize','toggleFocusMode','setFilterMajorLoc']),
        the100InstanceId(meme){return meme['theHundred'][this.currentSet]['instance'] },
        closeDialog(){this.wheelwait=false,this.curr_main_id=0;this.focusEditMode=false;this.$emit('finishedShowMore');},
        the100Instance(meme){  try{return meme['instances'][ meme['theHundred'][this.currentSet]['instance']]} catch{return null}  },
        allPossibileSpots(meme,i){let allPossibileSpots = [];for (let instance_id in meme['instances']){for (let alt in meme['instances'][instance_id]['alts']) { if (!allPossibileSpots.includes(alt) && alt!=i){allPossibileSpots.push(alt)}  }}; return allPossibileSpots.sort() },
        getUrlImg(meme,i){let out = null; try{out = meme['media'][i?i:0]['url']}catch{};return out},
        onWheel(event){
            let that=this; let d=10
             if (event.deltaY<-d && !this.FocusMode &&!this.wheelwait){this.closeDialog()}
            if (event.deltaY>d && this.FocusMode  &&!this.wheelwait){this.closeDialog()}
            if (event.deltaY<-d && this.FocusMode  &&!this.wheelwait){this.toggleFocusMode(); this.wheelwait=true; setTimeout(()=>{that.wheelwait=false},1000)}
            if (event.deltaY>d && !this.FocusMode  &&!this.wheelwait){this.toggleFocusMode(); this.wheelwait=true; setTimeout(()=>{that.wheelwait=false},1000)}
           
            
        },
        reshuffle(){
            for (let pos = 0; pos <=this.max_number_of_pos+1; ++pos){
                this.$set(this.wooh,pos,{})
                this.$set(this.wooh[pos],'z', 1 + Math.floor(Math.random() * this.max_number_of_pos))
                if (pos==0){
                    this.$set(this.wooh[pos],'size',85 + (Math.random() * 5))
                    this.$set(this.wooh[pos],'rot', -5 + (Math.random() * 10))
                }
                if (pos>0){
                    this.$set(this.wooh[pos],'size',90/(1.5+(this.max_number_of_pos/2)) + (Math.random() * 0.5*(90/(1.5+(this.max_number_of_pos/2))))) //die 1.5 kun je gebruien de kleintjes realeif groter te makne
                    this.$set(this.wooh[pos],'rot', -10 + (Math.random() * 20))
                    this.$set(this.wooh[pos],'x',this.wooh[pos]['size']/( 1.1+(Math.random()*2/10)))
                    this.$set(this.wooh[pos],'y', -5 + (Math.random() * 10)) //percentage
                    
                }
            }
            this.media_show_ids = Array.from({length: this.max_number_of_pos+1}, (v, k) => k); 
            this.media_show_ids.sort(() => Math.random() - 0.5)
            let tmp_id = this.media_show_ids.findIndex( (el) => el == this.curr_main_id) 
            this.media_show_ids[tmp_id]=this.media_show_ids[0]
            this.media_show_ids[0]=this.curr_main_id
            //console.log(this.media_show_ids)
            //console.log(this.the100NNN[this.actual_d][this.actual_i]['media'])
        },
    
    },
    created: function(){ 
        this.reshuffle();
    },
    components:{major, editmeme,imagesort,mememedia},
    //watch: {actual_i: function(){if (this.showMore&&showImageSort){showImageSort=false; showImageSort=true}}},
    props: ['actual_i','actual_d','showMore']
}
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
.my_border{
    border: 2px solid;
    outline: 2px solid #272727;
    -moz-border-radius:5px 5px 0 0;
    -webkit-border-radius:5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}
</style>
