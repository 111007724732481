export default {
  changePermutations (instance, pao_mode, cats, this_cat){

    function log(text){
      //if (instance.majorTemplate=='3`-1`5`-1`-2`-1`-1`8`-1`8`-1`6'){console.log(text)}
      //if (instance.displayName=='kagney lynn karter'){console.log(text)}
    }
    //console.log(instance); console.log(pao_mode); console.log(cats); console.log(this_cat)
    try{
      let majorcombos={}
      // >>> dit deel niet elegant maar converteren van major in object van objecten net als het in firebase zou zijn...
      // werkt handig met de functie hieronder
      let mj = {};
      let msplit = instance.codeTemplate.split('-2')

      let offset=0
      for (let n in msplit){
        let sub = msplit[n].split('`').map(x=>x?Number(x):-1)
        let subsub={}
        for (let c in sub){ if (sub[c]>-1) {subsub[Number(c)+offset] = sub[c]} }
        offset+=sub.length-1 //want de space telt mee
        mj[n] = subsub
        }
        //console.log(mj)
        function doit(major, majorcombos){
          var allstarters ={}
          for (let j in Object.keys(major)){
              var m = major[j] // array per naam deel
                var indices = Object.keys(m)
                if (indices.length>0){
                  var i = 1
                   for (let start of indices){
                     if (i==1){allstarters[start]=m[start]}
                     for (let end of indices.slice(i)){
                       var getal = String(m[start])+String(m[end])
                       if (!majorcombos[getal]){
                         majorcombos[getal]=[Number(start),Number(end)]}
                       }
                     i+=1;
                   }
                 }
            }
            return [allstarters]
          }

          //function just for 'c' style, mix with categories:
          function catmix(instance,allstarters,cats){
            //majorcombos={} // want zit nu al crzp in van de niet starters combo
            //eerst alleen de eerste cijfers:=> worden de 2e  dit klinkt omslachtig
            let tmp = {}
            let seconds = {}
            for (let a of allstarters){for (let m of Object.keys(a)){seconds[m]=a[m]}}
            //nu de eerste uit de cats halen
            let potfirsts =[]
            for (let first of Object.keys(cats)){
              if (cats[first].incl){
                if (cats[first].incl.includes(this_cat) )
                  {
                for (let s of Object.keys(seconds)){
                  var getal = String(first)+String(seconds[s])
                  if (!tmp[getal]){
                    tmp[getal]=[Number(s),null]
                  }else{
                      tmp[getal][1]=Number(s) //ook de 1e letter laatste naam wordt dan gehighlight
                  }
                  }
              }}
            }
            return tmp

            //bizat dat dit hier wel moet en boven niet ???

          }


         var allstarters = doit(mj,majorcombos)
         //console.log(allstarters)
         //nice dit zijn alleen de starters en precies welke we nodig hebben console.log(allstarters)
         if (pao_mode=='c'){
           majorcombos = catmix(instance, allstarters, cats)
            }
         else{
            doit(allstarters, majorcombos)
         }
 
         return majorcombos

       }
         catch{error => {console.error("Error writing document: ", error);return {}}}}
    }
