const fb = require('../firebaseConfig.js')

const state = {
  currentUser: null
};

const mutations = {
  setCurrentUser(state, val) {state.currentUser = val}
};

const actions = {
  clearData({commit}){commit('setCurrentUser',null)}
};

const getters=  {
  isAuthenticated (state){return state.idToken !== null;}
};

export default {
  namespaced: true,
  state,   //es6 voor state: state
  mutations,
  actions,
  getters,
};
