<template>
      <v-row justify="center" v-if="encodeShow" class="mt-5"> <!-- easy clear of inputs-->
      <v-dialog :value="encodeShow" persistent scrollable  max-width="600px"  >
      <v-card >
      <v-card-title>
        <v-col class="text-left text-h4 " style="opacity:0.1" >
          <span class="font-weight-light">{{totalTimeElapsed | onlyminutes | dd}}</span>
          <span class="mx-1">:</span>
          <span class="font-weight-light">{{totalTimeElapsed | onlyseconds | dd}}</span>

        </v-col>
        <v-spacer/>
        <v-icon v-if="" @click="CancelRun=true" size="40px" color="pink">mdi-stop-circle</v-icon>
        <v-icon v-if="" @click="submitEncodeRun(); closeDialog()" size="40px" :disabled="!Ready" color="pink">mdi-send-circle</v-icon>
      </v-card-title>
      <v-divider></v-divider>
        <v-container  class="mt-5 px-5">
          <v-row  justify="center" dense class="mt-0">
            <template v-for="j in 3">
              <v-avatar color="grey" size="150" class="mx-1" ><span class="text-h1 font-weight-bold white--text noselect" >{{ paoRun['encode']['set'][ ((currentSet)*3)+j-1 ]}}</span></v-avatar>
            </template>
          </v-row>
          <v-row  justify="center" dense class="mt-5">
            <v-progress-circular color="pink" :rotate="360" :size="100" :width="5" v-model="time_left_perc" >
                <v-icon v-if="pause &!Ready" @click="pause=!pause" :style="`opacity: ${sizetoggle}`" size="80px" color="pink">mdi-play-circle</v-icon>
                <v-icon v-if="!pause &!Ready" @click="pause=!pause"   size="80px" color="pink">mdi-pause-circle</v-icon>
                <v-icon v-if="Ready" @click=""   size="80px" color="pink">mdi-check-circle</v-icon>
            </v-progress-circular>
          </v-row>
        </v-container>
      <v-divider></v-divider>
        <v-container class=" px-5" fluid>
          <v-row  justify="start" dense class="">
            <template v-for="i in paoRun['settings']['NumberOfSets']">
              <div style="cursor:pointer" @click="switchSet(i)">
                <template v-for="j in 3">
                  <v-avatar :color="`grey ${currentSet==i-1?'':'lighten-2'}`" size="25" class="mx" ><span class="font-weight-bold white--text noselect" >{{ paoRun['encode']['set'][ ((i-1)*3)+j-1 ]}}</span></v-avatar>
                </template>
                <v-avatar color="white" size="6" class="mx" ><span class="font-weight-bold white--text noselect" >-</span></v-avatar>
              </div>
            </template>
          </v-row>
        </v-container>
    </v-card>
    </v-dialog>

    <v-dialog v-model="CancelRun" max-width="400">
                 <v-card>
                   <v-card-title style="noselect" class="body-2">This will cancel current run and cannot be undone!</v-card-title>
                   <v-card-actions>
                     <v-btn class="success" dark large text outlined @click="CancelRun=false;">Keep Run Going</v-btn>
                     <v-spacer></v-spacer>
                     <v-btn class="error" large dark outlined @click="CancelRun=false; closeDialog()">Cancel the Run</v-btn>
                   </v-card-actions>
                 </v-card>
      </v-dialog>

  </v-row>






</template>

<script>
import { mapState,mapMutations,mapActions } from 'vuex'
  export default {
    data () {
      return {
        timerId:null,
        pause: true,
        //totalTimeElapsed:0,
        step:0,
        sizetoggle:1,
        timing: {start:null,stop:null},
        currentSet:0,
        interval_delta: 1, //(s)
        step_update_interval:10, //(%)
        time_left_perc:100,
        NumberOfSets:0,
        EndTime:null,
        Ready:false,
        CancelRun:false
      }
    },
    computed: {
        ...mapState('runs',['paoRun']),
        ...mapState('globals',['nu']),
      total_time(){return this.paoRun['settings']['TimePerSet']*this.paoRun['settings']['NumberOfSets']},
      isCurSetOdd(){return (this.currentSet% 2 != 0)},
      totalTimeElapsed(){
        let out = (this.nu-this.paoRun['encode']['start'])/1000
        if (out>(60*60)-20){this.closeDialog(); return null} //niet over ongeveer een uur
        return out}
      },

    methods: {
        ...mapActions('runs',['submitEncodeRun']),
        ...mapMutations('runs',['resetRun']),
      closeDialog(){this.resetRun();clearInterval(this.timerId);this.$emit('finishedEncodeShow')},
      switchSet(i){
        this.currentSet=i-1;
        if (!this.Ready){this.step=0; this.time_left_perc=100}else{clearInterval(this.timerId)}
      },
      init(){
        clearInterval(this.timerId)
        this.step=0;
        this.pause= false;
        this.timing =  {start:null,stop:null}
        this.timerId = null
        this.totalTimeId = null,
        this.currentSet = 0
        this.Ready= false
        if (this.currentSet==this.paoRun['settings']['NumberOfSets']-1){this.Ready=true}
        this.timerOn()
      },
      timerOn() {
          var number_of_steps =Math.floor(this.paoRun['settings']['TimePerSet']/this.interval_delta)-1
          var perc_per_step =100/number_of_steps
          this.timerId = setInterval(() => {

            if (!this.pause){this.step+=1}else{this.sizetoggle=(this.sizetoggle==1)?0.7:1}
              this.time_left_perc= 100 - perc_per_step*this.step
              if (this.step > number_of_steps){
                  var end = new Date().getTime();
                  this.step = 0
                  this.time_left_perc=100

                  if (this.currentSet<this.paoRun['settings']['NumberOfSets']-1){
                    this.currentSet+=1
                    if (this.currentSet==0){
                        this.timing['start'] = new Date().getTime()}
                    }else{clearInterval(this.timerId)}
              }
                // simple click
            }, this.interval_delta*1000);//tolerance in ms
        },

    },
    watch: {
      encodeShow: function(){  if (this.encodeShow){this.init()}  },
      currentSet: function(){  if (this.currentSet==this.paoRun['settings']['NumberOfSets']-1){this.Ready=true}}
    },
    props: ['encodeShow'],
    //mixins: [mixin],
    components:{},
    mounted: function () {}
  }
</script>
