const fb = require('../firebaseConfig.js')

const state = {
    runSettings: {},
    paoRun:{},
    allPaoRuns:[]
}

const mutations = {
    resetRun(state){
      state.paoRun  = {
        'userId': null,
        'encode':{'start':null,'end':null,'total':null,'set':[]},
        'decode':{'start':null,'end':null,'total':null,'set':[]}
      }
    },
    initSettings(state){
      state.runSettings = {'NumberOfSets':18, 'TimePerSet':30, 'RndCorrected':false, 'MinTimeBeforeDecode':10*60}
      if(state.allPaoRuns.length>0){state.runSettings = state.allPaoRuns[0].settings}
    }
};

const actions = {
  initEncodeRun({commit,state}, curRunSettings){
    commit('resetRun');
    state.paoRun['encode']['start'] = new Date()

    if (curRunSettings){curRunSettings; state.runSettings=curRunSettings}
    state.paoRun['settings'] = state.runSettings
    // eerst de settings updaten.... de defaults worden nu dus de nwe totdat je de pagina refreshed
    let NumberOfSets = state.paoRun['settings']['NumberOfSets']
    let weighted_dist = {'person':[...Array(100).keys()] .map((i) =>  ("0" + Number(i)).slice(-2)) ,
            'action': [...Array(100).keys()] .map((i) =>  ("0" + Number(i)).slice(-2)) ,
            'object':[...Array(100).keys()] .map((i) =>  ("0" + Number(i)).slice(-2))
          }
    let out_arr = []
    for (let i = 0; i < NumberOfSets; i++) {
      for (let mode of Object.keys(weighted_dist)){
        var rnd = Math.floor(Math.random() * weighted_dist[mode].length)
        var digit = weighted_dist[mode][rnd]
        out_arr.push(digit)
      }
      }
      state.paoRun['encode']['set'] =  out_arr
    },

    submitEncodeRun({commit,state,rootState}){
      state.paoRun['encode']['end'] = new Date()
      state.paoRun['encode']['total'] = (state.paoRun['encode']['end']-state.paoRun['encode']['start'])/1000

      let insertObj = JSON.parse(JSON.stringify(state.paoRun)) //verkapte manier om copy te maken
      insertObj['userId'] = rootState.auth.currentUser.uid
        fb.runsCollection.add(insertObj).then((docRef) => {
        insertObj['id']=docRef.id
        state.allPaoRuns.unshift(insertObj)//push to start of array... ff checken of dezelfde stijl
      }).catch(error => {console.error("Error writing document: ", error);  })

    },
    initDecodeRun({commit,state},run){
      state.paoRun = JSON.parse(JSON.stringify(run))
      state.paoRun['decode']['start'] = new Date()
      },
    submitDecodeRun({state,person},set){
      state.paoRun['decode']['end'] = new Date()
      state.paoRun['decode']['total'] = (state.paoRun['decode']['end']-state.paoRun['decode']['start'])/1000
      state.paoRun['decode']['set'] = set

      let Errors = {}
      for (let i = 0; i < state.paoRun['encode']['set'].length; i++) {
        if (state.paoRun['encode']['set'][i]!=state.paoRun['decode']['set'][i]){
            Errors[i] = {'encode':state.paoRun['encode']['set'][i],
                              'decode':state.paoRun['decode']['set'][i],
                              'pao': this._vm.$options.filters.pao(i),
                              'type': 0
                            }
        }}
      state.paoRun['errors'] = Errors

      let insertObj = JSON.parse(JSON.stringify(state.paoRun))
      fb.runsCollection.doc(insertObj['id']).set(insertObj)
      .then(function() {
        let i = state.allPaoRuns.findIndex(x=>x.id==insertObj['id']);
        state.allPaoRuns.splice(i,1,insertObj);
        }) .catch(function(error) {console.error("Error writing document: ", error);});
      },
    initResultRun({commit,state},run){
      state.paoRun = JSON.parse(JSON.stringify(run))
    },

    submitResultRun({commit,state},errors){
      state.paoRun['errors'] = errors
      let insertObj = JSON.parse(JSON.stringify(state.paoRun))

      fb.runsCollection.doc(insertObj['id']).set(insertObj)
      .then(function() {
        let i = state.allPaoRuns.findIndex(x=>x.id==insertObj['id']);
        state.allPaoRuns.splice(i,1,insertObj);
        }) .catch(function(error) {console.error("Error writing document: ", error);});

    },

    getAllRunsFromFB({commit,dispatch, state,rootState}){
    state.allPaoRuns= []
    fb.runsCollection.where('userId','==',rootState.auth.currentUser.uid).orderBy('encode.start','desc')//.limit(20)//.where('category','==','actor')
        .get()
        .then(function(querySnapshot) {
          state.allPaoRuns= []
            querySnapshot.forEach(function(doc) {
                var p = doc.data()
                p['id']=doc.id
                state.allPaoRuns.push(p)
              })
              commit('initSettings')
        })
        .catch(function(error) {
            console.log("Error getting Runs: ", error);
        });
      },

      removeRunFromFB({commit,dispatch, state,rootState},run) {
        fb.runsCollection.doc(run.id).delete().then(function() {
          state.allPaoRuns.splice(state.allPaoRuns.findIndex(x=>x.id==run.id),1);
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
};


const getters=  {
};

export default {
  namespaced: true,
  state,   //es6 voor state: state
  mutations,
  actions,
  getters,
};
