<template>
  <v-dialog :value="showSets" scrollable persistent max-width="600px"  @click:outside="closeDialog()">
    <v-card> 
       <v-overlay absolute :opacity="0.5" :value=loading persistent>
        {{loadmessage}}
        <v-progress-circular :size="50" color="primary"  indeterminate ></v-progress-circular>
      </v-overlay>

      <v-card-subtitle><v-tabs v-model="tab"><v-tab v-for="tab of tabs">{{tab}}</v-tab></v-tabs>
     
      </v-card-subtitle>
      
      <!-- SETS-->
      <v-card-text class="mt-0" v-if="tabs[tab]=='sets'">
        <v-row dense class="pa-0 mt-0" fluid v-if="AllSets">
          <v-card v-if="Object.keys(AllSets).length>0" v-for="spot of Object.keys(AllSets)" class="d-inline-flex pa-2 mr-2 " tile rounded color=""  :class="`${dragSpotSet==spot?'lighten-4':''}`">
            <v-row dense draggable @dragstart="startDragSet($event, spot)"  style="cursor:pointer" :key='spot'>
              <div @dragover="dragSpotSet=spot" @dragleave ="dragSpotSet=null" @dragover.prevent @drop="onDropSet($event, spot)">
                <span  style="cursor:pointer" class="purple--text font-weight-bold pa-2">
                  <v-icon v-if="spot==currentSet" class="ml-n2 mr-1" small color="purple">mdi-rhombus-split</v-icon>
                  <v-icon v-if="edit_set==spot" class="ml-1" small color="purple">mdi-delete-outline</v-icon>
                  <v-form  v-if="edit_set==spot" class="d-inline-flex" ref="editform" v-model="valid" @submit.prevent lazyValidation >
                    <v-text-field @keyup.native.enter=""  class="pa-0" dense counter maxlength="25" rows="1" v-model="edit_set_name" :rules="nonEmptyRule" />
                   </v-form>
                  <span v-if="edit_set!=spot"  @dblclick="loading = true; setCurrentSet(spot)"  >{{AllSets[spot]['name']}}</span>
                  <v-icon v-if="edit_set==null" @click="edit_set=spot; edit_set_name=AllSets[spot]['name']" class="ml-1" small color="purple">mdi-square-edit-outline</v-icon>  
                  <v-icon v-if="edit_set==spot" @click="edit_set=null; edit_set_name=null" class="ml-1" small color="purple">mdi-close-circle-outline</v-icon>  
                  
                  </span>
              </div>
            </v-row>
          </v-card>
          <v-icon medium style="cursor:pointer" @click="" class="grey--text text--lighten-1">mdi-plus-circle</v-icon>
        </v-row>
        <v-row>
        
          </v-row>
      </v-card-text>

      <!-- SEQUENCE-->
      <v-card-text class="mt-0" v-if="tabs[tab]=='sequence'">
        <v-row dense class="pa-0 mt-0" fluid>
          <v-card v-if="allSpotsSeq.length>0" v-for="(dim,spot) of allSpotsSeq" class="d-inline-flex pa-2 mr-2 " rounded :color="`${spot<=splitIndex?spot==splitIndex?'white':'purple':'grey'}`" :class="`${dragSpotSeq==spot?'lighten-4':''}`">
            <v-row dense draggable @dragstart="startDragSeq($event, spot)"  style="cursor:pointer" :key='spot'>
              <div @dragover="dragSpotSeq=spot" @dragleave ="dragSpotSeq=null" @dragover.prevent @drop="onDropSeq($event, spot)">
                <v-icon v-if="spot!=splitIndex" large class="purple--text text--lighten-4">mdi-{{allDims[allSpotsSeq[spot]]['icon']}}</v-icon>
                <v-icon v-else large class="purple--text text--lighten-4">mdi-arrow-right-box</v-icon>
              </div>
            </v-row>
          </v-card>
        </v-row>
      </v-card-text>
      <!-- DIMS-->
      <v-card-text class="mt-0" v-if="tabs[tab]=='dims'">
       
      </v-card-text>
      <!-- USR-->
      <v-card-text class="mt-0" v-if="tabs[tab]=='usr'">
        <v-row  class="pa-1 mt-0" fluid fill-height>
          <v-col  cols="6">
            <div  @click="showService = false; downloadDBase()" style="cursor:pointer"><v-icon x-large class="grey--text text--darken-3">mdi-database-arrow-down</v-icon> download data to json</div>
          </v-col>
          <!-- -->
         
          <v-col  cols="1">
            <span  @click="doit()" style="cursor:pointer" class="grey--text text--darken-3">do it</span>
          </v-col>
          <v-col cols="6" class="pa-2"> <v-row  class="pa-0 mt-0" fluid fill-height>
            <croppa class="d-flex" v-model="myCroppa"  :width="avatar_size" :height="avatar_size" 
            :show-remove-button="true"  remove-button-color="#9A1750" :remove-button-size="10" 
            :prevent-white-space="true" :zoom-speed="4"
            canvas-color="purple" :quality="1" :video-enabled=false></croppa>
            <span align="top" v-if="croppaHasMedia" @click="myCroppa.generateBlob((blob) => {addMedia(blob)}, 'image/jpeg', 0.8); " style="cursor:pointer"><v-icon x-large class="grey--text text--darken-3">mdi-upload</v-icon></span>
           </v-row></v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>


<script>
  import { mapState,mapMutations,mapActions } from 'vuex'
  import 'vue-croppa/dist/vue-croppa.css'
  import { ObjectID } from 'bson';
  const Dropbox = require('dropbox').Dropbox;
  export default {
    data () {
      return {
        tabs:{0:'sets',1:'sequence',2:'dims',3:'usr'},tab:3,
        dragSpotSeq:null, allSpotsSeq:['|'],
        dragSpotSet:null,
        loading:false, loadmessage:'busy',
        nonEmptyRule: [  v => !!v || 'required' ],
        parentRules: [
            v => !!v || 'parent required',
            v => !/[^a-zA-Z0-9 ]/.test( v ) || 'no special characters'
          ],
        edit_set_name:null, edit_set:null,valid: false,
        myCroppa:null, avatar_size:100,
      }
    },
    computed: {
       ...mapState('auth',['currentUser']),
      ...mapState('service',['db_done']),
      ...mapState('settings',['usrSet','allDims','setSequence','currentSet','dbx_token']),
      ...mapState('memes',['the100Toggle']),
      ...mapState('globals',['mediaPath']),
      splitIndex(){return this.allSpotsSeq.indexOf('|')},
      CurrentSet(){return this.currentSet},
      AllSets(){return this.usrSet['sets']},
      croppaHasMedia(){let m = this.myCroppa; try{return m.hasImage()}catch{return false}},
     
    },
    methods: {
       ...mapActions('service',['downloadDBase','safeDBase','doit']),
      ...mapActions('settings',['updateSettings','setCurrentSet']),
      closeDialog(){if(!this.loading||1){this.$emit('finishedEditSets')}},
      startDragSeq (evt, org_spot_seq) {evt.dataTransfer.setData('org_spot_seq', org_spot_seq); evt.dataTransfer.dropEffect = 'move';evt.dataTransfer.effectAllowed = 'move'},
      onDropSeq (evt, new_spot_seq) {let org_spot_seq = evt.dataTransfer.getData('org_spot_seq');this.swapSequence(org_spot_seq, new_spot_seq); this.initSpots() },
      
      startDragSet (evt, org_spot_set) {evt.dataTransfer.setData('org_spot_set', org_spot_set); evt.dataTransfer.dropEffect = 'move';evt.dataTransfer.effectAllowed = 'move'},
      onDropSet (evt, new_spot_set) {let org_spot_set = evt.dataTransfer.getData('org_spot_set');this.swapSet(org_spot_set, new_spot_set); this.initSpots() },
   
      initSpots(){
        this.allSpotsSeq = JSON.parse(JSON.stringify(this.setSequence))
        this.allSpotsSeq.push('|'); Object.keys(this.allDims).forEach(dim => { if (!this.allSpotsSeq.includes(dim)){this.allSpotsSeq.push(dim)}});
        this.dragSpotSeq=null;this.dragSpotSet=null;
        } ,
      swapSequence(org_spot_seq, new_spot_seq){      //['8','3','6','|','0','1']
        this.loading = true
        org_spot_seq = Number(org_spot_seq); new_spot_seq = Number(new_spot_seq)
        if (org_spot_seq!=new_spot_seq){               //3 > 0   spots 1 > 4
          if (org_spot_seq<new_spot_seq){ new_spot_seq+=1}
          this.allSpotsSeq.splice(new_spot_seq,0,this.allSpotsSeq[org_spot_seq])
          if (org_spot_seq>new_spot_seq){ org_spot_seq+=1}
          this.allSpotsSeq.splice(org_spot_seq,1)
          this.allSpotsSeq.splice(this.allSpotsSeq.indexOf('|'))
          this.usrSet['sets'][this.currentSet]['sequence'] =  this.allSpotsSeq
          this.updateSettings(this.usrSet) 
          }
         this.loading=false
        },
        swapSet(org_spot_set, new_spot_set){      //['8','3','6','|','0','1']
          if (org_spot_set!=new_spot_set){
            /*
            this.loading=true;  
            let new_set =  JSON.parse(JSON.stringify(this.AllSets))
            new_set[new_spot_set] = this.AllSets[org_spot_set]
            new_set[org_spot_set] = this.AllSets[new_spot_set]
            this.usrSet['sets'] = new_set
            this.updateSettings(this.usrSet) 
            this.loading=false
            */
        }},
      addMedia(blob) {
        const dbx = new Dropbox({ accessToken : this.dbx_token ,fetch: require("isomorphic-fetch")  })
        let that = this
        this.loading=true; this.loadmessage='Uploading media to dropbox'
        let file_name = '/'+this.currentUser.uid+'/avatar.jpg'
        dbx.filesDeleteV2({path: file_name}).then((res)=>{}).catch((err)=>{})
        .finally(()=>{
          dbx.filesUpload({path: file_name, contents: blob})
          .then(function(responseUL) {
            // Create sharable link (409 if already exists)
            dbx.sharingCreateSharedLinkWithSettings({"path": file_name}) //filesGetThumbnail
              .then(function(responseLnk) {
                that.usrSet['avatar'] = responseLnk.result.url.replace('dl=0','dl=1')
                that.updateSettings(that.usrSet) 
                that.loading=false
                })
              .catch(function(error) {console.log("got error:"+error); that.loading=false; });
          }).catch((error) => {console.log('error happened uploading: '+error); that.loading=false;});
          })
     }
    },
    watch: {
      setSequence: function(){this.initSpots()},
      the100Toggle: function(){this.loading=false},
      Spots: function(){console.log(this.Spots)},
      db_done: function(){if (this.db_done==3){this.safeDBase()}  },
    },
    components:{},
    mounted: function () {this.initSpots();  },
    props:['showSets']
}
</script>
<style scoped>
  .croppa-container {width: 100px; height: 100px;}
</style>