<template>
    <v-dialog :value="showCropper" scrollable persistent class="mx-auto" max-width="500px" @click:outside="closeDialog()">
        <v-overlay absolute :opacity="0.5" :value=loading>{{loadmessage}} <v-progress-circular :size="50" color="primary"  indeterminate ></v-progress-circular></v-overlay>
        <v-card color="#272727">
            <v-row dense fluid class="mt-2 px-5" justify="center">
                <v-spacer/>
                <span  @click="myCroppa.flipY()" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-flip-vertical</v-icon></span>
                <span  @click="myCroppa.flipX()" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3 mr-2`">mdi-flip-horizontal</v-icon></span>
                <span  @click="myCroppa.rotate(1)" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-rotate-right-variant</v-icon></span>
                <span  @click="myCroppa.rotate(-1)" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3 mr-2`">mdi-rotate-left-variant</v-icon></span>
                <span  @click="myCroppa.zoom('in',-0.1)" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-magnify-minus-outline</v-icon></span>
                <span  @click="myCroppa.zoom('in',0.1)" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-magnify-plus-outline</v-icon></span>
                <!--<span  @click="" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-video-vintage</v-icon></span>-->
        
        
            </v-row>
            <v-row dense fluid class="mt-2" justify="center">
                <croppa
                v-model="myCroppa"
                :width="size/scale"
                :height="size/scale"
                :initial-image="initial_img"
                :prevent-white-space="ws"
                :zoom-speed="zoomspeed"
                placeholder="insert image"
                placeholder-color="#272727"
                :placeholder-font-size="16"
                :canvas-color=curColor
                :show-remove-button="false"
                :quality="scale"
                :video-enabled="true"
                :replace-drop="true"
                />
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
                <span :class="`overline ${curColor}--text text--lighten-3`"  @click="closeDialog()" style="cursor:pointer">cancel</span>
                <v-spacer/>
                <span  v-if="croppaHasMedia" :class="`overline  ${curColor}--text text--lighten-3`"  @click="myCroppa.generateBlob((blob) => {addMedia(blob,'jpg')}, 'image/jpeg', 0.8);" style="cursor:pointer">upload image</span>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const { promises: fs } = require("fs");
import { ObjectID } from 'bson';
import { mapState,mapMutations,mapActions } from 'vuex'
import 'vue-croppa/dist/vue-croppa.css'
const Dropbox = require('dropbox').Dropbox;

const fb = require('../firebaseConfig.js')
  export default {
    data () {
      return {
        size: 720, scale: 1.5,
        loading:false, loadmessage:'',ws:true,
        myCroppa: {}, zoomspeed:5,
       
    }},
    computed: {
      ...mapState('auth',['currentUser']),
       ...mapState('settings',['dbx_token','currentSet']),
      ...mapState('globals',['safeMode','mediaPath','colors100']),
      croppaHasMedia(){ let m = this.myCroppa;   try{return m.hasImage()}catch{return false}},
      SafeMode(){return this.safeMode},
       curColor(){try{return this.colors100[this.thisMeme['theHundred'][this.currentSet]['digits']]}catch{return 'grey'}}
    },
    methods: {
      ...mapActions('memes',['updateMemeInFB']),
      ...mapMutations('globals',['toggleSafeMode']),
      closeDialog(){this.myCroppa.remove();this.$emit('finishedCropper');},
     
      addMedia(blob, ext) {

        const dbx = new Dropbox({ accessToken : this.dbx_token ,fetch: require("isomorphic-fetch")  })
        let that = this
        this.loading=true; this.loadmessage='Uploading media to dropbox'
        let full_path = '/'+this.currentUser.uid+'/'+this.mediaPath['base']+'/'+ext+'/'
        let file_base = this.thisMeme.id+'_'+new ObjectID().toString()
        dbx.filesUpload({path: full_path+file_base+'.'+ext, contents: blob})
          .then(function(responseUL) {
            // Create sharable link (409 if already exists)
            dbx.sharingCreateSharedLinkWithSettings({"path": full_path+file_base+'.'+ext}) 
              .then(function(responseLnk) {
                  if (!Object.keys(that.thisMeme).includes('media')){that.thisMeme['media']={}} 
                  let new_pos = that.media_id//Object.keys(that.thisMeme[ext]).length
                  that.$set(that.thisMeme['media'],new_pos, {'file_base': file_base ,'url':responseLnk.result.url.replace('dl=0','dl=1'),'type':ext})
                  that.updateMemeInFB(that.thisMeme)
                  that.loading=false
                })
              .catch(function(error) {console.log("got error:"+error) })
          }).catch((error) => {console.log('error happened uploading: '+error)})
           .finally(()=>{that.loading=false;this.closeDialog()})
          
     }
    },
    props: ['showCropper','thisMeme','media_id','initial_img'],
    created: function () {
      //console.log(this.myCroppa)
    
    
      },
    watch: { initial_img: function(){try{this.myCroppa.refresh(); this.ws=false;this.ws=true;}catch{}} },
    components:{}
  }
</script>

