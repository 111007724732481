import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase/compat/app';


import Login from './views/Login'
import PAO from './views/PAO'
import the100 from './views/the100'
//import tmp from './views/tmp'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        //{path: '/service-worker.js',redirect: '/service-worker.js'},
        {path: '*',redirect: '/pao'},
        {path: '/login', name: 'Login', component: Login},
        {path: '/pao', name: 'PAO', component: PAO, meta: {requiresAuth: true}},
        {path: '/the100', name: 'the100', component: the100, meta: {requiresAuth: true}},

       // {path: '/tmp', name: 'tmp', component: tmp, meta: {requiresAuth: true}},


    ]
})
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const currentUser = firebase.auth().currentUser

    if (requiresAuth && !currentUser) {next('/login')}
    else if (requiresAuth && currentUser) {next()}
    else if (to.name=='Login' && currentUser) {next('/pao')}
    else {next()}
})

export default router
