const fb = require('../firebaseConfig.js')
// A. STATE ---------------------------------------------------------------------------------------------------------

const state = {
  currentSet:-1, currentSetOrder:-1,
  setSequence:[], //array with dims in proper order of Set
  usrSet:{},
  allDims:{}, // all dimensions used in currentSet; including pao_type and cats
  //notAllDims:{}, // all dimensions not used in currentSet; including pao_type and cats
  dbx_token:null,
  showMoreSize:'',
  focusMode:'',
  filterMajorLoc:'all',
  filterMajorLocs: {'first':['first'],'last':['last'],'single':['first','middle','last'],'separated':['multiple'],'all':['first','last','middle','multiple']},
  majorCode:{},
}
// B. MUTATIONS ---------------------------------------------------------------------------------------------------------
const mutations = {  
  initSettings(state){
    state.setSequence=[];  state. usrSet={}; state.allDims={}; state.notAllDims={};        
  },
  toggleFocusMode(state){
    state.focusMode=!state.focusMode
    fb.settingsCollection.doc(state.usrSet['id']).update("focusMode",state.focusMode).then(function() {}) .catch(function(error) {console.error("Error writing document: ", error);});
  },
  toggleShowMoreSize(state){
    //console.log(state.showMoreSize)
    switch(state.showMoreSize){
      case 'actual': {state.showMoreSize = 'small'; break;}
      case 'large' : {state.showMoreSize = 'actual'; break;}
      case 'small' : {state.showMoreSize = 'large'; break;}
    }
    //console.log(state.showMoreSize)
    fb.settingsCollection.doc(state.usrSet['id']).update("showMoreSize",state.showMoreSize)
    .then(function() {})
    .catch(function(error) {console.error("Error writing document: ", error);});
  },
 
  setFilterMajorLoc(state,filterMajorLoc){
    state.filterMajorLoc=filterMajorLoc
    fb.settingsCollection.doc(state.usrSet['id']).update("filterMajorLoc",state.filterMajorLoc).then(function() {}) .catch(function(error) {console.error("Error writing document: ", error);});
  },
  
  };
// C. ACTIONS ---------------------------------------------------------------------------------------------------------
const actions = {
  setCurrentSet({dispatch,commit,state},new_set){
    if (new_set && new_set!=state.currentSet){
      state.currentSet=new_set
      fb.settingsCollection.doc(state.usrSet['id']).update("currentSet",state.currentSet).then(function() {}) .catch(function(error) {console.error("Error writing document: ", error);});
      //commit('memes/initMemes',null,{root:true})
      dispatch('getSettings')}
  },
  updateSettings({dispatch,state},settings){
      //console.log(settings)
      let swapObj = JSON.parse(JSON.stringify(settings)); 
      swapObj['lastModifiedOn'] = new Date()
      fb.settingsCollection.doc(state.usrSet['id']).set(swapObj).then(function() { dispatch('getSettings') }) .catch(function(error) {console.error("Error writing document: ", error);});
     
  },
  getSettings({dispatch,commit,state,rootState}){
    commit('initSettings')
    let that=this;
    fb.settingsCollection.where('userId','==',rootState.auth.currentUser.uid)//.limit(20)//.where('category','==','actor')
        .get()
        .then(function(querySnapshot) {
            let error=false
            querySnapshot.forEach(function(doc) {
              let usr = doc.data()
              state.usrSet = usr
              state.usrSet['id']=doc.id
              state.dbx_token = state.usrSet['dbx_token']
              state.showMoreSize =state.usrSet['showMoreSize']
              state.focusMode=state.usrSet['focusMode']
              state.showNamesOnAll=state.usrSet['showNamesOnAll']
              state.filterMajorLoc=state.usrSet['filterMajorLoc']
              state.majorCode = state.usrSet['majorCode']
              state.currentSet = state.usrSet['currentSet']
              for (let s of Object.keys(state.usrSet['sets'])){
                //that._vm.$set(state.allSets, s, {'name':state.usrSet['sets'][s]['name'],'setId':state.usrSet['sets'][s]['setId']})
                //if (state.usrSet['sets'][s]['setId'] == state.currentSet){
                  if (s == state.currentSet){
                  state.setSequence = state.usrSet['sets'][s]['sequence']
                  //state.currentSetOrder = s
                }
              } // anders update Navbar niet
              state.allDims = state.usrSet['dims']
              
              });
              if (!error){
                dispatch('memes/getAllMemesFromFB',null,{ root: true })
              }else{
                console.log('Error!, Set Uses dimension for than once!')
                state.usrSet={}; state.allDims={}; state.notAllDims={}
              }
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });

  },
  
};
export default {
  namespaced: true,
  state,   //es6 voor state: state
  mutations,
  actions,
};
