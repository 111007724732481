<template>
  <div @dragover.prevent @dragover.prevent @dragleave.prevent @drop.prevent>
      <!-- MENU LEFT  --------------------------------------------------------------------------------->
      <v-navigation-drawer app dark floating hide-overlay dense class="" width="80" color="#272727"  permanent > 
          <v-row dense  fill-height  class="mt-2">
            <v-col class="text-center" >
              <div  @click="toggleSafeMode()" style="cursor:pointer"><v-icon class="d-flex grey--text text--darken-1 ml-1 text-center mt-2" style="cursor:pointer" medium >mdi-eye{{SafeMode?'-off':''}}</v-icon></div>
              <div class="mt-5"></div>
              <template v-if="!transposed&&setSequence.length>0" v-for="dim of setSequence" >
                <div  style="cursor:pointer"  @click="actual_d = dim"  :key='dim'>
                  <v-icon large :class="`grey--text text--${actual_d==dim?'darken-1':'darken-3'}`">mdi-{{AllDims[dim]['icon']}}</v-icon>
                </div>
              </template>
              <template v-if="transposed" v-for="(t,i) of Object.keys(subOfTens)" >
                <div  style="cursor:pointer"  @click="namesToShow?namesToShow=subOfTens[t][namesToShow.charAt(1)] :null; actual_t = t;"  :key='i'>
                  <v-chip :class="`py-0 px-2 mb-1 grey--text text--${actual_t==t?'darken-1':'darken-3'}`" outlined :color="`grey--text text--${actual_t==t?'darken-1':'darken-3'}`"  style="cursor:pointer">{{t}}</v-chip>
                </div>
              </template>
              <div class="mt-5"></div>
                  <div v-if="showAll100" @click="transposed=!transposed; namesToShow=null" style="cursor:pointer"><v-icon class="d-flex grey--text text--darken-1 ml-1 text-center mt-2" style="cursor:pointer" medium >mdi-swap-{{transposed?'vertical':'horizontal'}}-circle-outline</v-icon></div>
           </v-col>
          </v-row>
      </v-navigation-drawer>
      <!-- MENU RIGHT  --------------------------------------------------------------------------------->
      <v-navigation-drawer app dark floating hide-overlay dense width="80" color="#272727" permanent right>
        <v-row dense  fill-height class="mt-2">
          <v-col class="text-center" >
            <v-avatar round  size="50px" class="grey darken-2" @click="showSets=true"  style="cursor:pointer">
              <img class="pa-1" v-if="getUrlAvatarUsr()"  :src="getUrlAvatarUsr()"/>
              <img  class="pa-1" v-else="getUrlAvatarUsr()" src="../assets/img/anon.jpg"/>
            </v-avatar>   
            <div  @click="$router.push('/pao')" style="cursor:pointer"><v-icon class="d-flex grey--text text--darken-1 ml-1 text-center mt-2" style="cursor:pointer" large >mdi-brain</v-icon></div>
             <div v-if="!transposed" style="cursor:pointer" class="mt-5">
              <div  @click="showAllMemes=true; showAll100=false;" ><v-icon v-if="!showAllMemes"class="grey--text darken-1 ml-1" medium >mdi-all-inclusive-box-outline</v-icon></div>
              <div  @click="arr_of_nums=Array(100);showAll100=true; showAllMemes=false" ><v-icon v-if="!showAll100" class="grey--text text--darken-1 ml-1 text-center" medium >mdi-border-all</v-icon></div>
              </div>
            <editsets :showSets="showSets"  @finishedEditSets="showSets=false;" />
            <span v-if="setSequence.length>0" class="mt-10" style="writing-mode: vertical-rl; transform: rotate(0deg)">
              <span class="subtitle-1 grey--text text--darken-1 font-weight-bold">{{CurrentSetName}} | </span>
              <span v-if="!transposed" class="title grey--text text--darken-0"> {{AllDims[actual_d]['name']}} </span>
              <template v-if="transposed&&setSequence.length>0" v-for="dim of setSequence">
                <span><v-icon medium :class="`ml-n2 ${colors100[subOfTens[actual_t][0]]}--text text--darken-3`">mdi-{{AllDims[dim]['icon']}}</v-icon></span>
              </template>
            </span>
            <div><v-avatar v-if="!transposed&&totals[actual_d]?totals[actual_d]['unfilled']>0:false" class="caption red lighten-2 transparant--text" size="22">{{totals[actual_d]['unfilled']}}</v-avatar></div>
            <div v-if="!transposed" class="mt-5" @click="showEdit=true; editMeme=null; newnumber=null; newdim=actual_d"><v-icon medium style="cursor:pointer" class="grey--text text--darken-1">mdi-plus-circle</v-icon></div>
            </v-col>
        </v-row>
      </v-navigation-drawer>
    <!-- The MAIN  -------------------------------------------------------------------------------------->
    <v-main class=" mb-0 mt-n5" fluid  >
      <v-sheet  min-height="102vh" height="100%" color="#272727">
        <v-container class="mt-2" v-if='The100NNN[actual_d] && AllDims[actual_d]' fluid>
          <v-row  dense class="pa-0" justify="center" no-gutters v-if="!showAllMemes" >
            <!-- ALL CARDS -------------------------------------------------------------------------------------->
            <template  v-for="(subSub,itM) in CropNow?[[0,5],[5,10]]:[[0,10]]"> <!--dit kan wellicht netter, maar kweet niet hoe je kan forceren dat ie op 5 breakt-->
              <v-row dense no-gutters fluid :key="itM">
                <!-- itX = "00-09" - "90-99" of "01","02",..."09"-->
                <template v-if="showAll100" v-for="(itX,ititx) in transposed?subOfTens[actual_t].slice(subSub[0],subSub[1]): Object.keys(subOfTens).splice(subSub[0],subSub[1])" >
                  <v-col :color="colors100[itX]" :keys="itX"> <!--:cols="(namesToShow == itX)?($vuetify.breakpoint.width<750)?4:2:''"  -->
                    <div @click="namesToShow=(namesToShow==itX)?null:itX" :class="`text-center mt-${transposed?2:0} mb-${transposed?3:1}`" style="cursor:pointer">
                      <v-icon  v-if="!transposed" :class="`${(namesToShow==itX)?colors100[itX]:'grey'}--text text--${namesToShow!=itX?'darken-3':'lighten-1'}`" small>mdi-tag-{{namesToShow!=itX?'text':'faces'}}</v-icon>
                      <v-avatar v-else :color="(namesToShow==itX || namesToShow==null)?colors100[itX]:'grey darken-3'"  size="25" class="lighten-0 caption font-weight-bold white--text" round> {{itX | dd}}</v-avatar>
                    </div>
                    <template v-for="itY in transposed?setSequence:subOfTens[itX]">
                      <span v-if="(ThisMeme = transposed?The100NNN[itY][itX]:The100NNN[actual_d][itY]) || true"> <!-- just to make ThisMeme the or makes sure null goeds through -->
                        <v-card  tile class="mx-0 pa-0"  dark  :color="ThisMeme?colors100[transposed?itX:itY]:'grey'"   @click="(namesToShow!=itX&namesToShow!=null)?(namesToShow=itX):ShowMore(transposed?itY:actual_d,transposed?itX:itY)" :key="itM+'-'+itY+'-'+itX" :style="`opacity: ${namesToShow!=itX&namesToShow!=null?0.05:1}`">
                          <v-row dense class="mx-0 pa-0" justify="end" style="overflow-x:scroll">
                            <v-avatar v-if="namesToShow==null&&!transposed" :color="colors100[transposed?itX:itY]"  style="position: absolute; top: 15%; left: 8%;transform:translate(-50%, -50%);opacity: 1; z-index:2" size="20" class="mr-1 lighten-0" round><span class="caption font-weight-bold white--text">{{transposed?itX:itY | dd}}</span></v-avatar>
                            <v-avatar rounded class="pa-auto"  height="10vh" width="100%">
                              <mememedia :meme="ThisMeme" :media_id="0" :file_base="fileBase(ThisMeme,0)" :bgcolor="colors100[transposed?itX:itY]"/>
                              <!-- mememedia
                              <img  v-if="getUrlAvatar(ThisMeme) && SafeMode" style="opacity:0.6;object-fit: cover" src="../assets/img/anon.jpg"/>
                              <img  v-if="getUrlAvatar(ThisMeme) && !SafeMode" :src="getUrlAvatar(ThisMeme)" style="object-fit: cover"/>
                              <img v-if="!getUrlAvatar(ThisMeme) && ThisMeme" style="opacity:0.3;object-fit: cover" src="../assets/img/unknown.jpg"/>
                              <img v-if="!getUrlAvatar(ThisMeme) && !ThisMeme" style="opacity:0.2;object-fit: cover " src="../assets/img/noone.jpg"/>
                              -->
                            </v-avatar>
                            <v-card class="mx-0 pa-0" v-if="namesToShow==itX"  :style="`position: absolute; top: 0%; ${leftOrRight(ititx)}: 115%;  ;opacity: 1; z-index:1;`" >
                              <v-row dense class="mx-0 pa-0" :justify="`${leftOrRight(ititx)=='right'?'end':'start'}`">
                                  <div style="white-space: nowrap;">
                                  <v-avatar v-if="leftOrRight(ititx)=='left'" :color="colors100[transposed?itX:itY]"  size="25" class="mr-2 lighten-0 title-1 font-weight-bold white--text" round>  <v-icon v-if="transposed" small >mdi-{{AllDims[itY]['icon']}} </v-icon> <span v-else>{{transposed?itX:itY | dd}}</span></v-avatar>
                                  <major class= "" v-if="the100Instance(ThisMeme)"  :instance="the100Instance(ThisMeme).codeName" :major="the100Instance(ThisMeme).alts[transposed?itX:itY]"
                                      :class_main="`title white--text text--lighten-3 font-weight-light`"
                                      :class_major="`title-1 ${colors100[transposed?itX:itY]}--text text--lighten-1 font-weight-bold`"/>
                                    <span v-else class="mt-5" @click.stop="showEdit=true; editMeme=null; newnumber=null; newdim=transposed?itY:actual_d"><v-icon medium style="cursor:pointer" class="grey--text text--darken-1">mdi-plus-circle</v-icon></span>
                                    <v-avatar v-if="leftOrRight(ititx)=='right'" :color="colors100[transposed?itX:itY]"  size="25" class="ml-2 lighten-0 title-1 font-weight-bold white--text" round>  <v-icon v-if="transposed" small >mdi-{{AllDims[itY]['icon']}} </v-icon> <span v-else>{{transposed?itX:itY | dd}}</span></v-avatar>
                                  </div>
                              </v-row>
                            </v-card>
                          </v-row>
                        </v-card>
                      </span>
                    </template>
                  </v-col>
                </template>
              </v-row>
            </template>
          </v-row>
          <!-- ALL MEMES  --------------------------------------------------------------------------------->
          <v-row dense fluid v-if="showAllMemes" class="mt-5 px-5">
              <span class="overline grey--text text--lighten-3 font-weight-bold">memes not in the100 </span>
              <v-spacer/>
              <span @click="allMemesSort=!allMemesSort" style="cursor:pointer" ><v-icon class="grey--text text--darken-1" medium >mdi-{{allMemesSort?'sort-alphabetical-ascending':'sort-calendar-ascending'}}</v-icon></span>
          </v-row>
          <v-row dense justify="start" fluid v-if="showAllMemes" class="mt-5  px-5">
              <template  v-for="meme of AllMemesSubSet" class="ma-0 pa-0" >
                <v-chip :key="meme.id" small  v-if="meme['theHundred'][CurrentSet]?i=meme['theHundred'][CurrentSet]['digits']:false" class="px-0 mr-1 mb-1 body-2 grey--text text--darken-2" outlined  :color="`${colors100[i] +' darken-1'}`"  @click="showEdit=true; editMeme=meme" style="cursor:pointer">
                  <v-avatar>
                    <mememedia :meme="meme" :media_id="0" :file_base="fileBase(meme,0)" :bgcolor="colors100[i]"/>
                    <!--
                    <img  v-if="getUrlAvatar(meme) && SafeMode" style="opacity:0.6" src="../assets/img/anon.jpg"/>
                    <img  v-if="getUrlAvatar(meme) && !SafeMode" :src="getUrlAvatar(meme)"/>
                    <img v-if="!getUrlAvatar(meme) && meme" style="opacity:0.3" src="../assets/img/unknown.jpg"/>
                    <img v-if="!getUrlAvatar(meme) && !meme" style="opacity:0.2" src="../assets/img/noone.jpg"/>
                    -->
                  </v-avatar>
                  <major v-if="the100Instance(meme)" class="ml-1" :instance="the100Instance(meme).codeName" :major="the100Instance(meme).alts[i]"
                    :class_main="`subtitle  ${colors100[i]}--text text--lighten-3 font-weight`"
                    :class_major="`subtitle-1 white--text font-weight-bold`" />
                  <v-chip v-if="i" :color="colors100[i]" outlined small class="pa-1 ml-1">
                    <span class="overline white--text font-weight-bold">{{i | dd}}</span>
                  </v-chip >
                </v-chip>

                <v-chip :key="meme.id" small  v-else class="px-0 mr-1 mb-1 body-2 grey--text text--darken-2" outlined  color="grey darken-1" @click="showEdit=true; editMeme=meme" style="cursor:pointer">
                  <v-avatar v-if="getUrlAvatar(meme)">
                    <img  v-if="SafeMode" style="opacity:0.6" src="../assets/img/anon.jpg"/>
                    <img  v-if="!SafeMode" :src="getUrlAvatar(meme)" />
                  </v-avatar>
                  <span class="subtitle grey--text text--lighten-3 font-weight-light mx-2">{{meme['instances'][0]['displayName']}}</span>
                  <template v-for="(sets,i) of meme['theHundred']">
                    <v-chip v-if="sets?Object.keys(sets).includes('digits')?jj=sets['digits']:false:false" :color="colors100[jj]" outlined small class="pa-1 ml-1">
                      <span class="overline white--text font-weight-bold">{{jj | dd}}</span>
                    </v-chip >
                  </template>
                </v-chip >
              </template >
            </v-row>
          </v-row>
        </v-container>
      </v-sheet>
      <showMore  v-if="showMore&&The100NNN[actual_d]&&AllDims[actual_d]" :actual_i="actual_i" :actual_d="actual_d" :showMore="showMore" @finishedShowMore="showMore=false;"/>
    </v-main>
    <editmeme :showEdit="showEdit" :newdim="newdim" :meme="editMeme" :newnumber="newnumber"  @finishedEditMeme="showEdit=false;" />
       
  </div>
</template>

<script>
  import { ObjectID } from 'bson';
  import major from '../components/major.vue'
  import showMore from '../components/showMore.vue'
  import editmeme from '../components/editmeme'
  import sets from '../components/sets'
  import editsets from '../components/editsets'
  import mememedia from '../components/mememedia'
  import { mapState,mapMutations,mapActions } from 'vuex'
    
  export default {
      
    data() {return {
      all:[...Array(100).keys()].map(x => this.$options.filters.dd(x)),
      the100 : {}, //Object.fromEntries(Object.entries([...Array(110).keys()]) .map(([ key, val ]) => [key<100?this.$options.filters.dd(key):(key-100), null ])),
      arr_of_nums: [],
      transposed:false,
      subOfTens:{},
      showEdit:false,
      showPresentation:false,
      editMeme:null,
      newnumber:null,
      newdim:null,
      showMore:false,
      actual:null,
      actual_i:0,
      actual_t:'00-09',
      actual_d:0,
      clicktimeoutId:  null,
      the100nav:null,
      showAll100:true,
      showAllMemes:false,
      allMemesSort:false, //true az false daate
      showSets:false,
      totals:{},
      namesToShow:null,
    }},

    // COMPUTED ---------------------------------------------------------------------------------------------------------
    computed: {
      ...mapState('auth',['currentUser']),
      ...mapState('globals',['safeMode','colors100']),
      ...mapState('memes',['allMemes','the100Toggle','the100NNN','notThe100NNN','allMemes']),
      ...mapState('settings',['usrSet','allDims','currentSet','uniqueNNN','setSequence','showNamesOnAll','filterMajorLoc','filterMajorLocs']),
      //GUI dynamics
       FilterMajorLocs(){return this.filterMajorLocs},
       FilterMajorLoc(){return this.filterMajorLoc},
      SafeMode(){return this.safeMode},
      AllDims(){return this.allDims},
      ShowNamesOnAll(){return this.showNamesOnAll},
      The100NNN(){return this.the100NNN},
      NotThe100NNN(){return this.notThe100NNN}, //in current set niet!
      CurrentSet(){return this.currentSet},
      CurrentSetName(){try{return this.usrSet['sets'][this.currentSet]['name']}catch{return null} },
      CropNow(){return this.$vuetify.breakpoint.width<750},
      SafeMode(){return this.safeMode},
      //
      the100_sub () {let out = Object.fromEntries( Object.entries(this.The100NNN[this.actual_d]).filter(([key]) => this.arr_of_nums.includes(key))); return out },
      AllMemesSubSet(){
        if (this.allMemesSort){return this.NotThe100NNN[this.actual_d].sort((a,b)=> (a['instances'][0]['displayName'] > b['instances'][0]['displayName'])?1:-1 )}
        else {return this.NotThe100NNN[this.actual_d].sort((a,b)=> (new Date(a.lastModifiedOn) < new Date(b.lastModifiedOn))?1:-1 )}
        },//this.allMemes[this.actual_d].sort((a, b) => new Date(a.lastModifiedOn) > new Date(b.lastModifiedOn))},
      doubles(){
        let allitems = {}
         for (let dim of this.setSequence){
          allitems[dim]= {'group':{},'parent':{}}
          for (let p of Object.keys(this.The100NNN[dim])){
            if (this.The100NNN[dim][p]){
                for (let a of Object.keys(allitems[dim])){
                  if (this.The100NNN[dim][p][a]!=null){
                    try{allitems[dim][a][ this.The100NNN[dim][p][a] ].push(p)
                    }catch{allitems[dim][a][ this.The100NNN[dim][p][a] ] = [p]}
                  }}}
                }
          //now only doubles
          for (let a of Object.keys(allitems[dim])){  //not for categories
            for (let i of Object.keys(allitems[dim][a])){
              if (allitems[dim][a][i].length<2){delete allitems[dim][a][i]}
            }
          }
        }
        return allitems
      },

     
    },

// METHODS ---------------------------------------------------------------------------------------------------------
    methods: {
       ...mapMutations('globals',['toggleSafeMode']),
      fileBase(meme,spot){let out = 'geen'; try {out= meme['media'][spot]['file_base'] }catch{}; return out; },
        
      the100Instance(meme){
        try{return meme['instances'][ meme['theHundred'][this.currentSet]['instance']]}
        catch{
           return null
        }
        },
      leftOrRight(ind){let out= 'left'; if (this.CropNow){if (ind>2){out='right'}     } else { if (ind>4){out='right'}} return out},
      getUrlAvatar(meme){ let out = null;try{out = meme['media'][0]['url']}catch{}; return out },
      getUrlAvatarUsr(){ let out = null;try{out = this.usrSet['avatar']}catch{}; return out },
      ShowMore(dim,i){this.showMore=!this.showMore;  this.actual_i=i; this.actual_d=dim},
      klok(){console.log(this.subOfTens)},
      count(){
        this.totals = {}
        for (let dim of this.setSequence){
          this.totals[dim] = {'category' :{}, 'unfilled':100}
          try{
            for (let i of Object.keys(this.The100NNN[dim])){
              if (this.The100NNN[dim][i]){
                if(this.The100NNN[dim][i].category){if (this.totals[dim]['category'][this.The100NNN[dim][i].category]>0){this.totals[dim]['category'][this.The100NNN[dim][i].category]+=1}else{this.totals[dim]['category'][this.The100NNN[dim][i].category]=1}}
                this.totals[dim]['unfilled']-=1
              }
          } }catch{}
        }
        //console.log(this.totals)
      }
    },
    // WATCH ---------------------------------------------------------------------------------------------------------
    watch: {
      the100Toggle: function(){
        if (!this.AllDims[this.actual_d]) {
          this.actual_d=this.setSequence[0]
          }//Object.keys(this.AllDims)[0]}
        this.$forceUpdate();
      },
      the100NNN: function(){this.count();},
    },

    // CREATED ---------------------------------------------------------------------------------------------------------
    created: function () {
      //this.subOfTens['0-9']= [...Array(10).keys()].map(x=>String(x))
      for (let j of [...Array(10).keys()]){
        var k = [...Array(10).keys()].map(x => this.$options.filters.dd(x + j*10));
        this.subOfTens[this.$options.filters.dd(j*10)+'-'+this.$options.filters.dd(((j+1)*10-1))]= k
      }
      //de default setten
      this.arr_of_nums = Array(100) //this.subOfTens['00-09']
      this.the100nav = 0
       //console.log(this.subOfTens)
    },
     mounted:function(){
      this.actual_d=this.setSequence[0]
      this.$forceUpdate();
  },
      
    //COMPONENTS ---------------------------------------------------------------------------------------------------------
    components:{
      major,
      editmeme,
      sets,
      showMore,
      editsets,
      mememedia
    }
  
  }

</script>


<style scoped>
  .gender.male{
    border-left: 4px solid #26c6da;
    border-right: 4px solid #26c6da;

  }
  .gender.female{
    border-left: 4px solid pink;
    border-right: 4px solid pink
  }
  .gender.unknown{
    border-left: 4px solid LightGrey ;
    border-right: 4px solid LightGrey ;

  }
  .rotated  {
    /* Abs positioning makes it not take up vert space */
    /* Border is the new background */
    /* Rotate from top left corner (not default) */
    //transform-origin: 100% 100%;
    transform: rotate(-90deg);
  }
</style>
