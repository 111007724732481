<template  style="position: relative">
  <v-sheet  dense style="z-index:1"  :class="`${bgcolor?bgcolor:'black'} av_pos`">
    <v-overlay absolute :opacity="0.2" :value="isLocked">
      <v-progress-circular :size="50" :color="bgcolor"  indeterminate ><v-icon :class="`${bgcolor}--text text--lighten-3`">mdi-blender-outline</v-icon></v-progress-circular>
    </v-overlay>
    <v-overlay absolute :opacity="0.8" :value="waitForPlaying">
      <v-progress-circular :size="50" :color="bgcolor"  indeterminate ><v-icon :class="`${bgcolor}--text text--lighten-3`">mdi-motion-play-outline</v-icon></v-progress-circular>
    </v-overlay>
    <img  v-on="false" :src="Back_src" :style="`opacity:0.5; z-index:2`"  class="av_pos"/>
    <img  v-on="false" ref="image_ref" v-show="!SafeMode&&Img_src&&!Img_err&&!Vid_src" :style="`opacity:${Opacity};z-index:3`" :src="Img_src"  class="av_pos pa-0 ma-0"/>
    <video ref="video_ref" v-show="!SafeMode&&Vid_src" class="av_pos pa-0 ma-0" :style="`opacity:${Opacity};z-index:4;cursor:pointer`" :src="Vid_src"
    preload="auto" @click="togglePlay()" webkit-playsinline="true" playsinline="true" :poster="Img_src" looped/>  
  </v-sheet>
 
</template>
<script>
import { mapState, mapMutations } from 'vuex'
let Alts = {'safe' :    require('../assets/img/anon.jpg'),
        'meme_empty' :  require('../assets/img/emptymeme.jpg'),
        'media_empty':  require('../assets/img/unknown.jpg'),
        'oops_img':     require('../assets/img/noone.jpg') }
    
export default {
  data() {
    return {
      url:null,
      waitForPlaying:false,
      isVideo:false,
      play:false,
      Meme:null,
      MediaId:null,
      fileBase:null,
      Meme_url:null,
      Media_type:null,
      Media_present:null, // er hoeft geen media elemen op media_id spot plek te zitten. 
      Vid_src:null,
      Back_src:null,
      Img_src:null, Img_err:false,//always an image!!
      Opacity:null,
      ThisVideo:null,
      hover:false,
    }
  },
  //z-index afh van input z!!!!! in show gebruik ik die
  computed: {
    ...mapState('auth',['currentUser']),
    ...mapState('memes',['the100Toggle']),
    ...mapState('settings',['dbx_token']),
    ...mapState('globals',['safeMode','mediaPath','colors100']),
    SafeMode(){return this.safeMode},
    //isLocked(){let out = false; try{Object.keys(this.Meme['media'][this.MediaId]).includes('ffmpeg')?out=!this.Meme['media'][this.MediaId]['ffmpeg']:null}catch{};return out;},
    isLocked(){return false},
       
  },
  methods:{
    ...mapMutations('globals',['toggleSafeMode']),
    reinit(){
      this.Meme=null; this.MediaId=null;this.Img_src=null;this.Meme_url=null;this.Media_type=null;this.Vid_src =null;this.Opacity=null;
      this.Meme = this.meme
      this.MediaId =this.media_id
      this.fileBase = this.file_base
      try{this.Media_type = this.Meme['media'][this.MediaId]['type']}catch{this.Media_type=null}
      this.isVideo= ['mp4','webm'].includes(this.Media_type)
      this.determineImgSrc()
      this.determineBackSrc()
      this.setOpacity();
      this.Zindex = this.z_index>-1?this.z_index+100:100
      /*
      if (this.autoplay && this.canplay && !this.waitForPlaying && !this.play){
      let that=this
      setTimeout(()=>{
        console.log(this.MediaId);
        that.togglePlay()},500)
        }*/
    },
    determineBackSrc(){
      if (this.Meme){
        if (this.Img_err){
          this.Back_src = Alts['oops_img']
        }else{
          if (this.Img_src){
            this.Back_src = Alts['safe']
           }else{
              this.Back_src = Alts['media_empty']
           }
        }
      }else {
        this.Back_src = Alts['meme_empty']
      } 
    },
    determineImgSrc(){
      this.Img_src=null
     if (this.isVideo){
        try{ 
          this.Img_src= this.Meme['media'][this.MediaId]['url_poster']
          this.Vid_src= this.Meme['media'][this.MediaId]['url']
          this.ThisVideo.load()
       }catch{}}
      else{
        try{ this.Img_src = this.Meme['media'][this.MediaId]['url']}catch{}
      }
     
    },
    setOpacity(){
      let out=1
      this.Opacity=out
    },
    togglePlay(){
      //console.log('plee')
        this.play=!this.play
        if (!this.canplay || this.SafeMode){this.play=false}
        if(this.play && this.ThisVideo.paused){
          try{this.ThisVideo.play(); this.waitForPlaying=true}catch{}
        }
        if(!this.play && !this.ThisVideo.paused && !this.waitForPlaying){
           try{this.ThisVideo.pause(); this.waitForPlaying=false}catch{}
        }
    },  
  },
  watch: { 
    meme: function(){this.reinit()}, 
    media_id: function(){this.reinit()}, 
    //file_base: function(){this.reinit()}, 
    canplay: function(){this.reinit()}, 
    the100Toggle:function(){this.reinit()},
    //autoplay:function(){console.log(this.autoplay)},
    SafeMode:function(){if (!this.ThisVideo.paused&&this.SafeMode){this.ThisVideo.pause();this.play=false}  }
  },
  props: ['meme','media_id','file_base','canplay','autoplay','bgcolor'],
  created: function (){this.reinit()},
  mounted: function(){
    let that=this;
    this.ThisVideo = this.$refs["video_ref"]
    this.ThisVideo.loop=true
    let ThisImg = this.$refs["image_ref"]
    //this.ThisVideo.onloadedmetadata = function() { }
    ThisImg.onerror= function() {that.Img_err=true;that.Back_src = Alts['oops_img']} 
    this.ThisVideo.addEventListener('playing', () => {that.waitForPlaying=false })
   }
}
</script>
<style scoped>
.av_pos {
  position: absolute;
  padding: 0px 0px;
  margin: 0px 0px;
  top: 50%;
  left: 50%;
  width:100%;
  height:100%;
  transform: translate(-50%, -50%);
  object-fit:cover;
 }


.filmstrip {
  --background: rgba(0, 0, 0, 1);
  --size-block-x: 10%; --size-block-y: 3%;  --size-strip-y:0.75%;
  background-image:
    linear-gradient(to right, var(--background) 0% 100%),
    linear-gradient(to right, var(--background) 50%,transparent 50% 100%),
    linear-gradient(to right, var(--background) 0% 100%),
    linear-gradient(to right, var(--background) 0% 100%),
    linear-gradient(to right, var(--background) 50%,transparent 50% 100%);
   
  background-size: 100% calc(var(--size-strip-y)),
                   calc(var(--size-block-x)) calc(var(--size-block-y)),
                   100% calc(var(--size-strip-y)),
                   100% calc(var(--size-strip-y)),
                   calc(var(--size-block-x)) calc(var(--size-block-y));
                   
  ;
                    
  background-position:  0 0,
                        calc(var(--size-block-x)*-0.25) calc(var(--size-strip-y)),
                        0 calc( var(--size-block-y) + var(--size-strip-y)),
                        0 calc(100% - var(--size-strip-y)),
                        calc(var(--size-block-x)*-0.25) calc(100% - var(--size-block-y));
                       
                   
  background-repeat: repeat-x; 
  padding: 0% 0%;
 margin: 0% 0%;
  box-sizing: border-box;
}

 </style>
