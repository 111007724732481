import Vue from "vue"

Vue.filter('capitalized', (input) =>{
  if (input){return input.replace(/\w\S*/g,
    tStr => tStr.charAt(0).toUpperCase()+tStr.substr(1).toLowerCase())
  }else{return input}
  })

  Vue.filter('capitalizefirst', (input) =>{
    return input[0].toUpperCase() + input.slice(1);
  })


Vue.filter('dd',(input) => {
return ("0" + Number(input)).slice(-2)
})

Vue.filter('pao',(input) => {
  let pao = 'object'
  if (input%3==0){pao = 'person'}
  else if ((input+2) % 3==0){pao = 'action'}
  return pao
})

Vue.filter('majorLocation', (codeTemplate,major) => {
  try{
    let CodeArr = codeTemplate.split("`").map(Number)
    let IndArr = []; let ap = 0
    CodeArr.forEach((el, index) => {IndArr.push(ap); if(el==-2){ap++}})
    let majorLoc = major.map((el) => {return IndArr[el]})
    //console.log(codeTemplate + '\t' + major + '\t' +CodeArr)//+'\t'+ IndArr+ '\t' +majorLoc)
    let type = 'multiple'
    if (majorLoc[0]==majorLoc[1]){
      type="middle"
      if (majorLoc[0]==ap){type="last"} //deze eerst igv 1 
      if (majorLoc[0]==0){type="first"}    
      
    }
  return {'parts':majorLoc,'type':type}}
  catch{return {'parts':null,'type':null}}
  })

Vue.filter('truncate', function (text, stop, clamp) {
  if (!text) {return null} else{
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')}
})

Vue.filter('onlyminutes', function (time) {
    return Math.floor( (time-(3600*Math.floor(time/3600) )) /60 );
})
Vue.filter('onlyseconds', function (time) {
    return Math.floor(time % 60);
})
Vue.filter('onlyhours', function (time) {
    return Math.floor(time/3600);
})



Vue.filter('changePermutations',  function (major){

          let majorcombos={}
          // >>> dit deel niet elegant maar converteren van major in object van objecten net als het in firebase zou zijn...
          // werkt handig met de functie hieronder

          let mj = {};
          let msplit = major.split('-2')
          let offset=0
          for (let n in msplit){
            let sub = msplit[n].split('`').map(x=>x?Number(x):-1)
            let subsub={}
            for (let c in sub){ if (sub[c]>-1) {subsub[Number(c)+offset] = sub[c]} }
            offset+=sub.length-1 //want de space telt mee
            mj[n] = subsub
            }
            //console.log(mj)
            function doit(major, majorcombos){
              var allstarters ={}
              for (let j in Object.keys(major)){
                  var m = major[j]
                    var indices = Object.keys(m)
                    if (indices.length>0){
                      var i = 1
                       for (let start of indices){
                         if (i==1){allstarters[start]=m[start]}
                         for (let end of indices.slice(i)){
                           var getal = 10*m[start]+m[end]
                           if (!majorcombos[getal]){
                             majorcombos[getal]={0:Number(start),1:Number(end)}}
                           }
                         i+=1;
                       }
                     }
                }
                return [allstarters]
              }
             var allstarters = doit(mj,majorcombos)
             doit(allstarters, majorcombos)
             return majorcombos

})
