export default {
    apiKey: "AIzaSyCTLo85XrvXHzkoaTc9_8evWZzqA-972P0",
    authDomain: "andtheelephant-6bdb5.firebaseapp.com",
    databaseURL: "https://andtheelephant-6bdb5.firebaseio.com",
    projectId: "andtheelephant-6bdb5",
    storageBucket: "andtheelephant-6bdb5.appspot.com",
    messagingSenderId: "377308655984",
    appId: "1:377308655984:web:fbb437b6cec452cf8b9fb5"
  }  



