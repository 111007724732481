<template>
  <v-toolbar-title class="grey--text">
        <router-link to="/" class="grey--text" style="text-decoration: none">
              <span class="font-weight-light">n<sup>th</sup></span>
            <span class="font-weight-bold">elephant</span>
          </router-link>
                <span class="ml-5 title grey--text text--darken-1" >|</span>
                <span class="ml-5 title font-weight-light white--text">{{title}}</span>
  </v-toolbar-title>
</span>
</template>
<script>
  export default {
      props: ['title']
  }
</script>
