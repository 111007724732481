<template>
  <span>
    <v-btn text small @click.stop="dialog = true" >
      <v-icon color="primary">mdi-content-save</v-icon></v-btn>

    <v-row justify="center" v-if="dialog">
      <v-dialog v-model="dialog" scrollable  max-width="400px" @click:outside="mode=true; dialog=false">
          <v-card>
          <v-card-title>
            <span class="">Load & Save sets</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="mode">
            <v-text-field class="mt-5" dense counter maxlength="50" label="set name (optional)" v-model="setname" prepend-icon="mdi-content-save"/>
            <v-btn color="primary" text @click="saveSet(); mode=false">Save set</v-btn>
            <v-btn color="primary" text @click="mode = false">Load set</v-btn>
          </v-card-text>

          <v-card-text v-else>
            <v-list dense dark flat class="mt-1 pa-0" v-for="set in allSets">
                <v-list-item class="py-0" three-line :key="set.id">
                  <v-list-item-icon>
                    <v-btn text small @click="delete_dialog[set.id]=!delete_dialog[set.id]"><v-icon color="error">mdi-delete-forever</v-icon></v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>{{ new Date(set.createdOn.seconds*1000).toLocaleString()  }}</v-list-item-title>
                      <v-list-item-subtitle>{{set.setname}}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="delete_dialog[set.id]" class="error--text">
                            <b>delete set?</b>
                            <v-btn text small @click="delete_dialog[set.id]=false">NO</v-btn>
                            <v-btn text x-small @click="deleteSet(set.id); delete_dialog[set.id]=false">yes</v-btn>
                      </v-list-item-subtitle>
                      </v-list-item-content>
                 <v-list-item-icon>   <v-btn text small @click=""><v-icon color="primary">mdi-cloud-download</v-icon></v-btn></v-list-item-icon>
               </v-list-item>
             </v-list>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-row>
  </span>
</template>

<script>
  import { mapState,mapMutations,mapActions } from 'vuex'
  const fb = require('../firebaseConfig.js')

  export default {
    data () {
      return {
        dialog:false,
        mode:true,
        setname:null,
        allSets:[],
        delete_dialog:{}
        }
    },
    computed: {
      ...mapState('auth',['currentUser']),
    },
    methods: {
      saveSet(){
        let ids = []; let instances = []; let majorTemplates = []
        for (let p of Object.keys(this.the100).sort()){
            ids.push(this.the100[p]?this.the100[p].id:null)
            instances.push(this.the100[p]?this.the100[p].instance:null)
            majorTemplates.push(this.the100[p]?this.the100[p].majorTemplate:null)
          }
          let newset= {'ids':ids,'instances':instances,'majorTemplates':majorTemplates}
          if (this.setname!=undefined){newset['setname']=this.setname}
          newset['createdOn'] =  new Date()
          newset['userId'] = this.currentUser.uid
          newset['score'] = null
          //now store to firebase:
          fb.setsCollection.add(newset)
          .then((docRef) => {})
          .catch(error => {console.error("Error writing document: ", error);})
        },
        loadsets(){
          let that =this
          fb.setsCollection.where('userId','==',this.currentUser.uid)//.where('number','>',-1).orderBy("number", "asc").limit(20)//.where('category','==','actor')
              .get()
              .then(function(querySnapshot) {
                  querySnapshot.forEach(function(doc) {
                      var p = doc.data()
                      p['id']=doc.id
                      that.allSets.push(p)
                      that.$set(that.delete_dialog, doc.id, false)
                    });
              })
              .catch(function(error) {console.log("Error getting documents: ", error);});
        },
        deleteSet(setid){
          let that =this
          console.log(setid)
          fb.setsCollection.doc(setid).delete().then(function() {
            let i= that.allSets.findIndex(x=>x.id==setid)
            that.allSets.splice(i,1);
            delete that.delete_dialog[setid]
            })
            .catch(function(error) {console.error("Error removing document: ", error);  });

        }
    },
    watch: {
      mode: function(){if (!this.mode){
        this.setname = null
        this.allSets= []
        this.delete_dialog = {}
          this.loadsets()

          }
        }
    },
    props: ['the100'],
    //mixins: [mixin],
    components:{},
    created: function () {

    }
  }
</script>
