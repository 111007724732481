<template>
      <v-row justify="center" v-if="decodeShow" class="" > <!-- easy clear of inputs-->
      <v-dialog :value="decodeShow" persistent >
      <v-card >
      <v-card-title class="mt-n5 mb-n5">
        <v-col class="text-left text-h4 " style="opacity:0.1" >
          <span class="font-weight-light">{{totalTimeElapsed | onlyminutes | dd}}</span>
          <span class="mx-1">:</span>
          <span class="font-weight-light">{{totalTimeElapsed | onlyseconds | dd}}</span>
        </v-col>

        <v-col class="text-center" >
          <v-icon v-if="" @click="CurrentDigits-=(CurrentDigits>0)?1:0" size="30px" color="grey">mdi-arrow-left-circle</v-icon>
          <v-avatar  color="pink lighten-2" size="35" class="pa-0 mr-1 mb-1" style="cursor:pointer"
          @click="enterdigits('?')">
            <span class=" font-weight-bold white--text">?</span>  </v-avatar>
          <v-icon v-if="" @click="CurrentDigits+=(CurrentDigits<decode_set.length-1)?1:0" size="30px"  color="grey">mdi-arrow-right-circle</v-icon>
        </v-col>

        <v-col class="text-right" >
          <v-icon v-if="" @click="CancelRun=true" size="40px" color="pink">mdi-stop-circle</v-icon>
          <v-icon v-if="" @click="submitDecodeRun(decode_set);closeDialog()" size="40px" :disabled="!Ready" color="pink">mdi-send-circle</v-icon>
        </v-col>

      </v-card-title>
    <v-card-text class="text-center mb-n4 text-h6">
          <v-container class="mt-n5" dense >
              <template v-for="j in 10" >
                <v-row justify="center">  <template v-for="i in 10" >
                    <v-avatar  :color="`pink ${(i%2==0)?'':'lighten-2'}`" size="35" class="pa-0 mr-1 mb-1" style="cursor:pointer"
                    @click="enterdigits((i-1)*10+(j-1))">
                      <span class=" font-weight-bold white--text">{{(i-1)*10+(j-1) | dd}}</span>
                    </v-avatar>
                  </template>
                </v-row>  </template>

          </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="text-left mt-2">
        <template v-for="i in decode_set.length" style="cursor:pointer">
            <v-avatar @click="CurrentDigits=i-1" :color="`${(decode_set[ i-1 ]==='?')?'grey':'black'} ${(CurrentDigits==i-1)?'':'lighten-2'}`" :size="` ${CurrentDigits==i-1?30:20}`" class="mx" style="cursor:pointer" ><span class="title-1  font-weight-bold white--text noselect" >{{decode_set[i-1]}} </span></v-avatar>
            <v-avatar v-if="(i%3==0)" color="white" size="6" class="mx" ><span class="font-weight-bold white--text noselect" >-</span></v-avatar>
        </template>
      </v-card-text>
        <v-divider></v-divider>
    </v-card>
    </v-dialog>

    <v-dialog v-model="CancelRun" max-width="400">
                 <v-card>
                   <v-card-title style="noselect" class="body-2">This will cancel de decoding run!</v-card-title>
                   <v-card-actions>
                     <v-btn class="success" dark large text outlined @click="CancelRun=false;">Keep Run Going</v-btn>
                     <v-spacer></v-spacer>
                     <v-btn class="error" large dark outlined @click="CancelRun=false; closeDialog()">Cancel the Run</v-btn>
                   </v-card-actions>
                 </v-card>
      </v-dialog>

  </v-row>
</template>

<script>
import { mapState,mapMutations,mapActions } from 'vuex'
  export default {
    data () {
      return {
        CurrentDigits:0,
        EndTime:null,
        Ready:false,
        CancelRun:false,
        decode_set:[]
      }
    },
    computed: {
        ...mapState('runs',['paoRun']),
        ...mapState('globals',['nu']),
        totalTimeElapsed(){
        let out = (this.nu-this.paoRun['decode']['start'])/1000
        if (out>(60*60)-20){this.closeDialog(); return null} //niet over ongeveer een uur
        return out}
      },

    methods: {
        ...mapActions('runs',['submitDecodeRun']),
        ...mapMutations('runs',['resetRun']),
      closeDialog(){this.resetRun(); this.$emit('finishedDecodeShow')},
      enterdigits(i){
        if (this.CurrentDigits<this.decode_set.length){
          if (i!="?"){this.decode_set[this.CurrentDigits] = this.$options.filters.dd(i);}
          else{this.decode_set[this.CurrentDigits] = i}
          this.CurrentDigits+=1
          if (this.CurrentDigits==this.decode_set.length){this.Ready=true}
        }
      },
      init(){
        this.Ready=false
        this.CurrentDigits = 0
        this.decode_set  =  new Array(3*this.paoRun['settings']['NumberOfSets']).fill("?");
      },
    },
    watch: {
      decodeShow: function(){  if (this.decodeShow){this.init()}  },
    },
    props: ['decodeShow'],
    components:{},
    mounted: function () {}
  }
</script>
