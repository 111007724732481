<template>
  <v-dialog :value="showVideoCropper" persistent class="mx-auto" max-width="600px" @click:outside="closeDialog()">
    <v-overlay absolute :opacity="0.2" :value=loading>{{loadmessage}} <v-progress-circular :size="50" color="primary"  indeterminate ></v-progress-circular></v-overlay>
      <v-card color="#272727" v-if="showVideoCropper">
          <v-row dense  class="mt-2 ">
            <v-col cols="1"  class='text-right '>
              <span  @click="toggleSafeMode()" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-eye{{SafeMode?'-off':''}}</v-icon></span>
              <span  @click="show_grid=!show_grid; updatecanvas()" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-grid{{show_grid?'':'-off'}}</v-icon></span>
              <span  @click="shift_start=false;shift_cum={'x':0,'y':0};resetVideoParameters({'off_x':0,'off_y':0,'zoom':edit_vid_zoom['zoom']})" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-image-filter-center-focus</v-icon></span>
              <span  @click="shift_start=false;shift_cum={'x':0,'y':0};resetVideoParameters({'off_x':0,'off_y':0,'zoom':1,'flip_x':1,'flip_y':1,'rot':0})" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-restore</v-icon></span>
              <v-divider/>
              <span @click="resetVideoParameters({'flip_x':-edit_vid_zoom['flip_x']})"  style="cursor:pointer"><v-icon medium :class="`${edit_vid_zoom['flip_x']>0?curColor:'black'}--text text--lighten-3`">mdi-flip-horizontal</v-icon></span>
              <span @click="resetVideoParameters({'flip_y':-edit_vid_zoom['flip_y']})"  style="cursor:pointer"><v-icon medium :class="`${edit_vid_zoom['flip_y']>0?curColor:'black'}--text text--lighten-3`">mdi-flip-vertical</v-icon></span>
              <v-divider/>
              <span><v-icon style="cursor:pointer" @click="prevent_white_space=!prevent_white_space; resetVideoParameters({'off_x':0,'off_y':0,'zoom':1,'flip_x':1,'flip_y':1,'rot':0})" medium :class="`${prevent_white_space?curColor:'black'}--text text--lighten-3`">mdi-overscan</v-icon></span>
              <span><v-icon medium :class="`${pot_white?curColor:'black'}--text text--lighten-3`">mdi-alert</v-icon></span>
              <v-divider/>
              <v-slider dark v-model="edit_vid_zoom['rot']" min=-180 max=180 vertical  style="cursor:pointer"  @input="resetVideoParameters({'rot':edit_vid_zoom['rot']})" 
                :color="`${curColor} lighten-3`" :track-color="`${curColor} lighten-3`" :thumb-color="`${curColor} lighten-3`"
                class="mt-3 pl-4"/>
              <span @click="edit_vid_zoom['rot']+=1;resetVideoParameters({'rot':edit_vid_zoom['rot']})"  style="cursor:pointer"><v-icon small :class="curColor+'--text text--lighten-3'">mdi-rotate-left</v-icon></span>
              <span @click="edit_vid_zoom['rot']-=1;resetVideoParameters({'rot':edit_vid_zoom['rot']})"  style="cursor:pointer"><v-icon small :class="curColor+'--text text--lighten-3'">mdi-rotate-right</v-icon></span>
              <v-avatar v-if="edit_vid_zoom['rot']!=0" rounded style="cursor:pointer" :color="curColor" size="25px" class="lighten-3 pa-0 ml-1 mt-2" @click="resetVideoParameters({'rot':0})">
                <b class="caption font-weight-bold noselect pa-0 ma-0">{{edit_vid_zoom['rot']}}&deg;</b>
              </v-avatar>
            </v-col>
            <v-col cols="10" class="text-center">
              <video  v-show="false" ref="video_source" autoplay preload="metadata" webkit-playsinline="true" playsinline="true"/>
              <canvas v-show="true" @dblclick="isPlaying?video_source.pause():video_source.play()"  :style="`opacity:${SafeMode?0.05:1}`"  ref="canvas_edit" :draggable="false" @wheel.prevent="wheel($event)" @mouseout="endShift($event)" @mouseup="endShift($event)" @mousedown="startShift($event)" @mousemove="shift($event)" :height="size_edit_h+'px'" :width="size_edit_w+'px'" style="overflow: hidden; border: 1px solid white" ></canvas>
              <canvas v-show="false" ref="canvas_out" :height="size_out_h+'px'" :width="size_out_w+'px'"></canvas>
              </v-col>
            <v-col cols="1" class='text-left'>
             
              <span  @click="skipMode=true; repeatSingleMode=false; startRecordingNow()" :style="`${canRec?'cursor:pointer;':'cursor:auto; pointer-events: none'}`">
                <v-icon medium :class="`${canRec?curColor+'--text text--lighten-3':'grey--text text--darken-3'}`" >mdi-record-circle</v-icon>
              </span>
              <span  @click="createSnapShot()"  :style="`${!snapShotBlob?'cursor:pointer;':'cursor:auto; pointer-events: none'}`">
                <v-icon medium  :class="`${!snapShotBlob?curColor+'--text text--lighten-3':'grey--text text--darken-3'}`">mdi-camera</v-icon>
              </span>
              <span v-if="snapshotSaveSettings" @click="video_source.pause(); updateCursor(snapshotSaveSettings['time'], false); resetVideoParameters(snapshotSaveSettings)"  style="cursor:pointer">
                <v-icon medium  :class="`${curColor+'--text text--lighten-3'}`">mdi-camera-marker-outline</v-icon>
              </span>
              <v-divider dark class="mt-5" />
              <span v-if="video_source" class="mt-5">
                <b v-if="dBaS['ready']" class="overline white--text text--lighten-3">{{Math.round(dBaS['mean_top']*10)/10}}</b><br>
               <span v-if="dBaS['ready']" style="cursor:pointer" class="mt-5 d-flex justify-center" @click="renormalizedBa()"><v-icon  medium  :class="`${curColor+'--text text--lighten-3'}`">mdi-waveform</v-icon></span>
      
              </span>

<!-- MULTIRANGEShit  -->
          <span v-if="video_source" class="mt-5">
          <v-divider dark class="mt-5" />
          <!--<span style="cursor:pointer" class="d-flex justify-center white--text"> {{(MULTIRANGE[current_cursor]/100).toFixed(2)}}</span>
          <v-divider dark />
          <span :style="`${(video_source.currentTime!=MULTIRANGE[current_cursor]/multiplier)?'cursor:pointer;':'cursor:auto; pointer-events: none'}`" class="d-flex justify-center" @click.stop="video_source.pause();video_source.currentTime=MULTIRANGE[current_cursor]/multiplier;gotTheTimeTicking();resetVideoParameters()"><v-icon medium :class="`${(video_source.currentTime==MULTIRANGE[current_cursor]/multiplier)?curColor+'--text text--lighten-3':'white--text'}`">mdi-map-marker-radius</v-icon></span> -->
          <span :style="`${currSegInd>-1?'cursor:pointer;':'cursor:auto; pointer-events: none'}`" class="mt-5 d-flex justify-center" @click="audioToggle()"><v-icon  medium  :class="`${currSegInd>-1?curColor+'--text text--lighten-3':'grey--text text--darken-3'}`" >mdi-volume-{{currSegInd>-1?audioSegments2D[currSegInd][0]?'high':'low':'high'}}</v-icon></span>
          <span :style="`${wiggleRoom>=min_int?'cursor:pointer;':'cursor:auto; pointer-events: none'}`" class="d-flex justify-center"  @click="addRange()"><v-icon  medium :class="`${curColor}--text text--${wiggleRoom>=min_int?'lighten-3':'lighten-5'}`">mdi-map-marker-plus-outline</v-icon></span>
          <span :style="`${(currSegInd>-1 && MULTIRANGE.length>2)?'cursor:pointer;':'cursor:auto; pointer-events: none'}`" class="d-flex justify-center" @click="removeRange()"><v-icon medium  :class="`${(currSegInd>-1 && MULTIRANGE.length>2)?curColor+'--text text--lighten-3':'grey--text text--darken-3'}`">mdi-map-marker-minus-outline</v-icon></span>
         
          <span  class="mt-5" v-if="Math.floor(video_source.currentTime*100)/100==Math.floor(100*MULTIRANGE[current_cursor]/multiplier)/100 && video_source.paused">
         
          <v-divider dark class="mt-5"   />
          <!--<span v-if="(video_source.currentTime==MULTIRANGE[current_cursor]/multiplier)" style="cursor:auto" class="mt-5 d-flex justify-center"><v-icon  medium class=''  :class="`${true?curColor+'--text '+'text--lighten-3':'white--text'}`">mdi-billboard</v-icon></span>-->
          <span style="cursor:pointer" class="d-flex justify-center"  @click.stop="setZoom()" ><v-icon  medium class=''  :class="curColor+'--text '+'text--lighten-3 mt-5'">mdi-lock{{zoomSegments2D[Math.floor(current_cursor/2)][current_cursor%2?1:0]!=null?JSON.stringify(zoomSegments2D[Math.floor(current_cursor/2)][current_cursor%2?1:0])==JSON.stringify(edit_vid_zoom)?'':'-open':'-open-outline'}}</v-icon></span>
          <span v-if="zoomSegments2D[Math.floor(current_cursor/2)][current_cursor%2?1:0]!=null" style="cursor:pointer" class="d-flex justify-center mt-2"  @click.stop="resetZoom()" ><v-icon  small class='' :class="curColor+'--text '+'text--lighten-3 mt-2'">mdi-lock-remove</v-icon></span>
          <span v-if="JSON.stringify(zoomSegments2D[Math.floor(current_cursor/2)][current_cursor%2?1:0])==JSON.stringify(edit_vid_zoom) && JSON.stringify(edit_vid_zoom)!=JSON.stringify(copiedSettings)" style="cursor:pointer" class="d-flex justify-center mt-2"><v-icon  small  @click.stop="copiedSettings=JSON.parse(JSON.stringify(edit_vid_zoom))" :class="curColor+'--text '+'text--lighten-3'">mdi-content-copy</v-icon></span>
          <span v-if="zoomSegments2D[Math.floor(current_cursor/2)][current_cursor%2?1:0]==null && copiedSettings!=null" style="cursor:pointer" class="d-flex justify-center mt-2"><v-icon  small  @click.stop="zoomSegments2D[Math.floor(current_cursor/2)][current_cursor%2?1:0]=JSON.parse(JSON.stringify(copiedSettings)); copiedSettings=null; gotTheTimeTicking();" :class="curColor+'--text '+'text--lighten-3'">mdi-content-paste</v-icon></span>
          </span>
          
          <!--
          <v-divider dark/>
          <v-list-item v-if="(currentTime==MULTIRANGE[index]/multiplier)" style="cursor:pointer" class="d-flex justify-center"><v-icon  medium class='' @dblclick.stop="segment_info[index]['zoomsettings']!=null?resetZoom(index):null" @click.stop="segment_info[index]['zoomsettings']==null?setZoom(index):null"  :class="`${segment_info[index]['zoomsettings']!=null?curColor+'--text '+'text--lighten-3':'white--text'}`">mdi-billboard</v-icon></v-list-item>
          <v-list-item v-if="(currentTime==MULTIRANGE[index]/multiplier) && segment_info[index]['zoomsettings']!=null" style="cursor:pointer" class="d-flex justify-center"><v-icon  small  @click.stop="copySettings=JSON.parse(JSON.stringify(segment_info[index]['zoomsettings']))" :class="`${JSON.stringify(segment_info[index]['zoomsettings'])==JSON.stringify(copySettings)?curColor+'--text '+'text--lighten-3':'white--text'}`">mdi-content-copy</v-icon></v-list-item>
          <v-list-item v-if="(currentTime==MULTIRANGE[index]/multiplier) && segment_info[index]['zoomsettings']==null" style="cursor:pointer" class="d-flex justify-center"><v-icon  small class='' @click="segment_info[index]['zoomsettings']=copySettings">mdi-content-paste</v-icon></v-list-item>
          <v-divider dark/>
          <v-list-item v-if="wiggleRoom>=min_int*multiplier" style="cursor:pointer" class="d-flex justify-center"><v-icon  medium class='' @click="addRange(index)">mdi-map-marker-plus-outline</v-icon></v-list-item>
          <v-list-item v-if="MULTIRANGE.length>2" style="cursor:pointer" class="d-flex justify-center"><v-icon medium class='' @click="removeRange(index)">mdi-map-marker-minus-outline</v-icon></v-list-item>
          -->
            </span>
              </v-col>
            </v-row>
<!--  PROGRESS                   -->  
            <v-row dense fluid justify="center">
                <v-col cols="1" class='text-right'>
                  <span  style="cursor:pointer" @dblclick="initMultiRange({})"><v-icon small :class="`${curColor}--text text--lighten-3`">mdi-undo-variant</v-icon></span>
                  <v-menu v-if="Object.keys(simularContent).length>0" top  dense dark class="pa-0">
                  <template v-slot:activator="{ on,}">
                    <span  v-on="on" style="cursor:pointer"><v-icon small :class="`${curColor}--text text--lighten-3`">mdi-content-copy</v-icon></span>
                  </template> 
                <v-list class="pa-0 " dense style="cursor:pointer" color="rgba(0, 0, 0, 1)"> 
                 <v-list-item v-for="media_id in Object.keys(simularContent)" class="d-flex justify-left px-5 py-1" :key="media_id" @click="initMultiRange(simularContent[media_id]['original'])"> 
                    <v-avatar tile size="50px" > <!--:size="CropNow?300:500"  object-fit: cover-->
                      <mememedia :meme="thisMeme" :media_id="media_id" :file_base="thisMeme['media'][media_id]['file_base']" :bgcolor="curColor"/>
                    </v-avatar>
                   {{media_id}} {{Object.keys(simularContent[media_id]['original']['edit'])}} @ {{simularContent[media_id]['original']['edit_w']}}
                  </v-list-item>
                <v-divider dark />
                </v-list>
                </v-menu>
                </v-col>
                <v-col cols="10" class='text-center px-5'>
                  <div :style="`width:100%; ${this.magCSS?'overflow-x:hidden':''}`">
                  <div :style="`${this.magCSS?this.magCSS:''}`">
                  <input type="range" style="width:100%" ref="progress" class="myslider"
                    min="0" :max="Math.ceil(duration_in*multiplier)"
                    @input="updateCursor(progress_slider.value/multiplier,wasPlaying )"
                    @mousedown="wasPlaying=!video_source.paused;!wasPlaying?video_source.play():null"
                  />
                  </div></div>
                </v-col>
                <v-col cols="1" class='pa-0 text-left'>
                  <span v-if="!skipMode" @click="skipMode=!skipMode; repeatSingleMode=false" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3 mr-2`">mdi-all-inclusive</v-icon></span>
                  <span  v-if="skipMode" @click="repeatSingleMode=!repeatSingleMode" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-{{repeatSingleMode?'repeat-once':'repeat'}}</v-icon></span>
                </v-col>
              </v-row>
<!--  MULTIRANGE                   -->
              <v-row dense fluid justify="center" class="mt-2">
                <v-col cols="1" class='text-right'>
                  <span v-if="duration_in > magnifyDurS[0]" @click="magCSS?magCSS=null:setMagRange()" style="cursor:pointer"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-{{magCSS?'magnify-close':'magnify'}}</v-icon></span>
                </v-col>
                <v-col cols="10" class='text-center px-5'>
                <div v-if="1" :style="`width:100%; overflow-y:hidden; ${magCSS?'overflow-x:hidden':''}`">
                  <div :style="`${magCSS?magCSS:''}`">
                    <vue-slider   
                        ref="myMultiRangeSlider"
                        v-model="MULTIRANGE"
                        v-bind="options"
                        tooltip='none'
                        :silent=false
                        :max="multiSliderMax"
                        :min="0"
                        railStyle="backgroundColor:'black'"
                        :contained="true"
                        class="py-4"
                      >
                      <template v-slot:dot="{ index }">
                        <v-avatar size="15px" :style="`border: 1px solid ${zoomSegments2D[Math.floor(index/2)][index%2?1:0]!=null?JSON.stringify(zoomSegments2D[Math.floor(index/2)][index%2?1:0])==JSON.stringify(copiedSettings)?'red':'white':'transparent'}`" round :class="`${index%2?'mt-n7':'mt-1'} ${current_cursor==index?curColor+'--text  text--lighten-3':'grey--text  text--darken-1'}`"
                          style="cursor:pointer"
                          @dblclick="snap(index)" 
                          @click="current_cursor=index;"> 
                          <v-icon large  style="cursor:pointer" :class="`${current_cursor==index?curColor+'--text  text--lighten-3':'grey--text  text--darken-1'}`" >mdi-{{isNotAbut(index)?'chevron':'menu'}}-{{index%2?'down':'up'}}</v-icon>
                        </v-avatar> 
                      </template>
                      </vue-slider>
                    </div>
                  </div>
                </v-col>
                <v-col cols="1" class='text-left'>
               </v-col>
              </v-row>
<!--  PLAY                   -->
              <v-row dense fluid justify="center" align="center" class="mt-3" style="height:50px">
                <v-col cols="1" class='text-left'>
                   <template v-if="magCSS" v-for="(mag,i) in magnifyDurS">
                    <v-avatar v-if="duration_in>mag" :style="`${(magnifyDurS[magn_i]==mag)?'cursor:auto; pointer-events: none':'cursor:pointer'}`" :color="`${(magnifyDurS[magn_i]==mag)?curColor:'grey'}`" size="17px" class="lighten-3 pa-0 ml-1 mt-n2" @click="magn_i=i;setMagRange()" :key="i">
                        <b class="caption font-weight-bold noselect pa-0 ma-0">{{mag}}</b>
                    </v-avatar>
                    </template>
                </v-col>
                <v-col cols="2" class='text-center'>
                  <span v-if="currTime" :class="`${video_source.currentTime==MULTIRANGE[current_cursor]/multiplier?curColor+'--text text--lighten-3 font-weight-bold':'grey--text text--lighten-1 font-weight-normal'}`">{{currTime}}|</span>
                  <span v-if="duration_in>0" :class="`${curColor}--text text--lighten-3 font-weight-normal`">{{Math.floor(100*duration_in)/100}}s</span>
                </v-col>
                
                <v-col cols="6" class='text-center '>
                  <span @click="video_source.pause(); video_source.currentTime-=0.01; gotTheTimeTicking()" style="cursor:pointer"><v-icon small class="grey--text text--darken-1">mdi-rewind-10</v-icon></span>
                  <span @click="video_source.pause();video_source.currentTime-=0.05; gotTheTimeTicking()" style="cursor:pointer"><v-icon medium class="grey--text text--darken-1">mdi-rewind-5</v-icon></span>
                  <span  v-if="skipMode" @click="skipMode=false; video_source.currentTime=MultiRange2D[currSegInd][0]; gotTheTimeTicking(true)" style="cursor:pointer"><v-icon large :class="`${curColor}--text text--lighten-3`">mdi-skip-previous</v-icon></span>
                  <v-avatar><span @click="isPlaying?video_source.pause():video_source.play()" style="cursor:pointer"><v-icon size="50px" :class="`${isPlaying?'white':'grey'}--text text--lighten-3 py-0 px-5`">mdi-{{isPlaying?'pause':'play'}}-circle-outline</v-icon></span></v-avatar>
                  <span  v-if="skipMode" @click="skipMode=false; video_source.currentTime=MultiRange2D[nextSegInd][0]; gotTheTimeTicking(true)" style="cursor:pointer"><v-icon large :class="`${curColor}--text text--lighten-3`">mdi-skip-next</v-icon></span>
                  <span @click="video_source.pause();video_source.currentTime+=0.05; gotTheTimeTicking()" style="cursor:pointer"><v-icon medium class="grey--text text--darken-1">mdi-fast-forward-5</v-icon></span>
                  <span @click="video_source.pause();video_source.currentTime+=0.01; gotTheTimeTicking()" style="cursor:pointer"><v-icon small class="grey--text text--darken-1">mdi-fast-forward-10</v-icon></span>
                </v-col>
                <v-col cols="2" class='text-right'>
                  <span v-if="totalTime>0" :class="`${(totalTime<max_time && totalTime>1)?curColor+'--text text--lighten-3':'grey--text text--darken-3'}`">{{totalTime}}s</span>
                  <v-icon medium :class="`${canRec?curColor+'--text text--lighten-3':'grey--text text--darken-3'} mt-n1` ">mdi-record-rec</v-icon>
                </v-col>
                    <v-col cols="1" class='text-right'>
                      <span style="cursor:pointer" class="d-flex justify-center" @click="globalaudio=!globalaudio| 0"><v-icon  medium  :class="`${globalaudio?curColor+'--text text--lighten-3':'grey--text text--darken-3'}`" >mdi-volume-{{globalaudio?'high':'high'}}</v-icon></span>
                    </v-col>
              </v-row>
            </v-col>
            <!-- let play anders update canvas niet; geen moeite in stoppen is niet straightforward ; het is geen video maar stream displayed on canvas-->
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="mt-0">
            <v-row dense fluid  class="px-0 mt-0">
              <v-col cols="4" class='text-left '>
                <span  :class="`overline white--text text--lighten-3`"  @click="closeDialog()" style="cursor:pointer">cancel</span>
              </v-col>
              <v-col cols="4" class='text-center '>
                  <v-tooltip left v-if="movieOut">
                <template v-slot:activator="{ on, attrs }">
                  <span  v-on="on" style="cursor:pointer" @mouseover="playBlob(true)" @mouseleave="playBlob(false)" @dblclick="movieOut=null;movieBlob=null; mode='edit'"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-video-box</v-icon></span>
                </template>
                <video ref="movie_preview" :src="movieOut" loop width="200px" height="200px"/>
              </v-tooltip>
       
               <v-tooltip left v-if="snapShot" dark >
                <template v-slot:activator="{ on, attrs }">
                  <span  v-on="on" style="cursor:pointer" @dblclick="snapShot=null;snapShotBlob=null; snapshotSaveSettings=null"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-image-area</v-icon></span>
                </template>
                <img ref="poster_img" :src="snapShot" width="200px" height="200px">
              </v-tooltip>
              </v-col>
               <v-col cols="4" class='text-right '>
                <span  v-if="ext&&movieBlob&&snapShotBlob" style="cursor:pointer" @click="saveVideo()"><v-icon medium :class="`${curColor}--text text--lighten-3`">mdi-content-save</v-icon></span>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-dialog>
</template>


<script>
import { mapState,mapMutations,mapActions } from 'vuex'
import { ObjectID } from 'bson';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import mememedia from '../components/mememedia'
const fb = require('../firebaseConfig.js')
const Dropbox = require('dropbox').Dropbox;
let mimetypes=['video/webm;codecs=vp9','video/webm;codecs=vp8']; let medrec_opt = null
for (let m of mimetypes){if (MediaRecorder.isTypeSupported(m)) {medrec_opt = { mimeType : m}; break;}}
      
function repos(pos){
          let out = []; let i=0; 
          while(i<pos.length){out.push([pos[i],pos[i+1],{ backgroundColor: 'pink' }]);i=i+2}
          return out
      }
export default {
  data () {
    return {
      ext:null,
      show_grid:false,
      medrec_options: medrec_opt,
      possible_sizes: [1080,720,480,360,240,120], //1080
      size_edit_w: 480, size_out_w: 750, aspectRatio:1/1, fps:25, prevent_white_space:true, zoom_speed:3, rot:0, oversample:10,
      mediaRecorder:null, audioStream:null, gainNode:null,gainNodePre:null, gainNodeGlobal:null,
      canvas_edit:null, video_source:null, progress_slider:null,canvas_preproc:null,
      loading:false, loadmessage:'',
      org_vid: {'width':0,'height':0,'ratio':1},
      edit_vid: {'width':0,'height':0,'off_x_max':0,'off_y_max':0,'center_x':0,'center_y':0},
      edit_vid_zoom:{'off_x':0,'off_y':0,'flip_x':1,'flip_y':1,'rot':0,'zoom':1},
      shift_add:false, shift_cum:{'x':0,'y':0},
      multiplier:100,
      isPlaying:false,wasPlaying:false,
      max_time:15,
      mode:'edit',
      movieOut:null, movieOutLength:null,movieBlob:null,
      snapShotBlob:null, snapShot:null,
      duration_in:0,
      skipMode: true, repeatSingleMode: false,
      currTime:null, currSegInd: -1, nextSegInd:0,closetSeg:[null,null],
      magnifyDurS:[2,5,15,30], magn_i: 0 , magCSS:null,
      //to be cleared in init
      canvas_edit_ctx:null,canvas_out_ctx:null,
      pot_white: false, simularContent:{},
      window:0.2, //sec
      savesettings:null, snapshotSaveSettings:null, dBaSaveSettings:null,
      //dba
      dBRange:{'min':-100,'max':0, 'silence':-98},
      dBa:{},
      dBaS:{'min':null,'max':null,'mean':null, 'mean_top':null, 'ready':false},
      dBa_ref: -60, db_ref_margin: 2, db_ref_topperc: 10,
      //MULTIRANGESlider
      copiedSettings:null,
      myMultiRangeSlider:null, wiggleRoom:0,
      autolink:false,
      min_int: 0.1, max_int: 1, //in seconds
      globalaudio:1,
      audioSegments2D:[[1,1]],
      zoomSegments2D:[[null,null]],
      now:0,
      current_cursor:0,
      MULTIRANGE: [0,1],multiSliderMax:1E10, //kan zijn dat parent meegaat
      options: {
              process: pos => { 
                  let out=[]; let i=0;
                  while (i<pos.length){
                      out.push([pos[i], pos[i+1] , {backgroundColor: 'white'} ])
                      i=i+2}
                  return out },
              enableCross: false,
              minRange:0,
              },
    }
  },
  computed: {
    ...mapState('auth',['currentUser']),
    ...mapState('settings',['dbx_token','currentSet']),
    ...mapState('globals',['safeMode','mediaPath','colors100']),
    SafeMode(){return this.safeMode},
    curColor(){try{return this.colors100[this.thisMeme['theHundred'][this.currentSet]['digits']]}catch{return 'grey'}},
    startTime(){let out =null; try{if (!this.skipMode){out= 0} else {  if (this.repeatSingleMode){ out= this.MultiRange2D[this.currSegInd!=-1?this.currSegInd:this.nextSegInd][0]} else{  out= this.MultiRange2D[0][0]} }}catch{out=0; this.skipMode=false}; return out },
    endTime(){let out =null; try{if (!this.skipMode){out= this.duration_in} else {    if (this.repeatSingleMode){ out= this.MultiRange2D[this.currSegInd!=-1?this.currSegInd:this.nextSegInd][1]} else{  out= this.MultiRange2D[this.MultiRange2D.length-1][1];} }}catch{out= this.duration_in;this.skipMode=false}; return out },
    totalTime(){try{ let out= this.MultiRange2D.map(x=>x[1]-x[0]);out= out.reduce((a, b) => a + b, 0); return (Math.round(out*100)/100).toFixed(2) }catch{return null}},
    totalTimeNotSilent(){try{ let out= this.MultiRange2D.map((x,ind) => {return this.audioSegments2D[ind][0]>0?x[1]-x[0]:0}); out= out.reduce((a, b) => a + b, 0); return (Math.round(out*100)/100).toFixed(2)}catch{return null}},
    size_edit_h(){return this.size_edit_w/this.aspectRatio},
    size_out_h(){return this.size_out_w/this.aspectRatio},
    canRec(){
      return (!this.movieBlob && this.totalTime<this.max_time && this.totalTime>1 &&
      ((this.dBaS['ready']&& this.dBaS['mean_top'] <= this.dBa_ref+this.db_ref_margin &&  this.dBaS['mean_top']>= this.dBa_ref-this.db_ref_margin)||!this.globalaudio)
      )
      },
    MultiRange2D(){let out = [];let i=0;while(i < this.MULTIRANGE.length) {out.push([this.MULTIRANGE[i]/this.multiplier,this.MULTIRANGE[i+1]/this.multiplier]); i=i+2;}; return out; }, 
  },
  methods: {
    ...mapActions('memes',['updateMemeInFB','queueffmpeg']),
    ...mapMutations('globals',['toggleSafeMode']),
    reset_params(){
      this.canvas_edit_ctx=null; this.canvas_out_ctx=null;
      this.magCSS=null; this.magn_i=0; this.movieOutLength = null;
      this.snapShotBlob=null; this.snapShot=null;this.movieBlob=null; 
      this.movieOut=null;this.duration_in=0; this.prevent_white_space=true
      this.edit_vid = {'width':0,'height':0,'off_x_max':0,'off_y_max':0,'center_x':0,'center_y':0}
      this.org_vid= {'width':0,'height':0,'ratio':1}
      this.edit_vid_zoom = {'off_x':0,'off_y':0,'flip_x':1,'flip_y':1,'rot':0,'zoom':1}
      this.savesettings=null; this.snapshotSaveSettings = null; this.dBaSaveSettings = null;
      this.dBa= {}; this.dBaS= {'min':null,'max':null,'mean':null, 'mean_top':null, 'ready':false};
    },
    //MULTIRANGESHIT
    initMultiRange(segS){
      let range=[];this.audioSegments2D = []; this.segSegments2D= []
      this.multiSliderMax =  Math.floor(this.duration_in*this.multiplier)
      if (segS.hasOwnProperty('edit')){
        if (Object.keys(segS['edit']).length>0){
          for (let i in Object.keys(segS['edit'])){
            range = range.concat([segS['edit'][i]['range_start']*this.multiplier, Math.min(segS['edit'][i]['range_end']*this.multiplier,this.multiSliderMax)])
            this.audioSegments2D[i] = [segS['edit'][i]['audio_start'],segS['edit'][i]['audio_end']]
            this.zoomSegments2D[i] = [segS['edit'][i]['zoom_start'],segS['edit'][i]['zoom_end']]
          }
        }
      }else{
        range=  [0,this.multiSliderMax]
        this.audioSegments2D = [[1,1]]; this.zoomSegments2D= [[null,null]]
      }
      this.myMultiRangeSlider.setValue(range)
      if (segS.hasOwnProperty('dBaS')){this.dBa= {}; this.gainNodePre.gain.value=segS['dBaS']['preGain']; 
      this.gainNodeGlobal.gain.value = segS['dBaS']['globalaudio']?1:0; this.determine_dBa() }
      if (segS.hasOwnProperty('snapshot')){this.snapshotSaveSettings = segS['snapshot']}
    },
    isNotAbut(pos){
      let out = false
      if (this.MULTIRANGE.length>2){
        if( (pos%2) && pos<this.MULTIRANGE.length-1){out = (Math.abs(this.MULTIRANGE[pos+1] - this.MULTIRANGE[pos])!=0) }
        if( !(pos%2) && pos>0){out = (Math.abs(this.MULTIRANGE[pos] - this.MULTIRANGE[pos-1])!=0) }
      }
      return out
    },
    audioToggle(){
      if (this.currSegInd>-1){
        this.$set(this.audioSegments2D[this.currSegInd],0, !this.audioSegments2D[this.currSegInd][0] | 0) 
        this.$set(this.audioSegments2D[this.currSegInd],1, !this.audioSegments2D[this.currSegInd][1] | 0)
        let that=this; let as = this.audioSegments2D
        this.options =  {
          process: pos => { 
          let out=[]; let i=0;  
          while (i<pos.length){
            out.push([pos[i], pos[i+1] , {backgroundColor: that.audioSegments2D[Math.floor(i/2)][0]?'white':'grey'} ])
            i=i+2};  
            return out }
            ,enableCross: false, minRange:0}
      this.determine_dBa();
      }
    },
    addRange(){
        this.getSegmentIndex()
        let cT = this.video_source.currentTime
        if (this.wiggleRoom>this.min_int){
          let pos = this.currSegInd>-1?this.currSegInd*2:this.closetSeg[0]*2+this.closetSeg[1]
          let val1 = (this.currSegInd>-1?cT:cT-this.min_int )*this.multiplier; let val2 = (this.currSegInd>-1?cT:cT+this.min_int )*this.multiplier 
          let range = this.myMultiRangeSlider.getValue(); 
          range.splice(pos,0,val1,val2)
          this.audioSegments2D.splice(Math.floor(pos/2)+this.closetSeg[1],0,this.currSegInd>-1?JSON.parse(JSON.stringify(this.audioSegments2D[this.currSegInd])):[1,1])
          this.zoomSegments2D.splice(Math.floor(pos/2)+this.closetSeg[1],0,[null,null])
          this.myMultiRangeSlider.setValue(range)
          this.getSegmentIndex()
          }else{}
    },
    removeRange(){
        this.getSegmentIndex()
        let range = this.myMultiRangeSlider.getValue()
        if (range.length>2){
        range.splice(this.currSegInd*2,2)
        this.audioSegments2D.splice(this.currSegInd,1)
        this.zoomSegments2D.splice(this.currSegInd,1)
        this.myMultiRangeSlider.setValue(range)}
         this.getSegmentIndex()
     },
    setZoom(){ //zoomSegments2D
      this.zoomSegments2D[ Math.floor(this.current_cursor/2)][this.current_cursor%2?1:0] =  JSON.parse(JSON.stringify(this.edit_vid_zoom));  this.gotTheTimeTicking(); this.snap(this.current_cursor)
     },
    resetZoom(){ this.zoomSegments2D[ Math.floor(this.current_cursor/2)][this.current_cursor%2?1:0] =  null; this.gotTheTimeTicking(); this.snap(this.current_cursor)},
    getSegmentIndex(){
      let currentTime = this.video_source.currentTime;
      this.currSegInd =-1; 
      let i=0; let closest_i=[null,null]; let room = Infinity;
      while(i < this.MultiRange2D.length) {
        if(currentTime>=this.MultiRange2D[i][0] && currentTime<=this.MultiRange2D[i][1]){
          this.currSegInd=i
          this.nextSegInd = (this.currSegInd<=this.MultiRange2D.length-2)?this.currSegInd+1:0
        }
        for (let j of [0,1]){let c =Math.abs(currentTime-this.MultiRange2D[i][j]); if (c<room){room = c; closest_i=[i,j]}}
        i++
      }
      //kijken of begin end erger wordt => let op niet closest_i veranderen.....
      for (let j of [0,this.duration_in]){let c =Math.abs(currentTime-j); if (c<room){room = c;}}
      this.wiggleRoom=room; this.closetSeg=closest_i
    },
    //END MULTIRANGESHIT
    closeDialog(){this.video_source.pause();this.reset_params();this.$emit('finishedVideoCropper');},
    setMagRange(){
      this.magCSS=null
      if (this.duration_in > this.magnifyDurS[this.magn_i]){
      let ct = Number(this.currTime)
      let strt = Math.max(0, Math.ceil(100*(ct - this.magnifyDurS[this.magn_i]/2))/100)
      let stp =  Math.min(Math.floor(100*this.duration_in)/100,Math.floor(100*(ct + this.magnifyDurS[this.magn_i]/2))/100)
      let too_short = Math.floor(100*Math.abs(Math.abs(stp-strt)-this.magnifyDurS[this.magn_i]))/100
      if (too_short>0&&strt==0){stp= Math.min(Math.floor(100*this.duration_in)/100,stp+too_short)}
      if (too_short>0&&strt!=0){strt= Math.max(0,strt-too_short)}
      let magRange = [strt,stp];
      let magnification = Math.floor(100*this.duration_in/(stp-strt))/100
      let center = 100*strt/this.duration_in
      this.magCSS = 'width:'+magnification*100+'%; transform: translate(-'+center+'%, 0%);'
      }
    },
    snap(pos){
      this.current_cursor=pos
      this.updateCursor(this.MULTIRANGE[pos]/this.multiplier, false)
      this.resetVideoParameters(JSON.parse(JSON.stringify(this.zoomSegments2D[Math.floor(pos/2)][pos%2?1:0])))
      
    },
    updateCursor(time, play){ 
        let old_skipMode = this.skipMode;  this.skipMode=false; let that=this;
        this.video_source.pause(); 
        this.isPlaying=false;
        this.video_source.currentTime = time;
        this.gotTheTimeTicking(old_skipMode); 
        //doet alle time-shit zonder te playen
        setTimeout(()=>{that.updatecanvas()},150)
        if (play){ setTimeout(()=>{that.video_source.play()},100)}
    },
    createSnapShot(){
      this.snapshotSaveSettings = JSON.parse(JSON.stringify(this.edit_vid_zoom))
      this.snapshotSaveSettings['time']=this.video_source.currentTime
      //console.log(this.snapshotSaveSettings)
      this.snapShot = this.canvas_out.toDataURL('image/jpeg', 0.8)
      let that=this
      this.canvas_out.toBlob(function(blob){that.snapShotBlob=blob },'image/jpeg', 0.8);
    },
    startShift(event){if(!this.shift_add){this.shift_add={'x':event.clientX,'y':event.clientY}}},
    shift(event){if (this.shift_add){
      let x = this.shift_cum['x']+(event.clientX-this.shift_add['x'])/this.size_edit_w;
      let y = this.shift_cum['y']+(event.clientY-this.shift_add['y'])/this.size_edit_h;
      this.resetVideoParameters({'off_x':x,'off_y':y,'zoom':this.edit_vid_zoom['zoom']})
      }
    },
    wheel(event){
      let new_zoom = this.edit_vid_zoom['zoom'] + event.deltaY * -0.001*this.zoom_speed
      if (new_zoom<0.01){new_zoom=0.01}
      this.resetVideoParameters({'off_y':this.edit_vid_zoom['off_y'],'off_x':this.edit_vid_zoom['off_x'],'zoom':new_zoom})
    },
    endShift(event){this.shift_cum['x']=this.edit_vid_zoom['off_x']; this.shift_cum['y']=this.edit_vid_zoom['off_y']; this.shift_add=false},
    resetVideoParameters(current){
      let ZOOM=current?Object.keys(current).includes('zoom')?current['zoom']:this.edit_vid_zoom['zoom']:this.edit_vid_zoom['zoom']
      let OFF_X=current?Object.keys(current).includes('off_x')?current['off_x']:this.edit_vid_zoom['off_x']:this.edit_vid_zoom['off_x']
      let OFF_Y=current?Object.keys(current).includes('off_y')?current['off_y']:this.edit_vid_zoom['off_y']:this.edit_vid_zoom['off_y']
      let FLIP_X=current?Object.keys(current).includes('flip_x')?current['flip_x']:this.edit_vid_zoom['flip_x']:this.edit_vid_zoom['flip_x']
      let FLIP_Y=current?Object.keys(current).includes('flip_y')?current['flip_y']:this.edit_vid_zoom['flip_y']:this.edit_vid_zoom['flip_y']
      let ROT=current?Object.keys(current).includes('rot')?current['rot']:this.edit_vid_zoom['rot']:this.edit_vid_zoom['rot']
      this.edit_vid_zoom['rot']= ROT
      
      this.edit_vid_zoom['flip_x'] = FLIP_X; this.edit_vid_zoom['flip_y'] = FLIP_Y; 
      
      this.edit_vid_zoom['zoom'] = this.prevent_white_space?Math.max(1,ZOOM):ZOOM;
      // GEEN ROT90 DOEN!!! dit wordt een nightmare! => onderstaanfe kan wellicht analytischer maar dit is praktisch
      this.edit_vid['width']=this.size_edit_w  // W = canvas width
      this.edit_vid['height']=this.edit_vid['width']/this.org_vid['ratio'] // H = W / ratio_org
      if (this.edit_vid['height']<this.size_edit_h){ // als H < H_canvas dan andersom
        this.edit_vid['height']=this.size_edit_h // W = canvas width
        this.edit_vid['width']=this.edit_vid['height']*this.org_vid['ratio'] // H = W / ratio_org
      }
      this.edit_vid['width']= this.edit_vid['width']*this.edit_vid_zoom['zoom']
      this.edit_vid['height']= this.edit_vid['height']*this.edit_vid_zoom['zoom']
      this.edit_vid['center_x'] = -(this.edit_vid['width']- this.size_edit_w)/2
      this.edit_vid['center_y'] = -(this.edit_vid['height']- this.size_edit_h)/2 
      let O_X = (this.edit_vid['width']-this.size_edit_w)/(2*this.size_edit_w)
      let O_Y = (this.edit_vid['height']-this.size_edit_h)/(2*this.size_edit_h)
      this.edit_vid['off_x_max'] = this.prevent_white_space?O_X:Infinity
      this.edit_vid['off_y_max'] = this.prevent_white_space?O_Y:Infinity
      this.edit_vid_zoom['off_x']=  Math.sign(OFF_X)*Math.min(Math.abs(OFF_X),this.edit_vid['off_x_max'])
      this.edit_vid_zoom['off_y']=  Math.sign(OFF_Y)*Math.min(Math.abs(OFF_Y),this.edit_vid['off_y_max'])
      this.updatecanvas()
    },
    updatecanvas(){
      //zoom en audio
      this.canvas_edit_ctx.clearRect(0,0,this.size_edit_w, this.size_edit_h);
      
      // white space detector
      this.canvas_edit_ctx.fillStyle = "rgba(1,2,3,1)"; // "rgba(1,2,3,1)"; 
      this.canvas_edit_ctx.fillRect( 0, 0, 1, 1 );
      this.canvas_edit_ctx.fillRect(this.size_edit_w-1,0, 1, 1 );
      this.canvas_edit_ctx.fillRect(0,this.size_edit_h-1, 1, 1 );
      this.canvas_edit_ctx.fillRect(this.size_edit_w-1,this.size_edit_h-1, 1, 1 );
      // white space detector

      let x_0 = this.edit_vid['center_x']+this.size_edit_w*this.edit_vid_zoom['off_x']; let y_0 = this.edit_vid['center_y']+this.size_edit_h*this.edit_vid_zoom['off_y']
      let W = this.edit_vid['width']/2; let H = this.edit_vid['height']/2;
      this.canvas_edit_ctx.translate(x_0+W , y_0+H );
      this.canvas_edit_ctx.scale(this.edit_vid_zoom['flip_x'], this.edit_vid_zoom['flip_y']);
      this.canvas_edit_ctx.rotate( this.edit_vid_zoom['rot']*Math.PI / 180  );
      this.canvas_edit_ctx.translate(-x_0-W , -y_0-H);
      this.canvas_edit_ctx.drawImage(this.video_source, 0,0, this.org_vid['width'], this.org_vid['height'],x_0, y_0, this.edit_vid['width'], this.edit_vid['height']);  
      this.canvas_edit_ctx.setTransform(1,0,0,1,0,0)
      
      this.pot_white=false
      let ul = this.canvas_edit_ctx.getImageData(0, 0, 1, 1);
      let lr = this.canvas_edit_ctx.getImageData(this.size_edit_w-1,this.size_edit_h-1, 1, 1 );
      let ll = this.canvas_edit_ctx.getImageData(0,this.size_edit_h-1, 1, 1 );
      let ur = this.canvas_edit_ctx.getImageData(this.size_edit_w-1,0, 1, 1 );
      for (let pxl of [ul,lr,ll,ur]){
        if (pxl.data[0]==1 && pxl.data[1]==2 && pxl.data[2]==3 && pxl.data[3]==255){this.pot_white=true}
      }   
      if (this.show_grid){
       this.canvas_edit_ctx.strokeStyle = 'rgba(255, 255, 255)';
       for (let x_grid of [Math.floor(this.size_edit_h/3),2*Math.floor(this.size_edit_h/3)]){
        this.canvas_edit_ctx.moveTo(0, x_grid);
		    this.canvas_edit_ctx.lineTo(this.size_edit_w, x_grid);
        this.canvas_edit_ctx.stroke();}
        for (let y_grid of [Math.floor(this.size_edit_h/3),2*Math.floor(this.size_edit_h/3)]){
        this.canvas_edit_ctx.moveTo(y_grid, 0);
		    this.canvas_edit_ctx.lineTo(y_grid,this.size_edit_h);
        this.canvas_edit_ctx.stroke();}}
		  //DEZE ER UIT
      this.canvas_out_ctx.clearRect(0,0,this.size_out_w, this.size_out_h);
      let scl = this.size_out_h/this.size_edit_h
      x_0 = (this.edit_vid['center_x']+this.size_edit_w*this.edit_vid_zoom['off_x'])*scl; y_0 = (this.edit_vid['center_y']+ this.size_edit_h*this.edit_vid_zoom['off_y'])*scl
      W = (this.edit_vid['width']*scl)/2; H = (this.edit_vid['height']*scl)/2;
      this.canvas_out_ctx.translate(x_0+W , y_0+H );
      this.canvas_out_ctx.scale(this.edit_vid_zoom['flip_x'], this.edit_vid_zoom['flip_y']);
      this.canvas_out_ctx.rotate( this.edit_vid_zoom['rot']*Math.PI / 180  );
      this.canvas_out_ctx.translate(-x_0-W , -y_0-H);
      this.canvas_out_ctx.drawImage(this.video_source, 0,0, this.org_vid['width'], this.org_vid['height'], x_0, y_0, this.edit_vid['width']*scl, this.edit_vid['height']*scl)  
      this.canvas_out_ctx.setTransform(1,0,0,1,0,0)

    },
    playBlob(p){
      if (p){try{this.$refs['movie_preview'].play()}catch{}}else{
        try{this.$refs['movie_preview'].pause()}catch{}
      }
    },
    startRecordingNow(){
      this.video_source.pause()
      this.show_grid=false //else it will produce shocking video
      this.loading=true;
      this.mode='record'
      this.loadmessage='Recording'
      this.movieOut=null;
      this.movieBlob=null;
      this.video_source.currentTime=this.startTime
      this.getSegmentIndex(); this.current_cursor=0
      if (this.zoomSegments2D[0][0]==null){this.setZoom()}
      this.savesettings = {}
      for (let i in this.MultiRange2D){this.savesettings[i] = {'range_start':this.MultiRange2D[i][0], 'range_end': this.MultiRange2D[i][1], 'audio_start': this.audioSegments2D[i][0],'audio_end': this.audioSegments2D[i][1], 'zoom_start': this.zoomSegments2D[i][0],'zoom_end': this.zoomSegments2D[i][1]} }
      this.dBaSaveSettings = JSON.parse(JSON.stringify(this.dBaS))
      this.dBaSaveSettings['preGain'] = this.gainNodePre.gain.value
      this.dBaSaveSettings['globalaudio'] = this.globalaudio
      this.mediaRecorder.start(100);
      this.video_source.play()
    },
   
    SegmentFunction(currentInd,window){
      let currentTime = this.video_source.currentTime; 
      let audio= 1; 
      if (this.skipMode){audio = this.gainNode.gain.value} //else clicks if silent to silent part
      let zoom= null
      if (currentInd>-1 &&this.audioSegments2D && this.zoomSegments2D && this.MultiRange2D.length>0){
        this.MultiRange2D.forEach((interval,ind) => {
          //audio
          if (currentTime>=interval[0] && currentTime<= interval[1]){
            if (this.audioSegments2D[ind][0]==0){ audio=0;}
            else{
              audio=1; 
              let intro = true; try{intro = this.audioSegments2D[ind-1][0]==0 }catch{}
              let outro = true; try{outro = this.audioSegments2D[ind+1][0]==0 }catch{}
              if (currentTime>=interval[0] && currentTime<= interval[0]+window &&intro){audio = 1 * (currentTime-interval[0])/window}
              if (currentTime>=interval[1]-window && currentTime<= interval[1] && outro){audio = 1 * (interval[1]-currentTime)/window}
            }
          }
          //zoom (begin)
          if (currentTime>=interval[0] && currentTime<= interval[0]+window  && this.zoomSegments2D[ind][0]!=null){
             zoom = this.zoomSegments2D[ind][0]
          }
          if (currentTime>=interval[0]+window && currentTime<= interval[1]-window && this.zoomSegments2D[ind][0]!=null&&this.zoomSegments2D[ind][1]!=null){
            zoom={}
            for (let k of Object.keys(this.zoomSegments2D[ind][0])){
              let zoom_r = (this.zoomSegments2D[ind][1][k]-this.zoomSegments2D[ind][0][k])
              let time_r = interval[1]-interval[0]-2*window
              let incr = (currentTime-interval[0]-window) *(zoom_r/time_r)
              zoom[k] = this.zoomSegments2D[ind][0][k] + incr
              if (k=='zoom'){}
            }
          }
        })
      }
      return [audio, zoom]
    },
    gotTheTimeTicking(prev_mode){
      if (this.video_source.currentTime < this.startTime){this.video_source.currentTime = this.startTime;  if (this.isPlaying){this.video_source.play()} }
      if (this.video_source.ended || this.video_source.currentTime > this.endTime  ){
        if (this.mode=='record' && this.mediaRecorder.state=='recording'){
          this.mediaRecorder.stop()
          this.loading=false
          this.video_source.pause()
        }else{
          this.video_source.currentTime = this.startTime; this.video_source.play()
        }
      }
      this.progress_slider.value = this.video_source.currentTime*this.multiplier
      this.currTime = (Math.floor(this.video_source.currentTime*100)/100).toFixed(2)
      this.getSegmentIndex()
      if (this.currSegInd>-1){if (prev_mode){this.skipMode=true}}
      else{  if (this.skipMode){this.video_source.currentTime=this.MultiRange2D[this.nextSegInd][0]}}
      let audioZoom = this.SegmentFunction(this.currSegInd, this.window)
      if (audioZoom[0]!=null && audioZoom[0]!=this.gainNode.gain.value){this.gainNode.gain.value = audioZoom[0]}
      //if (audioZoom[1]){console.log(audioZoom[1])}
      if (audioZoom[1]!=null && (audioZoom[1]['flip_x']!=this.edit_vid_zoom['flip_x'] || audioZoom[1]['flip_y']!=this.edit_vid_zoom['flip_y'] || audioZoom[1]['rot']!=this.edit_vid_zoom['rot'] || audioZoom[1]['zoom']!=this.edit_vid_zoom['zoom'] || audioZoom[1]['off_x']!=this.edit_vid_zoom['off_x'] || audioZoom[1]['off_y']!=this.edit_vid_zoom['off_y'] )){this.resetVideoParameters(audioZoom[1]) }
      this.updatecanvas()
    },
    saveVideo(){
      let that=this
      this.loading=true
      this.loadmessage='uploading video to dropbox'
      let full_path = '/'+this.currentUser.uid+'/'+this.mediaPath['base']+'/'+this.ext+'/'
      let full_path_poster = '/'+this.currentUser.uid+'/'+this.mediaPath['base']+'/poster/'
      let file_name_base = this.thisMeme.id+'_'+new ObjectID().toString()
      let res_url = null
      const dbx = new Dropbox({ accessToken : this.dbx_token ,fetch: require("isomorphic-fetch") })
      dbx.filesUpload({path: full_path+file_name_base+'.'+this.ext, contents: this.movieBlob})
        .then(function(responseUL) {
          //console.log(full_path+'_'+file_name_base+'.'+that.ext)
          dbx.sharingCreateSharedLinkWithSettings({"path": full_path+file_name_base+'.'+that.ext}) 
            .then(function(responseLnk) {
            res_url = responseLnk.result.url.replace('dl=0','dl=1')
            if (!Object.keys(that.thisMeme).includes('media')){that.thisMeme['media']={}} 
            let new_pos = that.media_id//Object.keys(that.thisMeme[ext]).length
            let new_meme =  {
              'file_base': file_name_base ,'url':responseLnk.result.url.replace('dl=0','dl=1'),'type':that.ext,'ffmpeg':false,
              'duration':that.movieOutLength, 
              'original':{'width':that.org_vid['width'], 'height':that.org_vid['height'], 'duration':that.duration_in, 'file_size':that.initial_file_size, 'edit':that.savesettings,
                          'snapshot':that.snapshotSaveSettings, 
                          'dBaS': that.dBaSaveSettings,
                          'edit_w':that.size_edit_w},
              'size':{'width':that.size_out_w, 'height':that.size_out_h, 'fps':that.fps}
            }
            dbx.filesUpload({path: full_path_poster+file_name_base+'.jpg',contents: that.snapShotBlob})
              .then(function(responseUL2) {
                dbx.sharingCreateSharedLinkWithSettings({"path": full_path_poster+file_name_base+'.jpg'}) 
                  .then(function(responseLnk2) {
                   new_meme['url_poster'] = responseLnk2.result.url.replace('dl=0','dl=1')
                   that.$set(that.thisMeme['media'],new_pos,new_meme)
                    that.updateMemeInFB(that.thisMeme)
                    //that.queueffmpeg(that.thisMeme)
                    that.loading=false
                    that.mode="edit"
                    that.closeDialog()
                  }).catch(function(error) {console.log("got error:"+error) })
              }).catch((error) => {console.log('error happened uploading: '+error)})
          
          }).catch(function(error) {console.log("got error:"+error) })
        }).catch((error) => {console.log('error happened uploading: '+error)})
    },
    prepareRecording(){
      let that=this
      let stream_canvas = this.canvas_out.captureStream(this.fps);
      //let stream_audio_source = this.video_source.captureStream(this.fps);
      try{if(this.globalaudio){stream_canvas.addTrack(this.audioStream.getAudioTracks()[0])}}catch{}
      let recordedChunks = [];
      //out sync:
      // in sync 'video/x-matroska;codecs=avc1', 'video/webm;codecs=H264','video/webm;codecs=h264,vp9,opus'
      // werkt niet: 'video/mp4;codecs=H264'
      //met gelijke bitrate settings is vp9 net iets beter dan h264,
      this.medrec_options['audioBitsPerSecond'] = 128*1000
      this.medrec_options['videoBitsPerSecond'] = (this.size_out_w *2.5+700) *1000
      this.mediaRecorder = new MediaRecorder(stream_canvas, this.medrec_options);
      this.mediaRecorder.ondataavailable = handleDataAvailable; 
      function handleDataAvailable(event) {if (event.data.size > 0) {recordedChunks.push(event.data)} }
      
      this.mediaRecorder.onstop = function(e) {
        //console.log(that.medrec_options)
        that.movieBlob = new Blob(recordedChunks, that.medrec_options); //deze niet op video/mp4 zetten anders audio out sync
        recordedChunks = []; 
        that.movieOutLength = that.totalTime
        that.movieOut = URL.createObjectURL(that.movieBlob)
      }
    },
    renormalizedBa(){
      let current_pre = this.gainNodePre.gain.value;
      try{this.gainNodePre.gain.value = current_pre * Math.pow(10, (this.dBa_ref-this.dBaS['mean_top'])/20)}
      catch{this.gainNodePre.gain.value = 1}
      this.dBa = {}
      this.dBaS = {'min':null,'max':null,'mean':null, 'mean_top':null, 'ready':false}
      this.determine_dBa()
    },
    determine_dBa(){
      //console.log('determineNow')
      this.dBaS = {'min':this.dBRange['max']+1,'max':this.dBRange['min']-1,'mean':null,  'mean_top':null,'ready':false}
      let it=0; let it_nos=0; let dt = 0; let top_dba = []
      for (let t_i of Object.keys(this.dBa)){ 
        for (let i in this.MultiRange2D){
            if (t_i>= this.MultiRange2D[i][0] && t_i<=this.MultiRange2D[i][1] && this.audioSegments2D[i][0]>0){
              it++
              if (this.dBa[t_i]>this.dBRange['silence']){ 
                it_nos++;
                dt=dt+this.dBa[t_i]
                top_dba.push(this.dBa[t_i])
                this.dBaS['max'] = Math.max( this.dBaS['max'],this.dBa[t_i])
                this.dBaS['min'] = Math.min( this.dBaS['min'],this.dBa[t_i])
                }
            }
        }
      }
      this.dBaS['mean'] = dt/it_nos
      if (top_dba.length>0){ 
        top_dba.sort(); top_dba = top_dba.splice(0, Math.ceil((this.db_ref_topperc * top_dba.length)/100));
        this.dBaS['mean_top']=top_dba.reduce((a, b) => a + b, 0)/top_dba.length
      }
      let min_req  = 0.5*this.totalTimeNotSilent*100  // + 1 bij hoge sample rate deze omlaaag......
      if (it>=min_req){this.dBaS['ready'] = true}
    },
    initShit(){
      let that=this
      this.reset_params()
      this.canvas_edit = this.$refs['canvas_edit'];
      this.canvas_out = this.$refs['canvas_out'];
      this.canvas_edit_ctx = this.canvas_edit.getContext('2d',{preserveDrawingBuffer:true,alpha: true, desynchronized: true, });
      this.canvas_out_ctx = this.canvas_out.getContext('2d',{preserveDrawingBuffer: true,alpha: true, desynchronized: true, });
      this.video_source = this.$refs['video_source']
      this.video_source.src=this.initial_img
      this.progress_slider=this.$refs['progress']
      this.loading=true
      this.loadmessage='loading video, be patient.....'
      this.video_source.load()
      this.video_source.onloadedmetadata = function() { 
        that.loading=false
        let AudioContext = window.AudioContext || window.webkitAudioContext;
        let audioCtx = new AudioContext();
        let destination = audioCtx.createMediaStreamDestination();
        let source = audioCtx.createMediaElementSource(that.video_source);
        let analyserNode = audioCtx.createAnalyser();
        that.audioStream = destination.stream
        that.gainNode = audioCtx.createGain();  that.gainNodePre = audioCtx.createGain(); that.gainNodeGlobal= audioCtx.createGain();
        that.gainNode.gain.value = 1; that.gainNodePre.gain.value = 1; that.gainNodeGlobal.gain.value = 1;
        source.connect(that.gainNodeGlobal).connect(that.gainNodePre).connect(that.gainNode).connect(analyserNode).connect(destination) //deze anders record ie niet
        source.connect(that.gainNodeGlobal).connect(that.gainNodePre).connect(that.gainNode).connect(analyserNode).connect(audioCtx.destination) //anders hoor je em niet.
        ///

      analyserNode.fftSize = 1024; //dit lijkt goede compromis;
      let bufferLength = analyserNode.frequencyBinCount;
      analyserNode.maxDecibels = that.dBRange['max']; analyserNode.minDecibels = that.dBRange['min'];
      let dB_range = (analyserNode.maxDecibels- analyserNode.minDecibels)
      let dataArray = new Uint8Array(bufferLength); let max_val = 2**8
      let f_c =  (audioCtx.sampleRate/2)/analyserNode.frequencyBinCount
      function Ra(f) { 
        let f2=Math.pow(f,2); let f4 =Math.pow(f,4); 
        let teller =  Math.pow(12194,2) * f4
        let noemer =  (f2 + Math.pow(20.6,2) ) * Math.sqrt( (f2 + Math.pow(107.7,2)) * (f2 + Math.pow(737.9,2))) * (f2 + Math.pow(12194,2)) 
        return  teller/noemer///  
      };
      let dBa_arr={}
      for(var i = 0; i < bufferLength; i++) {let freq = (f_c*i+f_c*(i+1))/2; dBa_arr[i] = 20*Math.log10(Ra(freq))+2}
      function sample() {
        if (that.showVideoCropper){
          requestAnimationFrame(sample);
          analyserNode.getByteFrequencyData(dataArray);
          let Li_cumm = 0
          for(var i = 0; i < bufferLength; i++) {
            let dB_f = (dB_range*(dataArray[i]/max_val))+analyserNode.minDecibels; dB_f+=dBa_arr[i]
            let tmp = dB_f; tmp=tmp+dBa_arr[i]
            let dB_a =  Math.max(analyserNode.minDecibels,tmp)
            //overal level: ///https://en.wikipedia.org/wiki/Sound_pressure
            Li_cumm += Math.pow(10,(dB_a-analyserNode.minDecibels)/10)
            }
          let t_i = Math.round(that.video_source.currentTime*100)/100; //per 0.1 sec 
          let dBa_i = 10*Math.log10(Li_cumm/bufferLength) + analyserNode.minDecibels
          dBa_i = Math.round(10*dBa_i)/10
          //updates till all times t_i are present
          if (!Object.keys(that.dBa).includes(String(t_i))){that.$set(that.dBa, t_i,dBa_i) }
          else{
            if (!that.dBaS['ready'] && Object.keys(that.dBa).length<that.totalTimeNotSilent*100*0.85){ //paar keer draaiien en dan niet meer wijzigen
              that.determine_dBa()
            }
            }
          }
        }
        sample()
        ///
        that.duration_in = that.video_source.duration<60*60*24?that.video_source.duration:100000
        that.org_vid['width']=that.video_source.videoWidth
        that.org_vid['height']=that.video_source.videoHeight
        that.org_vid['ratio']=that.org_vid['width']/that.org_vid['height']
        let max_size = Math.min(that.org_vid['width'], that.org_vid['height'])
        that.size_out_w =  that.possible_sizes[0]
        that.possible_sizes.forEach((el) => {if (el>max_size){that.size_out_w = el}} )
        that.simularContent={}
        if( Object.keys(that.thisMeme).includes('media')){  
          for (let m of Object.keys(that.thisMeme['media'])){
              if ((that.thisMeme['media'][m]['original']['duration']>=that.duration_in-0.2 
                  && that.thisMeme['media'][m]['original']['duration']<=that.duration_in+0.2)
                  
                  //that.thisMeme['media'][m]['original']['file_size'] == that.initial_file_size &&
                  //that.thisMeme['media'][m]['original']['width'] == that.org_vid['width'] &&
                  //that.thisMeme['media'][m]['original']['height'] == that.org_vid['height'])
              ){ that.simularContent[m] = that.thisMeme['media'][m] }
            }
        }
         that.myMultiRangeSlider = that.$refs.myMultiRangeSlider
        that.initMultiRange({})
        that.video_source.addEventListener('play', function() {
          (function loop() {
            if (that.video_source.paused){that.isPlaying=false;}else{that.isPlaying=true}
              try{that.gotTheTimeTicking(false)}catch{}
              if (that.isPlaying){setTimeout(loop, 1000/(that.oversample*that.fps))}
          })()
        }, 0);
        that.resetVideoParameters()
        that.prepareRecording()
      }
    }
  },  
  props: ['showVideoCropper','thisMeme','media_id','initial_img','initial_file_size'],
  watch: {  
    showVideoCropper: function(){if (this.showVideoCropper){let that=this; setTimeout(()=>{that.initShit()}, 100);}},
    MultiRange2D: function(){this.determine_dBa()},
    globalaudio: function(){
      this.dBaS = {'min':null,'max':null,'mean':null, 'mean_top':null, 'ready':false},
      this.dBa = {}
      this.gainNodePre.gain.value=1; 
      this.gainNodeGlobal.gain.value = this.globalaudio
      if (this.globalaudio){this.determine_dBa()}
    }
  },
  created: function(){
    try{    this.ext = this.medrec_options['mimeType'].split('video/')[1].split(';')[0] }
    catch{  this.ext = null }
  },
  components:{VueSlider,mememedia}
  }
</script>
<style scoped>
>>>.v-slider__thumb {cursor:pointer;}
>>>.v-slider__thumb::before { background-color: transparent;}


.myslider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: rgba(0, 0, 0, .90);
  border: 1px solid  rgba(128, 128, 128, .20);
  outline: none;
  -webkit-transition: 0s;
  transition: opacity 0s;
}
.myslider:hover { background: rgba(70,169,167,0.2);}
.myslider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2px;
  height: 20px;
  background: white;
  cursor: pointer;
}
>>>.vue-slider-rail {
  background-color: black;
  border-radius: 10px;
}
</style>

