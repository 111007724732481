<template>
        <v-dialog v-if="showEdit" :value="showEdit" persistent max-width="500px" @click:outside="closeDialog()">
            <v-card dark color="#272727" @dragover.prevent @dragover.prevent @dragleave.prevent @drop.prevent :style="`overflow-y: hidden`"> <!--:color="colors100[ edit.theHundred[currentSet]['digits']]" class="lighten-4">-->
<!--TOP --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                <v-row dense fluid class="px-5 py-0 mt-0" align="center">
                    <v-badge class="mt-n5" v-if="isTheHun"  :value="!sameInst"  top overlap bordered :color="`${colors100[edit.theHundred[currentSet]['digits']]} lighten-3`">
                      <template v-slot:badge>!</template>
                      <v-avatar :color="colors100[edit.theHundred[currentSet]['digits']]" size="45"  class="lighten-3 mt-5 mb-n1 pa-0" style="z-index: 1">
                            <b :class="`headline font-weight-bold noselect grey--text text--darken-4`">{{edit.theHundred[currentSet]['digits']}}</b>
                        </v-avatar>
                    </v-badge>
                    <v-spacer/>
                    <span  @click="toggleSafeMode()" class="mx-2" style="cursor:pointer" > <v-icon  medium color="white">mdi-eye{{SafeMode?'-off':''}}</v-icon></span>
                    <span  @click="closeDialog()" class="mx-2" style="cursor:pointer"> <v-icon medium>mdi-close</v-icon>  </span>
                    <!--<span class="ml-4 mt"><b>{{edit.id}}</b></span>-->
                    <v-spacer/>
                    <span class="" v-if="usrSet['dims'][actual_d]"> <v-icon x-large :color="`${colors100[edit.theHundred[currentSet]['digits']]} lighten-3`" >mdi-{{usrSet['dims'][actual_d]['icon']}}</v-icon> </span>
                 </v-row>
<!--instance edit --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                <v-row dense fluid class="px-5 mt-2 mb-n5">
                  <v-col cols="1" class="mt-2"> <span @click="insertquote()" style="cursor:pointer"><v-icon color="white" medium >mdi-comma-circle-outline</v-icon></span></v-col>
                  <v-col cols="10" class="px-2">
                    <v-form ref="editform" v-model="valid" @submit.prevent lazyValidation >
                        <v-text-field @keyup.native="edit['instances']={}" @keyup.native.enter="fixName(inst_edit_name)" 
                        class="" dense counter maxlength="500" rows="2"
                        v-model="inst_edit_name" :rules="nonEmptyRule"
                        ref="instance" />
                    </v-form>
                  </v-col>
                  <v-col cols="1" class="mt-2"> <span v-if="inst_edit_name.length>1"  @click="fixName(inst_edit_name);automajorize();fixName(inst_edit_name)" style="cursor:pointer"><v-icon medium>mdi-state-machine</v-icon> </span> </v-col>
                </v-row>
<!--buttons --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                <v-row dense fluid class="pb-2"  justify="center" style="cursor:pointer">
                       <span v-if="edit['id']" @click="showMode='avatar'"> <v-icon :class="`mx-2 grey--text text--${showMode=='avatar'?'lighten-3':'darken-3'}`" medium>mdi-account-circle-outline</v-icon>  </span>
                       <span  @click="showMode='edit'"> <v-icon :class="`mx-2  grey--text text--${showMode=='edit'?'lighten-3':'darken-3'}`" medium>mdi-tune-vertical-variant</v-icon>  </span>
                       <span  @click="showMode='major'"> <v-icon :class="`mx-2 grey--text text--${showMode=='major'?'lighten-3':'darken-3'}`" medium>mdi-chevron-triple-up</v-icon>  </span>
                      <v-icon v-if="!allInstancesOk" color="error" >mdi-alert-circle-outline</v-icon>
                </v-row>
<!-- AVATAR --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                <v-row dense fluid class="px-5"  justify="center" style="cursor:pointer">
                  <v-container v-if="edit['id'] && showMode=='avatar'" class="py-0 px-5" :style="`height: 300px; overflow-y: scroll`">
                    <v-row dense class="mt-2 pb-3 px-0 ma-0 mb-5" fill-height justify="center" align="start">
                     <v-col>
                     <v-avatar rounded class="rounded-lg" :color="`${colors100[edit.theHundred[currentSet]['digits']]}`" size="250px" style="cursor:pointer">
                            <v-avatar rounded  :class="`rounded-lg ${colors100[edit.theHundred[currentSet]['digits']]} lighten-3`" width="99.5%" height="99.5%" > <!--:size="CropNow?300:500"  object-fit: cover-->
                                <img  v-if="getUrlImg() && SafeMode" style="opacity:0.6" src="../assets/img/anon.jpg"/>
                                <img  v-if="getUrlImg() && !SafeMode" :src="getUrlImg()" />
                                <img v-if="!getUrlImg()" style="opacity:0.3; object-fit: cover;" src="../assets/img/unknown.jpg"/>
                            </v-avatar>
                        </v-avatar>
                     
                     </v-col>
                     <v-col>
                     <imagesort  :showImageSort="true" :size="50" :thisMeme="edit" @finishedImageSort="showImageSort=false" />
                     </v-col>
                     </v-row>
                  </v-container>
<!-- MAJOR STUFF EDIT --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                  <v-container v-if="edit['instances'] && showMode=='edit'" class="py-0 px-5" :style="`height: 300px; overflow-y: scroll`">
                    <v-row dense class="pb-3 px-0 ma-0" v-for="(instance, ins) in  edit['instances']">
                      <v-col cols="1"><v-icon class="" small :color="`${colors100[ins+'0']} lighten-3`">mdi-penguin</v-icon></v-col>
                      <v-col cols="11">
                        <template  v-for="(n, index) in instance['codeName'].split('`')">
                          <span v-if="n!=' '" :key="index">
                            <v-menu >
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip  v-bind="attrs" v-on="on" small  class="px-2 mr-1 mb-1" :color="`grey darken-2`" outlined medium><span class="body-2 white--text font-weight" >{{n}}</span>
                                  <v-avatar v-if="codestring[ins][index]>-1" right medium class="mr-n2 lighten-3" :color='colors100[("0" + Number(codestring[ins][index]*10)).slice(-2)]'><span class="body-2 grey--text text--darken-4 font-weight-bold" >{{codestring[ins][index]}}</span></v-avatar>
                                </v-chip>
                              </template>
                              <v-list dense class="pa-0" v-for="i in [-1,0,1,2,3,4,5,6,7,8,9]" :key="i">
                                <v-list-item @click="clickerdeclick(ins,index,i)"><v-list-item-title>{{i}}</v-list-item-title></v-list-item>
                              </v-list>
                            </v-menu>
                          </span>
                          <span v-else class='px-2' :key="index">&nbsp;</span>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
<!--MAJOR STUFF VIEW --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                  <v-container v-if="edit['instances'] && showMode=='major'" class="py-0 px-5" :style="`height: 300px; overflow-y: scroll`" fluid>
                    <v-col>
                    <v-row dense class="pa-0 ma-0" v-for="(instance, ins) in  edit['instances']" :key="ins">
                      <v-col>
                      <v-row class="" v-for="(a,it) in Object.keys(edit['instances'][ins]['alts'])" :key="it" no-gutters fluid>
                        <v-col cols="1"><v-icon class="" small :color="`${colors100[ins+'0']} lighten-3`">mdi-penguin</v-icon></v-col>
                        <v-col cols="5"> 
                          <v-chip class="px-2 mr-1 mb-1 body-2 grey--text text--darken-2" outlined small color="grey darken-3">
                          <v-chip v-if="a" :color="`${colors100[a]}`" x-small class="pa-1 ml-n1 mr-1 lighten-3">
                            <span class="body-2 grey--text text--darken-3 font-weight-bold">{{a}}</span>
                          </v-chip >
                          <major class="" :instance="edit['instances'][ins]['codeName']" :major="edit['instances'][ins]['alts'][a]"
                            :class_main="`subtitle  ${colors100[a]}--text text--lighten-3 font-weight`"
                            :class_major="`subtitle-1 white--text font-weight-bold`" />
                          </v-chip>
                        </v-col>
                        <v-col cols="1">
                          <v-icon v-if="the100NNN[actual_d][a]?the100NNN[actual_d][a]['id']==edit['id']:false" :class="`subtitle  ${colors100[a]}--text mt-n1`" small>mdi-map-marker-radius-outline</v-icon>
                          <v-icon v-else small :class="`${colors100[a]}--text text--lighten-3 font-weight-bold`">mdi-arrow-right</v-icon> 
                        </v-col>
                        <v-col cols="4" v-if="the100NNN[actual_d][a]" class="">
                          <v-chip v-if="the100NNN[actual_d][a]['id']!=edit['id']" class="px-2 mr-1 mb-1 body-2 grey--text text--darken-2" outlined small color="grey darken-3">
                            <major :instance="the100NNN[actual_d][a]['instances'][the100NNN[actual_d][a]['theHundred'][currentSet]['instance']]['codeName']" :major="the100NNN[actual_d][a]['instances'][the100NNN[actual_d][a]['theHundred'][currentSet]['instance']]['alts'][a]" 
                              :class_main="`overline  ${colors100[a]}--text text--lighten-3 font-weight`"
                              :class_major="`overline white--text font-weight-bold`" />
                           </v-chip>
                            <v-chip v-else class="px-2 mr-1 mb-1 body-2 grey--text text--lighten-3" small color="grey darken-3">free spot</v-chip>
                       </v-col>
                        <v-col cols="1">
                        <!--  ANDERE SET CHECK nog geen actie of restrictie; zelf opletten -->
                        <span v-if="Object.keys(SetsContainingInst(a)).length>0" :color="colors100[a]"><v-icon class="mr-0" x-small :color="`${colors100[a]}`">mdi-rhombus-split</v-icon> </span>
                        </v-col>
                      </v-row>
                       </v-col>
                    </v-row>
                    </v-col>
                  </v-container>
                </v-row>
<!--CATS --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                <v-row dense fluid class="px-5 mt-0"  justify="center" style="cursor:pointer" align="center">
                  <v-col cols="1" class="mt-2"><v-icon  medium color="withe">mdi-shape-outline</v-icon></v-col>
                  <v-col cols="10" class="px-2">
                    <v-form  ref="editform" v-model="valid" @submit.prevent lazyValidation >
                      <v-combobox  class="mt-8" dense v-model="edit['category']"  :items="uniqueNNN[actual_d]['category']" small-chips clearable :rules="pao_type=='c'?nonEmptyRule:[]"/>
                    </v-form>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
<!--BUTTONS --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                <v-row dense fluid class="px-5 mt-0 mb-2"  justify="center" style="cursor:pointer" align="center">
                  <v-dialog dark v-model="dialog_delete" persistent max-width="290">
                      <template v-slot:activator="{ on }">
                          <span v-if="meme" @click="dialog_delete=true" class="overline red--text text--lighten-3">delete</span>
                      </template>
                      <v-card>
                          <v-card-title v-if="!hasMedia" class="body-2">Sure You want to delete this meme?</v-card-title>
                          <v-card-title v-else class="body-2">First remove all images.</v-card-title>
                          <v-card-actions>
                              <v-btn  v-if="!hasMedia" color="error" text @click="dialog_delete = false; removeMemeFromFB(edit); closeDialog()">yeah go ahead</v-btn>
                              <v-spacer></v-spacer>
                              <v-btn color="grey" text @click="dialog_delete = false">cancel</v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                  <v-spacer/>
                  <span v-if="canSave && valid && meme" style="cursor:pointer" @click="updateMemeInFB(JSON.parse(JSON.stringify(edit)));closeDialog()" class="overline green--text text--lighten-3">Save</span>
                  <span v-if="canSave && valid && !meme" style="cursor:pointer" @click="addMemeInFB(JSON.parse(JSON.stringify(edit)));closeDialog()" class="overline green--text text--lighten-3">Create New</span>
                </v-row>
            </v-card>
        </v-dialog>

</template>


<script>
  import { mapState,mapMutations,mapActions } from 'vuex'
  //import {mixin} from '../filtersandmixins/mixin';
  import c from '../filtersandmixins/changePermutations';
  import major from '../components/major.vue'
  import imagesort from '../components/imagesort'
   

  export default {
    data () {
      return {
        editNull:  {   //moet er zijn on create to watch it properly!
          'dimension': null,
          'category': 'default',
          'theHundred': {}, //wellicht werkt dit niet
          'instances':{},
          'lock':false,'favorite':null
        },
        memeOnLoad:{},
        edit:{},
        inst_edit_name: '', //deze moet apart want niet aanwezig in object
        codestring:{},
        showMode:'major',//
        showCropper: false,
        nonEmptyRule: [  v => !!v || 'required' ],
        parentRules: [
            v => !!v || 'parent required',
            v => !/[^a-zA-Z0-9 ]/.test( v ) || 'no special characters'
          ],
        valid: false,
        dialog_delete:false,
        done: false,
        cats:{},
        pao_type:null,
        actual_d:null,
        sameInst:false,
        isTheHun:false,
        trigger:true,
      }
    },
    computed: {
      ...mapState('memes',['allMemes','uniqueNNN','the100NNN','notThe100NNN']),
      ...mapState('settings',['usrSet','allDims','currentSet','majorCode']),
      ...mapState('globals',['maxActive','colors100','safeMode']),
      The100NNN(){return this.the100NNN},
      SafeMode(){return this.safeMode},
      canSave(){
            let dummy = this.trigger
            let out = false; 
            if (this.edit['category'] && this.allInstancesOk){out=this.edit['category'] != undefined && this.valid}
            return  out
        },
        allPossibileSpots(){
          let dummy = this.trigger
            let allPossibileSpots = [];  for (let instance_id in this.edit['instances']){for (let alt in this.edit['instances'][instance_id]['alts']) { if (!allPossibileSpots.includes(alt)){allPossibileSpots.push(alt)}  }}
            return allPossibileSpots.sort()
        },
        allInstancesOk(){
          let dummy = this.trigger
          let out = true; 
          //console.log(this.edit['instances'])
          if (Object.keys(this.edit['instances']).length<1) {out=false} 
          for (let instance_id in this.edit['instances'])
          {  if (Object.keys(this.edit['instances'][instance_id]['alts']).length<1) {   out=false}   }
          return out    
        },
        hasMedia(){let out = false; 
        try{if (Object.keys(this.edit['media']).length>0){out=true}}catch{};
        return out}
      },
      methods: {
         ...mapMutations('globals',['toggleSafeMode']),
        ...mapActions('memes',['updateMemeInFB','addMemeInFB','removeMemeFromFB','addTempLinkToImageUrl','swapMemeNumber']),
        closeDialog(){this.$emit('finishedEditMeme');},
        SetsContainingInst(a){ return Object.fromEntries(Object.entries(this.edit.theHundred).filter(([key, value]) => {try{return (value['digits']==a)&&key!=this.currentSet}catch{return false}}))} ,
        
        insertquote(){
          let bodyTextArea = this.$refs.instance.$el.querySelector('input') //textarea    => textarea
          let  start = bodyTextArea.selectionStart
          if(this.inst_edit_name){
              this.inst_edit_name= this.inst_edit_name.substr(0, start) + '`' + this.inst_edit_name.substr(start)
            }else{this.inst_edit_name='`'}
        },
        theHun(){
          //computed klinkt logischer maar update niet.... dit wekrt ook 
          let theHun={'digits':null,'instance':null}
          this.sameInst = false
          try{
              for (let ins in this.edit['instances']){
                if (Object.keys(this.edit['instances'][ins]['alts']).includes(this.meme.theHundred[this.currentSet]['digits'])){
                  theHun['digits'] = this.meme.theHundred[this.currentSet]['digits']; //houden als het kan
                  if(ins==this.meme.theHundred[this.currentSet]['instance']){theHun['instance'] = ins; this.sameInst=true}
                  else{if(!this.sameInst){this.sameInst=false; theHun['instance'] = ins;}}
                  }
              } 
              }
          catch{}
          this.isTheHun= theHun['instance']!=null
          return theHun
        },
        init(){
          //if meme; then fill all existing keys; keys that exist in dbase and are not editted are kept this way
          //this.edit = JSON.parse(JSON.stringify(this.meme))
          this.done=false
          if (this.meme){this.edit= JSON.parse(JSON.stringify(this.meme)); this.showMode='avatar'} ///anders verandert ie meme in nivo erboven
          //if (this.meme){for (let key of Object.keys(this.meme)){this.edit[key] = this.meme[key]}}
          else{this.edit['dimension'] = this.newdim}
          this.actual_d = this.edit['dimension'] 
          this.pao_type = this.allDims[this.actual_d]['type']
          this.cats = this.allDims[this.actual_d]['type']=='c'?this.allDims[this.actual_d]['cats']:{}

          let tmp=[]
          for (let ins in this.edit['instances']){
            this.edit['instances'][ins]['alts'] = c.changePermutations(this.edit['instances'][ins],this.pao_type,this.cats,this.edit['category'])
            tmp.push(this.edit['instances'][ins]['codeName']) 
            this.codestring[ins] = this.edit['instances'][ins]['codeTemplate'].split('`')
          } 
          this.inst_edit_name = tmp.join(',')
          let theH = this.theHun(); this.edit['theHundred'][this.currentSet]=this.isTheHun?theH:{}
          return this.edit
        },
        clickerdeclick(ins,n,i){
          this.codestring[ins][n]=i
          this.edit['instances'][ins]['codeTemplate'] = this.codestring[ins].join('`')
          this.edit['instances'][ins]['alts'] = c.changePermutations(this.edit['instances'][ins] ,this.pao_type,this.cats,this.edit['category'])
          let theH = this.theHun(); this.edit['theHundred'][this.currentSet]=this.isTheHun?theH:{}
          this.trigger=!this.trigger
       },
        fixName(before){
          //this.inst_edit_name = this.inst_edit_name.trim().replace(/^[\`]+|[\`]+$/g, "").trim().replace(/\s{2,}/g, ' ').replace(/`{2,}/g, '`').toLowerCase()
          function fix(name){
            name = name.trim().toLowerCase()
            name = name.replace(/^[\`]+|[\`]+$/g, "").trim() //haalt begin en end '`' weg
            name = name.replace(/\s+/g, ' ').replace(/`+/g, '`') //haalt multiple spaces en ` weg
            name = name.replace(/` `|` | `/g, ' ').replaceAll(' ','` `') //haalt multiple spaces en ` weg
          return name
          }
          let inst_edit_name_arr = this.inst_edit_name.split(',')
          this.edit['instances']={}
          for (let ins in inst_edit_name_arr){
              let ins_name = fix(inst_edit_name_arr[ins])
              inst_edit_name_arr.splice(ins,1, ins_name) 
              this.edit['instances'][ins]={}
              this.edit['instances'][ins]['displayName']=ins_name.split('`').join('')
              this.edit['instances'][ins]['codeName'] = ins_name
              this.codestring[ins] = Array(this.edit['instances'][ins]['codeName'].split('`').length).fill(-1)
              for (let i in this.edit['instances'][ins]['codeName'].split('`')){
                let c = this.edit['instances'][ins]['codeName'].split('`')[i]
                if (c ==' '){this.codestring[ins][i]=-2}
                if (this.majorCode[c]){
                  this.codestring[ins][i]=Number(this.majorCode[c])}
              }
              this.edit['instances'][ins]['codeTemplate'] = this.codestring[ins].join('`')
              this.edit['instances'][ins]['alts'] = c.changePermutations(this.edit['instances'][ins] ,this.pao_type,this.cats,this.edit['category'] )
              let theH = this.theHun(); this.edit['theHundred'][this.currentSet]=this.isTheHun?theH:{}
              this.trigger=!this.trigger
          }
          this.inst_edit_name = inst_edit_name_arr.join(',')
        },
        automajorize(){
            let sorted = Object.keys(this.majorCode).sort((a, b)=> {return b.length - a.length});
            let inst_edit_name_out = []
            function red(instance,majorCode,major_l){
              let arr= []
              for (let el of instance) {
                for (let mjr of Object.keys(majorCode)){  if (mjr.length==major_l){  if (!(el[0]==el.slice(-1)&& el[0] =='`')){ el = el.replaceAll(mjr, ',`'+mjr+'`,') }}  }
                arr.push(el.split(","))
              }
              return arr.flat()
            }
            for (let instance of this.inst_edit_name.split(',')){
              let prut = [instance.replaceAll('`','').trim()]
              for (let i=sorted[0].length; i>0; i--){ prut = red(prut,this.majorCode,i)} 
              inst_edit_name_out.push(prut.join(''))//.replaceAll('``','`').replace(/^[\`]+|[\`]+$/g, "").replace(/`{2,}/g, '`'))
            }
            //console.log(inst_edit_name_out.join(','))
            this.inst_edit_name = inst_edit_name_out.join(',')
          },
          getUrlImg(){
            let out = null
            try{out = this.edit['media'][0]['url']}catch{}
            return out
            },
       
      },
      watch: {
        showEdit: function(){
          if (this.showEdit){
            
            this.edit= JSON.parse(JSON.stringify(this.editNull))
            if (!this.edit.theHundred){this.edit.theHundred[this.currentSet] = null}
            this.memeOnLoad = this.init()
            }
        }

    },
    props: ['showEdit','meme','newnumber','newdim'],
    //mixins: [mixin],
    components:{
      major, imagesort,
     },
    mounted: function () {
    }
  }
</script>
