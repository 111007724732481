<template>
  <div>
    <nav>
      <v-app-bar app flat dark dense>
        <navtitle :title="'p.a.o.'"/>
         <v-btn text @click="$router.push('/the100')">
             <v-icon medium :class="`white--text lighten-1 ml-1`">mdi-database-search</v-icon>
         </v-btn>
         <v-spacer></v-spacer>
         <navmenu/>
         <template v-slot:extension>
          <v-tabs v-model="mainMode" background-color="pink" dark show-arrows grow align-with-title>
            <v-tabs-slider color="cyan lighten-4"></v-tabs-slider>
              <v-tab v-for="i in Object.keys(mainModes)" :key="i" @change="">
                <v-icon v-if="i==0" class="mt" color="pink lighten-4">mdi-ear-hearing</v-icon>
                <v-icon v-if="i==1" class="mt" color="pink lighten-4">mdi-bullhorn-outline</v-icon>
                <v-icon v-if="i==2" class="mt" color="pink lighten-4">mdi-seal</v-icon>

                <span class="px-2">{{mainModes[i]}}</span>

              </v-tab >
            </v-tabs-slider>
          </v-tabs>
         </template>
       </v-app-bar>
     </nav>

     <v-main class="mx-4 mb-4 mt-0" fluid>
       <!-- ENCODING -->

              <v-card class="mx-auto mt-5" max-width="600" v-if="mainMode==0">

                  <v-card-text>
                    <v-row class="mb-4"  >
                      <v-col class="text-left">
                        <span class="text-h2 font-weight-light" v-text="NumberOfSets" ></span>
                        <span class="subheading font-weight-light mr-1"> SETS</span>
                        <span class="text-h2 font-weight-light" v-text="TimePerSet" ></span>
                        <span class="subheading font-weight-light mr-1"> sec/set</span>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn  class="mr-5" v-ripple="false" icon @click="RndCorrected=!RndCorrected">
                        <v-icon size="60px" :color="RndCorrected?'pink':'grey'" >mdi-dice-multiple</v-icon>
                        </v-btn>

                        <v-icon @click="initEncodeRun({'NumberOfSets':NumberOfSets,'TimePerSet':TimePerSet,'RndCorrected':false,'MinTimeBeforeDecode':60*(MinTimeBeforeDecodeHours*60+MinTimeBeforeDecodeMinutes)} );encodeShow=true" :disabled="('settings' in paoRun)" size="60px" color="pink">mdi-play-circle</v-icon>
                      </v-col>
                    </v-row>
                    <v-row class="mt-8">
                         <v-slider v-model="NumberOfSets" min="1" max="50" color="pink" track-color="grey" always-dirty thumb-label="always">
                           <template v-slot:prepend>
                               <v-icon color="pink" @click="NumberOfSets-=NumberOfSets>1?1:0">mdi-minus </v-icon>
                           </template>
                           <template v-slot:append>
                                <v-icon color="pink" @click="NumberOfSets+=NumberOfSets<50?1:0">mdi-plus</v-icon>
                           </template>
                         </v-slider>
                   </v-row>
                   <v-row class="mt-1">
                   <v-slider v-model="TimePerSet" min="1" max="60" color="cyan" track-color="grey" always-dirty thumb-label="always">
                     <template v-slot:prepend>
                         <v-icon color="cyan" @click="TimePerSet-=TimePerSet>1?1:0">mdi-minus </v-icon>
                     </template>
                     <template v-slot:append>
                          <v-icon color="cyan" @click="TimePerSet+=TimePerSet<60?1:0">mdi-plus</v-icon>
                     </template>
                   </v-slider>
                 </v-row>
                 <v-row class="mt-8" justify="space-between" >
                   <v-col class="text-left">
                     <span class="text-h2 font-weight-light">{{MinTimeBeforeDecodeHours}}</span>
                     <span class="subheading font-weight-light mr-1">hrs</span>
                     <span class="text-h2 font-weight-light">{{MinTimeBeforeDecodeMinutes}}</span>
                     <span class="subheading font-weight-light mr-1">mins</span>

                     <span class="subheading font-weight-light ml-5">(minimal wait before decoding)</span>
                 </v-col>
                 </v-row>

                 <v-row class="mt-8">
                  <v-slider v-model="MinTimeBeforeDecodeHours" min="0" max="23" color="black" track-color="grey" always-dirty thumb-label="always">
                    <template v-slot:prepend>
                        <v-icon color="black" @click="MinTimeBeforeDecodeHours-=MinTimeBeforeDecodeHours>0?1:0">mdi-minus </v-icon>
                    </template>
                    <template v-slot:append>
                         <v-icon color="black" @click="MinTimeBeforeDecodeHours+=MinTimeBeforeDecodeHours<23?1:0">mdi-plus</v-icon>
                    </template>
                  </v-slider>

                  <v-slider v-model="MinTimeBeforeDecodeMinutes" width="30%" min="0" max="59" color="black" track-color="grey" always-dirty thumb-label="always">
                    <template v-slot:prepend>
                        <v-icon color="black" @click="MinTimeBeforeDecodeMinutes-=MinTimeBeforeDecodeMinutes>0?1:0">mdi-minus </v-icon>
                    </template>
                    <template v-slot:append>
                         <v-icon color="black" @click="MinTimeBeforeDecodeMinutes+=MinTimeBeforeDecodeMinutes<59?1:0">mdi-plus</v-icon>
                    </template>
                  </v-slider>
                </v-row>

                 </v-card-text>
                </v-card>
           <paoencode :encodeShow="encodeShow"  @finishedEncodeShow="encodeShow=false;"/>

              <!-- @finishedEncodeShow="showEdit=false;resetShowMore();" /> -->
       <!--END ENCODING -->

       <!-- DECODING -->
      <div class="mt-5"  v-if="mainMode==1">
           <v-card   justify="start" flat rounded  class="mb-1 py-0 px-2">
             <v-row  justify="start" dense  class="subtitle-1 grey mt-2" >
               <v-col cols="2" class="px-2">Date</v-col>
               <v-col cols="1" class="px-2">Time</v-col>
               <v-col cols="1" class="px-2">Sets</v-col>
               <v-col cols="2" class="text-right">t<sub>encode</sub></v-col>
               <v-col cols="2" class="text-right"><v-icon color="black" small>mdi-timer-outline </v-icon></v-col>
               <v-col cols="2" class="text-right"><v-icon color="black" small>mdi-delete-outline </v-icon></v-col>
               <v-col cols="2" class="text-right"></v-col>
              </v-row>
            </v-card>
            <v-card  justify="start" flat tile  class="mb-1 py-0 px-2"  v-for="(run,i) in allPaoRuns" :key="i">
               <v-row  v-if="run['decode']['start']==null" justify="start" dense  class="subtitle-1 grey lighten-4 mt-2" >
                 <v-col cols="2" class="px-2">{{run['encode']['start'].slice(0, 10)}}</v-col>
                 <v-col cols="1" class="px-2">{{run['encode']['start'].slice(11, 16)}}</v-col>
                 <v-col cols="1" class="px-2">{{run['settings']['NumberOfSets']}}</v-col>
                 <v-col cols="2" class="text-right">{{run['encode']['total'] | onlyminutes}}m {{run['encode']['total'] | onlyseconds | dd}}s</v-col>
                 <v-col cols="2" class="text-right"><span v-if="timeleft(run)>=0">{{ timeleft(run) | onlyhours }}h {{ timeleft(run) | onlyminutes |dd }}m {{ timeleft(run) | onlyseconds |dd}}s</span><span class="grey--text text--lighten-2" v-else>00h00m00s</span></v-col>
                 <v-col cols="2" class="text-right"><span v-if="(timeleft(run)<0) && (TimeBeforeDelete+timeleft(run)>=0)">{{ (TimeBeforeDelete+timeleft(run))| onlyhours}}h {{ (TimeBeforeDelete+timeleft(run))| onlyminutes |dd}}m</span><span class="grey--text text--lighten-2" v-else>00h00m</span></v-col>
                 <v-col cols="2" class="text-right"><v-btn color="pink" :disabled="(timeleft(run)>0) || ('settings' in paoRun)" :loading="timeleft(run)>0" small width="100px" @click="decodeShow=true; initDecodeRun(run)">decode</v-btn></v-col>

               </v-row>
             </v-card>
              <paodecode :decodeShow="decodeShow"  @finishedDecodeShow="decodeShow=false;"/>
          </div>

       <!--END DECODING -->


       <!-- RESULTS -->
       <div class="mt-5" v-if="mainMode==2">

         <template>
           <div class="text-center">
             <v-pagination
              color="pink"
               v-model="page"
               :length="Math.ceil(allPaoRuns.filter(x=>x.decode.end!=null).length/perpage)"
               :total-visible="10"
             ></v-pagination>
           </div>
         </template>




         <v-card justify="start" flat rounded  class="mb-1 py-0 px-2">

           <v-row  justify="start" dense  class="subtitle-1 grey mt-2" >
             <v-col cols="2" class="px-2">Date</v-col>
             <v-col cols="1" class="px-2">Time</v-col>
             <v-col cols="1" class="px-2">Sets</v-col>
             <v-col cols="1" class="text-right">t<sub>encode</sub></v-col>
             <v-col cols="1" class="text-right">t<sub>decode</sub></v-col>
             <v-col cols="1" class="text-center"> <v-icon small>mdi-seal</v-icon></v-col>
             <v-col cols="1" class="text-center">
              <v-icon small  color="" class="pa-0 mx-0">mdi-ear-hearing</v-icon>
              <v-icon small color="" >mdi-bullhorn-outline</v-icon>
              <v-icon small color="" >mdi-skull-outline</v-icon>

          </v-col>


          <v-col cols="1" class="text-right"></v-col>
          </v-row>
          </v-card>
           <v-card justify="start" flat tile  class="mb-1 py-0 px-2"  v-for="(run,i) in allPaoRuns.filter(x=>x.decode.end!=null).slice((page-1)*perpage,page*perpage)"   :key="i" >
             <v-row  justify="start" dense
             :class="`subtitle-1 ${!(ErrorsTotals(run['errors'])[0]==0)?'pink':'grey'} ${Hover['res'][i]?'lighten-2':'lighten-4'} mt-2`"
             @click="resultShow=true; initResultRun(run)" style="cursor:pointer"
              @mouseover="setHover('res',i,true)" @mouseout="setHover('res',i,false)">
               <v-col cols="2" class="px-2">{{run['encode']['start'].slice(0, 10)}}</v-col>
              <v-col cols="1" class="px-2">{{run['encode']['start'].slice(11, 16)}}</v-col>
               <v-col cols="1" class="px-2">{{run['settings']['NumberOfSets']}}</v-col>
               <v-col cols="1" class="text-right">{{run['encode']['total'] | onlyminutes}}m {{run['encode']['total'] | onlyseconds | dd}}s</v-col>
               <v-col cols="1" class="text-right">{{run['decode']['total'] | onlyminutes}}m {{run['decode']['total'] | onlyseconds | dd}}s</v-col>
               <v-col cols="1" class="text-center">
                  <span v-if="(ErrorsTotals(run['errors'])[0]==0&&ErrorsTotals(run['errors'])[99]==0)" class="green--text"  >
                     <v-icon size="40px"  small color="green">mdi-seal</v-icon>
                   </span>
                  <span class="" v-else >
                     <span v-if="ErrorsTotals(run['errors'])[1]>0" class="pink--text"> {{ErrorsTotals(run['errors'])[99]}}</span>
                  </span>
                </v-col>
                 <v-col cols="1" class="text-center" >
                    <span v-if="!(ErrorsTotals(run['errors'])[0]==0&&ErrorsTotals(run['errors'])[99]==0)">
                      <span v-if="ErrorsTotals(run['errors'])[1]>0" class="pink--text"> <v-icon xSmall  color="pink" class="pa-0 mx-0 ">mdi-ear-hearing</v-icon>{{ErrorsTotals(run['errors'])[1]}}</span>
                      <span v-if="ErrorsTotals(run['errors'])[2]>0" class="pink--text"><v-icon xSmall  color="pink" class="pa-0 mx-0">mdi-bullhorn-outline</v-icon>{{ErrorsTotals(run['errors'])[2]}}</span>
                    <span v-if="ErrorsTotals(run['errors'])[3]>0" class="pink--text"><v-icon xSmall  color="pink" class="pa-0 mx-0">mdi-skull-outline</v-icon>{{ErrorsTotals(run['errors'])[3]}}</span>
                    </span>
              </v-col>

             </v-row>

           </v-card>
           <paoresult :resultShow="resultShow"  @finishedresultShow="resultShow=false;"/>
         </div>

       <!--END RESULTS -->


      </v-main>
</div>
</template>

<script>
  import navtitle from '../components/navtitle.vue'
  import navmenu from '../components/navmenu.vue'
  import paoencode from '../components/paoencode'
  import paodecode from '../components/paodecode'
  import paoresult from '../components/paoresult'
  import { mapState,mapMutations,mapActions } from 'vuex'

  export default {
      data() {return {
        mainModes:{0:'encode',1:'decode',2:'results',3:'practise'},
        mainMode:0,
        encodeShow: false,
        decodeShow:false,
        resultShow:false,
        Hover:{'res':{}},
        page:1,
        perpage:10,
        TimeBeforeDelete: 48*60*60, //seconds
        DeleteRun: {},
        NumberOfSets:1, TimePerSet:1,MinTimeBeforeDecodeHours:0,MinTimeBeforeDecodeMinutes:0,RndCorrected:false,
        ErrorIcons:{ 0:'mdi-help-circle-outline',
                     1:'mdi-ear-hearing',
                     2:'mdi-bullhorn-outline',
                     3:'mdi-skull-outline'},
      }

      },
      computed: {
          ...mapState('runs',['allPaoRuns','paoRun','runSettings']),
          ...mapState('globals',['nu']),
          nu_red(){return this.nu.getSeconds()}
      },
      methods: {
        ...mapActions('runs',['initEncodeRun','initDecodeRun','initResultRun','getAllRunsFromFB','removeRunFromFB']),
        setHover(type,i,val){ this.$set(this.Hover[type], i, val)},

        timeleft(run){
          return run['settings']['MinTimeBeforeDecode'] - ( this.nu - new Date(run['encode']['end']) )/1000
        },
        init(){
          this.NumberOfSets = this.runSettings['NumberOfSets']
          this.TimePerSet = this.runSettings['TimePerSet']
          this.RndCorrected = this.runSettings['RndCorrected']
          this.MinTimeBeforeDecodeHours = Math.floor((this.runSettings['MinTimeBeforeDecode']/60) / 60);
          this.MinTimeBeforeDecodeMinutes = Math.floor((this.runSettings['MinTimeBeforeDecode']/60) % 60);
        },
        ErrorsTotals(Errors){
          let out={0:0,1:0,2:0,3:0}
          for (let k of Object.keys(Errors)){out[Errors[k].type] += 1;}
          out[99]=out[0]+out[1]+out[2]+2*out[3]
          return out
        }
      },
      watch: {
        runSettings: function(){this.init()},
        nu_red: function(){
          if (this.nu_red == 0){
            for (let run of this.allPaoRuns){
              if (run['decode']['set'].length<1){
                if ((this.timeleft(run)<0) && (this.TimeBeforeDelete+this.timeleft(run)<0)){
                  this.removeRunFromFB(run)
                }
              }
            }
        }},

      },
      created: function(){
        this.getAllRunsFromFB()
        this.init()
      },
      components:{  navtitle,navmenu,paoencode,paodecode,paoresult  }
    }
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>
