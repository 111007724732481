import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import "./filtersandmixins/filter"
import router from './router'
import store from './store/store'
// import { store } from './store.js' // zo staat ie in de turoial
const fb = require('./firebaseConfig.js')
//import './scss/custom.scss'
import vuetify from './plugins/vuetify';
import Croppa from 'vue-croppa'

Vue.config.productionTip = false
Vue.use(Croppa)



// handle page reloads
fb.auth.onAuthStateChanged(user => {
//deze zorgt ervoor dat nav blijft staan bij page reload;
if (user) {store.commit('auth/setCurrentUser', user)}

let app
  if (!app) {
        app = new Vue({
            el: '#app',
            router,
            store,
            vuetify,
            render: h => h(App)
        })
    }
})
